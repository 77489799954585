import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import './Travel_Desk.css';
import useAuth from './useAuth';
//import { BASE_URL } from "./Constants.js";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const Loader = () => (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
        <p>Loading...</p>
    </div>
);

const Hotel_Request = () => {
    const { id } = useParams();
    const [EmpCode, setEmpCode] = useState('100076');
    const [items, setItems] = useState([]); // Cities
    const [ReportManagerName, setReportManagerName] = useState('');
    const [HotelManagerName, setHotelManagerName] = useState('');
    const [rows, setRows] = useState([]); // Rows for employee details
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const [formData, setFormData] = useState({
        from_city: '',
        to_city: '',
        travel_date: '',
        return_date: '',
        receipt: null,
        amount: '',
        receiptPreview: null, // Preview state
    });

    useAuth();
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
         // If the name is 'status_select', update it and set corresponding status to 'Pending'
         // If the name is 'status_select', update it and set corresponding status to 'Pending'
         if (name === 'status_select') {
            setFormData(prevData => {
                let newData = { ...prevData, [name]: value };

                // Based on the selected request type, set the corresponding status to 'Pending'
                if (value === 'request_for_manager') {
                    newData.rpt_mgr_status = 'Pending';
                } else if (value === 'request_for_hotel_mngr') {
                    newData.htl_mgr_status = 'Pending';
                } 

                return newData;
            });
        }

        // Handle other field changes (rpt_mgr_status, audit_status, account_status, etc.)
        else if (name === 'rpt_mgr_status') {
            setFormData(prevData => ({ ...prevData, [name]: value }));
        } else if (name === 'htl_mgr_status') {
            setFormData(prevData => ({ ...prevData, [name]: value }));
        } 
    };

    const handleRowChange = (id, e) => {
        const { name, value } = e.target;
        setRows(prevRows =>
            prevRows.map(row => (row.id === id ? { ...row, [name]: value } : row))
        );
    };
    const fetchEmployeeDetails = async (empCode) => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_BASE_URL}users?emp_code=${empCode}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            if (result.status) {
                setRows(prevRows => {
                    const updatedRows = [...prevRows];
                    const rowIndex = updatedRows.findIndex(row => row.emp_code === empCode);
                    if (rowIndex !== -1) {
                        updatedRows[rowIndex].comp_name = result.user.comp_name;
                        updatedRows[rowIndex].dept_name = result.user.dept_name;
                        updatedRows[rowIndex].designation_name = result.user.designation_name;
                        updatedRows[rowIndex].grade = result.user.grade;
                        updatedRows[rowIndex].mobile = result.user.mobile;
                    }
                    return updatedRows;
                });
            } else {
                setError(result.message);
            }
        } catch (error) {
            console.error('Fetch error:', error);
            // setError('Failed to fetch employee details');
        }
    };

    useEffect(() => {
        if (EmpCode) {
            fetchEmployeeDetails(EmpCode);
        }
    }, [EmpCode]);

    const fetchItems = async () => {
        try {
            const token = localStorage.getItem("authToken") || null;
            if (!token) return;

            const response = await fetch(`${API_BASE_URL}getHotelRequest?id=${id}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            if (result.status) {
                setReportManagerName(result.reportManagerName.comp_name || '');
                setHotelManagerName(result.hotelManagerName.comp_name || '');
                const travelDesk = result.hotel_request;
                
                
                setFormData({
                    hotel_name: travelDesk.hotel_name || '',
                    created_on: travelDesk.created_on || '',
                    rpt_mgr_status: travelDesk.rpt_mgr_status || '',
                    manager_approved_date: travelDesk.manager_approved_date || '',
                    hotel_approved_date: travelDesk.hotel_approved_date || '',
                    hotel_manager: travelDesk.hotel_manager || '',
                    htl_mgr_status: travelDesk.htl_mgr_status || '',
                    trans_code: travelDesk.trans_code || '',
                    city: travelDesk.city,
                    report_mngr: travelDesk.report_mngr,
                    hotel_mngr_receipt: travelDesk.hotel_mngr_receipt,
                    hotel_mngr_receipt2: travelDesk.hotel_mngr_receipt2,
                    hotel_mngr_receipt3: travelDesk.hotel_mngr_receipt3,
                    hotel_remarks: travelDesk.hotel_remarks,
                    check_in: travelDesk.check_in ? new Date(travelDesk.check_in).toISOString().split('T')[0] : '',
                    check_out: travelDesk.check_out ? new Date(travelDesk.check_out).toISOString().split('T')[0] : '', // Format date
                });
                setRows(travelDesk.related_details.map(detail => ({
                    id: detail.id,
                    emp_code: detail.emp_code,
                    comp_name: detail.comp_name,
                    dept_name: detail.dept_name,
                    designation_name: detail.designation_name,
                    grade: detail.grade,
                    mobile: detail.mobile,
                })));
            } else {
                setError(result.message);
            }
        } catch (error) {
            console.error("Fetch error:", error);
            //  setError("Failed to fetch items");
        } finally {
            setLoading(false);
        }
    };

    const fetchCitiesData = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_BASE_URL}cities`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            if (result.status) {
                setItems(result.data);
            } else {
                setError(result.message);
            }
        } catch (error) {
            console.error('Fetch error:', error);
            setError('Failed to fetch cities data');
        }
    };

    useEffect(() => {
        fetchItems();
        fetchCitiesData(); // Ensure this runs on component mount
    }, [id]);

    useEffect(() => {
        const fetchProfileData = async () => {
            setLoading(true); // Set loading to true at the start
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    const { profile } = result;

                    setEmpCode(profile.emp_code || '');

                    setFormData(prevData => ({
                        ...prevData,
                        comp_name: profile.comp_name || '',
                        emp_code: profile.emp_code || '',
                        dept_name: profile.dept_name || '',
                        designation_name: profile.designation_name || '',
                        grade: profile.grade || '',
                        mobile: profile.mobile || '',
                    }));
                }
            } catch (error) {
                console.error('Fetch error:', error);
            } finally {
                setLoading(false); // Set loading to false when done
            }
        };

        fetchProfileData();
    }, []);

    const handleBackClick = () => {
        navigate("/manage_hotel_request");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("authToken") || null;
        if (!token) return;

        // Validate travel_date
        if (!formData.check_in) {
            toast.error("Check In date is required.");
            return;
        }

        const formDataToSend = new FormData();
        formDataToSend.append('city', formData.city);
        formDataToSend.append('hotel_name', formData.hotel_name);
        formDataToSend.append('check_in', formData.check_in);
        formDataToSend.append('check_out', formData.check_out);
        formDataToSend.append('rpt_mgr_status', formData.rpt_mgr_status); // Ensure employee remarks are correctly appended from formData
        formDataToSend.append('htl_mgr_status', formData.htl_mgr_status);

        // Append related details
        rows.forEach((row, index) => {
            Object.keys(row).forEach(key => {
                formDataToSend.append(`related_details[${index}][${key}]`, row[key]);
            });
        });

        try {
            const response = await fetch(`${API_BASE_URL}updateHotelRequest?id=${id}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formDataToSend,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            if (result.status) {
                toast.success("Travel desk updated successfully!");
                navigate(`/manage_hotel_request`); // Redirect after success
            } else {
                toast.error(result.message || "Update failed.");
            }
        } catch (error) {
            console.error("Submission error:", error);
            toast.error("Failed to update travel desk.");
        }
    };

    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0" style={{ fontSize: '20px' }}>Hotel Request Ticket Booking Info ({formData.trans_code})</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                        <li className="breadcrumb-item"><a href="/manage_travel_desk">Hotel Details</a></li>
                                        <li className="breadcrumb-item active">Hotel Desk Booking Info</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <Loader /> // Show loader if loading is true
                    ) : (
                        <>
                            <section className="col-lg-12">
                                <div className="card" style={{ borderTop: '3px solid #00c0ef' }}>
                                    <div className="card-body">
                                        {error && <div className="alert alert-danger">{error}</div>}
                                        <form encType="multipart/form-data" onSubmit={handleSubmit}>
                                            <div className="box-body">
                                                <div className="row">

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="name">Name <span style={{ color: 'red', fontSize: '11px' }}>*</span></label>
                                                            <input
                                                                type="text"
                                                                name="comp_name"
                                                                id="name"
                                                                value={formData.comp_name}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                placeholder="Name"
                                                                readonly="true"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="name">Employee No/Consultant
                                                                <span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                type="text"
                                                                name="emp_code"
                                                                id="name"
                                                                value={formData.emp_code}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                placeholder="Employee No/Consultant"
                                                                readonly="true"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="name">Department<span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                type="text"
                                                                name="dept_name"
                                                                id="name"
                                                                value={formData.dept_name}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                placeholder="Department&Designation"
                                                                readonly="true"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="name">Designation <span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                type="text"
                                                                name="designation_name"
                                                                id="name"
                                                                value={formData.designation_name}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                placeholder="Department&Designation"
                                                                readonly="true"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="name">Employee Band as per Category  <span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                type="text"
                                                                name="grade"
                                                                id="name"
                                                                value={formData.grade}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                placeholder="Employee Band as per Category"
                                                                readonly="true"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="name">Mobile No
                                                                <span style={{ color: 'red' }}>*</span></label>
                                                            <input

                                                                type="text"
                                                                name="mobile"
                                                                id="name"
                                                                value={formData.mobile}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                placeholder="Days of travel"
                                                                readonly="true"
                                                            />
                                                        </div>
                                                    </div>



                                                </div>
                                                <div className="row">
                                                    <div className='col-md-3'>
                                                        <div className='form-group'>
                                                        <label htmlFor="hotel_name">Remarks</label>
                                                            <input
                                                                type="text"
                                                                name="hotel_name"
                                                                value={formData.hotel_name || ''}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label htmlFor="city">City <span style={{ color: 'red' }}>*</span></label>
                                                            <select
                                                                name="city"
                                                                value={formData.city}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                required
                                                            >
                                                                <option value="">Select From City</option>
                                                                {items.map(city => (
                                                                    <option key={city.id} value={city.id}>
                                                                        {city.city_name} {/* Display city_name */}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label htmlFor="check_in">Check - In <span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                type="date"
                                                                name="check_in"
                                                                value={formData.check_in || ''}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label htmlFor="check_out">Check - Out <span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                type="date"
                                                                name="check_out"
                                                                value={formData.check_out || ''}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                   
                                                        <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="hotel_remarks">Hotel Manager Remarks
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>

                                                            <textarea name="hotel_remarks"
                                                            
                                                                required
                                                                id="remarks"
                                                                value={formData.hotel_remarks || ''}
                                                                onChange={handleChange}
                                                                className="form-control "
                                                                disabled
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    {(formData.rpt_mgr_status === 'Rejected' || formData.htl_mgr_status === 'Rejected' ) && (
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label style={{ fontSize: '12px' }} htmlFor="status_select">
                                                            Request Type

                                                            <span style={{ color: 'red' }}>*</span>
                                                            (<small style={{ color: '#888', fontStyle: 'italic' }}>
                                                                For Requesting Again to the Reporting Manager or Hotel Manager make this "Pending".
                                                            </small>)
                                                        </label>

                                                        <select
                                                            name="status_select"
                                                            id="status_select"
                                                            value={formData.status_select || ''}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            required
                                                        >
                                                            <option value="">Select Request</option>

                                                            {/* Conditionally enable only the appropriate option */}
                                                            {formData.rpt_mgr_status === 'Rejected' && (
                                                                <option value="request_for_manager">Request For Manager</option>
                                                            )}
                                                            {formData.htl_mgr_status === 'Rejected' && (
                                                                <option value="request_for_hotel_mngr">Request For Travel Manager</option>
                                                            )}
                                                           
                                                        </select>
                                                    </div>

                                                  

                                                </div>

                                            )}


                                            {/* Based on selected Request Type, show the corresponding dropdowns */}
                                            {formData.status_select && (
                                                <div className="col-md-4">
                                                    {formData.status_select === 'request_for_manager' && (
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="rpt_mgr_status">
                                                                Request For Manager
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <select
                                                                name="rpt_mgr_status"
                                                                id="rpt_mgr_status"
                                                                value={formData.rpt_mgr_status || ''}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                required
                                                            >
                                                                <option value="Pending">Pending</option>
                                                            </select>
                                                        </div>
                                                    )}

                                                    {formData.status_select === 'request_for_hotel_mngr' && (
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="htl_mgr_status">
                                                                Request For Hotel Manager
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <select
                                                                name="htl_mgr_status"
                                                                id="htl_mgr_status"
                                                                value={formData.htl_mgr_status || ''}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                required
                                                            >
                                                                <option value="Pending">Pending</option>
                                                            </select>
                                                        </div>
                                                    )}

                                                   
                                                </div>
                                            )}
                                                 
                                                </div>
                                                {rows.length > 0 ? (
                                                    <div className="expense-table-container" style={{ textAlign: 'right' }}>
                                                        <table className="expense-table">
                                                            <thead>
                                                                <tr className="header-row">
                                                                    <th>Emp Code<span style={{ color: 'red' }}>*</span></th>
                                                                    <th>Name</th>
                                                                    <th>Dept</th>
                                                                    <th>Designation</th>
                                                                    <th>Grade</th>
                                                                    <th>Mobile</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {rows.map(row => (  // Correctly using curly braces here
                                                                    <tr key={row.id}>
                                                                        <td data-label="Emp Code">
                                                                            <input
                                                                                type="text"
                                                                                name="emp_code"
                                                                                value={row.emp_code}
                                                                                onChange={(e) => {
                                                                                    handleRowChange(row.id, e);
                                                                                    setEmpCode(e.target.value); // Update EmpCode state to trigger API fetch
                                                                                }}
                                                                                className="form-control input-font"
                                                                                placeholder="Emp Code"
                                                                                required
                                                                            />
                                                                        </td>
                                                                        <td data-label="Name">
                                                                            <input
                                                                                type="text"
                                                                                name="comp_name"
                                                                                value={row.comp_name}
                                                                                className="form-control input-font"
                                                                                placeholder="Name"
                                                                                readOnly
                                                                            />
                                                                        </td>
                                                                        <td data-label="Dept">
                                                                            <input
                                                                                type="text"
                                                                                name="dept_name"
                                                                                value={row.dept_name}
                                                                                className="form-control input-font"
                                                                                placeholder="Dept"
                                                                                readOnly
                                                                            />
                                                                        </td>
                                                                        <td data-label="Designation">
                                                                            <input
                                                                                type="text"
                                                                                name="designation_name"
                                                                                value={row.designation_name}
                                                                                className="form-control input-font"
                                                                                placeholder="Designation"
                                                                                readOnly
                                                                            />
                                                                        </td>
                                                                        <td data-label="Grade">
                                                                            <input
                                                                                type="text"
                                                                                name="grade"
                                                                                value={row.grade}
                                                                                className="form-control input-font"
                                                                                placeholder="Grade"
                                                                                readOnly
                                                                            />
                                                                        </td>
                                                                        <td data-label="Mobile">
                                                                            <input
                                                                                type="text"
                                                                                name="mobile"
                                                                                value={row.mobile}
                                                                                className="form-control input-font"
                                                                                placeholder="Mobile"
                                                                                readOnly
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                ) : (
                                                    <div className="expense-table-container" style={{ textAlign: 'right',display: 'none' }}>
                                                        <table className="expense-table">
                                                            <thead>
                                                                <tr className="header-row">
                                                                    <th>Emp Code<span style={{ color: 'red' }}>*</span></th>
                                                                    <th>Name</th>
                                                                    <th>Dept</th>
                                                                    <th>Designation</th>
                                                                    <th>Grade</th>
                                                                    <th>Mobile</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                                        No records found
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}

                                            </div>
                                            <div className='row'>
                                            {formData.hotel_mngr_receipt && (
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label>Receipt:</label>
                                                                    {formData.hotel_mngr_receipt.endsWith('.pdf') ? (
                                                                        // Display PDF file
                                                                        <a
                                                                            href={formData.hotel_mngr_receipt}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            style={{ display: 'block' }}
                                                                        >
                                                                            <embed
                                                                                src={formData.hotel_mngr_receipt}
                                                                                type="application/pdf"
                                                                                width="100%"
                                                                                height="200px"
                                                                                style={{ objectFit: 'contain' }}
                                                                            />
                                                                        </a>
                                                                    ) : (
                                                                        // Display image file
                                                                        <a
                                                                            href={formData.hotel_mngr_receipt}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            style={{ display: 'block' }}
                                                                        >
                                                                            <img
                                                                                src={formData.hotel_mngr_receipt}
                                                                                alt="Travel Manager Receipt"
                                                                                style={{ width: '100%', maxHeight: '200px', objectFit: 'contain' }}
                                                                            />
                                                                        </a>
                                                                    )}
                                                                </div>
                                                                <a
                                                                    href={formData.hotel_mngr_receipt}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    style={{ display: 'block' }}
                                                                >click to open file in new tab to download</a>
                                                            </div>
                                                        )}
                                                        {formData.hotel_mngr_receipt2 && (
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label>Receipt2:</label>
                                                                    {formData.hotel_mngr_receipt2.endsWith('.pdf') ? (
                                                                        // Display PDF file
                                                                        <a
                                                                            href={formData.hotel_mngr_receipt2}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            style={{ display: 'block' }}
                                                                        >
                                                                            <embed
                                                                                src={formData.hotel_mngr_receipt2}
                                                                                type="application/pdf"
                                                                                width="100%"
                                                                                height="200px"
                                                                                style={{ objectFit: 'contain' }}
                                                                            />
                                                                        </a>
                                                                    ) : (
                                                                        // Display image file
                                                                        <a
                                                                            href={formData.hotel_mngr_receipt2}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            style={{ display: 'block' }}
                                                                        >
                                                                            <img
                                                                                src={formData.hotel_mngr_receipt2}
                                                                                alt="Travel Manager Receipt"
                                                                                style={{ width: '100%', maxHeight: '200px', objectFit: 'contain' }}
                                                                            />
                                                                        </a>
                                                                    )}
                                                                </div>
                                                                <a
                                                                    href={formData.hotel_mngr_receipt2}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    style={{ display: 'block' }}
                                                                >click to open file in new tab to download</a>
                                                            </div>
                                                        )}
                                                        {formData.hotel_mngr_receipt3 && (
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label>Receipt3:</label>
                                                                    {formData.hotel_mngr_receipt3.endsWith('.pdf') ? (
                                                                        // Display PDF file
                                                                        <a
                                                                            href={formData.hotel_mngr_receipt3}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            style={{ display: 'block' }}
                                                                        >
                                                                            <embed
                                                                                src={formData.hotel_mngr_receipt3}
                                                                                type="application/pdf"
                                                                                width="100%"
                                                                                height="200px"
                                                                                style={{ objectFit: 'contain' }}
                                                                            />
                                                                        </a>
                                                                    ) : (
                                                                        // Display image file
                                                                        <a
                                                                            href={formData.hotel_mngr_receipt3}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            style={{ display: 'block' }}
                                                                        >
                                                                            <img
                                                                                src={formData.hotel_mngr_receipt3}
                                                                                alt="Travel Manager Receipt"
                                                                                style={{ width: '100%', maxHeight: '200px', objectFit: 'contain' }}
                                                                            />
                                                                        </a>
                                                                    )}
                                                                </div>
                                                                <a
                                                                    href={formData.hotel_mngr_receipt3}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    style={{ display: 'block' }}
                                                                >click to open file in new tab to download</a>
                                                            </div>
                                                        )}</div>
                                            <div className="box-footer" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                                <button
                                                    className="btn btn-secondary  ml-3"
                                                    onClick={handleBackClick} // Handle redirect
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </form>
                                        <br />
                                        <div className="col-sm-12 row" style={{ width: '100%' }}>
                                            <div style={{ border: '#cccccc 1px solid' }} className="col-sm-4">
                                                <b>Prepared by </b>
                                                <p> {formData.emp_code} ({formData.comp_name})<br /> Date
                                                    : {formData.created_on}
                                                </p>
                                            </div>
                                            <div style={{ border: '#cccccc 1px solid' }} className="col-sm-4">
                                                {formData.rpt_mgr_status === 'Approved' && (
                                                    <>
                                                        <b>Approved by</b>
                                                        <p>
                                                            <span>
                                                                {formData.report_mngr} ({ReportManagerName})<br />
                                                                Date: {formData.manager_approved_date}
                                                            </span>
                                                        </p>
                                                    </>
                                                )}
                                            </div>

                                            

                                            <div style={{ border: '#cccccc 1px solid' }} className="col-sm-4">
                                                {formData.htl_mgr_status === 'Approved' && (
                                                    <>
                                                        <b>Verified By Travel Team</b>
                                                        <p>
                                                            {formData.hotel_manager} ({HotelManagerName})<br />
                                                            Date: {formData.hotel_approved_date}
                                                        </p>
                                                    </>
                                                )}
                                            </div>

                                        </div>
                                        <ToastContainer />
                                    </div>
                                </div>
                            </section>
                        </>
                    )}
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Hotel_Request;
