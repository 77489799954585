import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CustomToastStyles.css';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import './Travel_Desk.css';
import useAuth from './useAuth';
//import { BASE_URL } from "./Constants.js";
import Select from 'react-select';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;





const Travel_Request = () => {
    const [AdvanceStatus, setAdvanceStatus] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [EmpCode, setEmpCode] = useState('100076'); // Default Emp Code
    const [items, setItems] = useState([]); // Cities
    const [rows, setRows] = useState([{ id: Date.now(), emp_code: '', comp_name: '', dept_name: '', designation_name: '', grade: '', mobile: '' }]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [empCodes, setEmpCodes] = useState([]);
    const [selectedID, setSelectedID] = useState('');
    const [idNumber, setIdNumber] = useState('');
    const [filteredCodes, setFilteredCodes] = useState([]);
    const [currentRowId, setCurrentRowId] = useState(null);
    const [currentInput, setCurrentInput] = useState('');
    useAuth();
    const [formData, setFormData] = useState({
        from_city: '',
        to_city: '',
        travel_date: '',
        return_date: '',
        receipt: null,
        amount: '',
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, type, value, files } = e.target;
        if (type === 'file') {
            setFormData(prevState => ({
                ...prevState,
                [name]: files[0], // Get the file object directly
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    // Handle the change in the ID number input
    const handleInputChange = (e) => {
        setIdNumber(e.target.value);
    };

    const handleSelectChange = (e) => {
        setSelectedID(e.target.value);
        setIdNumber(''); // Clear the previous input if ID type is changed
    };

    const handleRowChange = (id, e) => {
        const { name, value } = e.target;

        setRows(prevRows =>
            prevRows.map(row => (row.id === id ? { ...row, [name]: value } : row))
        );
        if (value === formData.emp_code) {
            alert("Please add other BM ID for shared travel");
            return;
        }
        if (name === 'emp_code') {
            setCurrentRowId(id);
            setCurrentInput(value);
            setFilteredCodes(empCodes.filter(code => code.includes(value)));
        }
    };

    const handleSelectCode = (code) => {
        setRows(prevRows =>
            prevRows.map(row => (row.id === currentRowId ? { ...row, emp_code: code } : row))
        );
        setCurrentInput(code);
        setFilteredCodes([]);
        fetchEmployeeDetails(code);
    };

    const handleAddRow = () => {
        setRows([...rows, { id: Date.now(), emp_code: '', comp_name: '', dept_name: '', designation_name: '', grade: '', mobile: '' }]);
    };

    const handleRemoveRow = (id) => {
        setRows(prevRows => prevRows.filter(row => row.id !== id));
    };

    const validateFormData = () => {
        const { comp_name } = formData;
        if (!comp_name) {
            toast.error('All fields are required');
            return false;
        }
        return true;
    };

    const fetchCitiesData = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_BASE_URL}cities`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            if (result.status) {
                setItems(result.data);
            } else {
                setError(result.message);
            }
        } catch (error) {
            console.error('Fetch error:', error);
            setError('Failed to fetch cities data');
        }
    };

    useEffect(() => {
        const fetchProfileData = async () => {
            setLoading(true); // Set loading to true at the start
            try {
                const token = localStorage.getItem('authToken');
                console.log("token1", token);
                const response = await fetch(`${API_BASE_URL}profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                console.log("Profile data1:", result);

                if (result.status) {
                    const { profile, travel_advance } = result;

                    setEmpCode(profile.emp_code || '');
                    setAdvanceStatus(travel_advance?.advance_status || ''); // Safely access advance_status

                    const report_mngr = profile.report_mngr || '';
                    setFormData({
                        comp_name: profile.comp_name || '',
                        emp_code: profile.emp_code || '',
                        dept_name: profile.dept_name || '',
                        designation_name: profile.designation_name || '',
                        dob: profile.dob || '',
                        grade: profile.grade || '',
                        mobile: profile.mobile || '',
                        report_mngr: profile.report_mngr || '',

                    });
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            } finally {
                setLoading(false); // Set loading to false when done
            }
        };

        fetchProfileData();
    }, []);

    const fetchEmployeeDetails = async (empCode) => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_BASE_URL}users?emp_code=${empCode}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            if (result.status) {
                setRows(prevRows => {
                    const updatedRows = [...prevRows];
                    const rowIndex = updatedRows.findIndex(row => row.emp_code === empCode);
                    if (rowIndex !== -1) {
                        updatedRows[rowIndex].comp_name = result.user.comp_name;
                        updatedRows[rowIndex].dept_name = result.user.dept_name;
                        updatedRows[rowIndex].designation_name = result.user.designation_name;
                        updatedRows[rowIndex].grade = result.user.grade;
                        updatedRows[rowIndex].mobile = result.user.mobile;
                    }
                    return updatedRows;
                });
            } else {
                setError(result.message);
            }
        } catch (error) {
            console.error('Fetch error:', error);
            setError('Failed to fetch employee details');
        }
    };

    useEffect(() => {
        fetchCitiesData();
    }, []);

    useEffect(() => {
        if (EmpCode) {
            fetchEmployeeDetails(EmpCode);
        }
    }, [EmpCode]);

    const handleBackClick = () => {
        navigate("/manage_travel_request");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isSubmitting) {
            return; // Prevent multiple submissions
        }
        setIsSubmitting(true);

        // Simulate form submission (e.g., API call or any async operation)
        setTimeout(() => {
            // Reset isSubmitting after the submission is complete
            setIsSubmitting(false);

        }, 1000); // Simulating a 2-second delay for the submission process
        if (validateFormData()) {

            const isEmpCodeDuplicate = rows.some((row) => row.emp_code === formData.emp_code);

            if (isEmpCodeDuplicate) {
                // Show an error message if emp_code in any row matches the form's emp_code
                toast.error('In Sharing We need to add other BM ID');
                setIsSubmitting(false); // Allow submission again after showing the error
                return; // Prevent submission if duplicate emp_code is found
            }
            // Create a new FormData object
            const formDataToSend = new FormData();
            formDataToSend.append('age', age);
            formDataToSend.append('proof_type', selectedID);
            formDataToSend.append('proof_number', idNumber);
            // Append all form fields to FormData
            for (const key in formData) {
                formDataToSend.append(key, formData[key]);
            }

            // Append rows data with incrementing keys
            if (formData.groupType === 'Shared') {
                rows.forEach((row, index) => {
                    formDataToSend.append(`rows[${index}][emp_code]`, row.emp_code);
                    formDataToSend.append(`rows[${index}][comp_name]`, row.comp_name);
                    formDataToSend.append(`rows[${index}][dept_name]`, row.dept_name);
                    formDataToSend.append(`rows[${index}][designation_name]`, row.designation_name);
                    formDataToSend.append(`rows[${index}][grade]`, row.grade);
                    formDataToSend.append(`rows[${index}][mobile]`, row.mobile);
                });
            }

            // Append the receipt file
            const receiptFileInput = document.querySelector('input[name="receipt"]');
            if (receiptFileInput && receiptFileInput.files.length > 0) {
                formDataToSend.append('receipt', receiptFileInput.files[0]); // Append the first selected file
            }

            console.log('Form submitted:', formDataToSend);

            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}postTravelRequest`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`, // Do not set 'Content-Type' for FormData
                    },
                    body: formDataToSend, // Directly use FormData as body
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                console.log('Response from server:', result);
                navigate("/manage_travel_request");

                setShowPopup(true);
                // Handle successful response (e.g., show a success message)
            } catch (error) {
                console.error('Error submitting form:', error);
                toast.error('Error submitting form: ' + error.message);
            }
        }
        setIsSubmitting(false);
    };

    // Map items to the format React Select expects
    const cityOptions = items.map(city => ({
        value: city.id,
        label: city.city_name
    }));

    const handleCityChange = (selectedOption) => {
        // Update form data with the selected city's ID
        handleChange({
            target: {
                name: 'from_city',
                value: selectedOption ? selectedOption.value : '' // Get the ID of the selected city
            }
        });
    };

    const handleCityChange1 = (selectedOption) => {
        // Update form data with the selected city's ID
        handleChange({
            target: {
                name: 'to_city',
                value: selectedOption ? selectedOption.value : '' // Get the ID of the selected city
            }
        });
    };

    // Function to calculate the age based on the dob
    const calculateAge = (dob) => {
        const currentYear = new Date().getFullYear();
        const dobYear = new Date(dob).getFullYear();
        return currentYear - dobYear;
    };

    // Calculate the age when the component mounts or dob changes
    const age = calculateAge(formData.dob);


    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0" style={{ fontSize: '20px' }}>Travel Request Ticket Booking Info</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active">Travel Desk Booking Info</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="col-lg-12">
                        <div className="card" style={{ borderTop: '3px solid #00c0ef' }}>
                            <div className="card-body">

                                <form onSubmit={handleSubmit} encType="multipart/form-data" method="POST">
                                    <div className="box-body">
                                        <div className="row">

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="name">Name </label>
                                                    <input
                                                        type="text"
                                                        name="comp_name"
                                                        id="name"
                                                        value={formData.comp_name}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        placeholder="Name"
                                                        readonly="true"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="name">Employee No/Consultant
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="emp_code"
                                                        id="name"
                                                        value={formData.emp_code}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        placeholder="Employee No/Consultant"
                                                        readonly="true"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="name">Department</label>
                                                    <input
                                                        type="text"
                                                        name="dept_name"
                                                        id="name"
                                                        value={formData.dept_name}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        placeholder="Department&Designation"
                                                        readonly="true"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="name">Designation </label>
                                                    <input
                                                        type="text"
                                                        name="designation_name"
                                                        id="name"
                                                        value={formData.designation_name}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        placeholder="Department&Designation"
                                                        readonly="true"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="name">Employee Band as per Category </label>
                                                    <input
                                                        type="text"
                                                        name="grade"
                                                        id="name"
                                                        value={formData.grade}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        placeholder="Employee Band as per Category"
                                                        readonly="true"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="name">Mobile No
                                                    </label>
                                                    <input

                                                        type="text"
                                                        name="mobile"
                                                        id="name"
                                                        value={formData.mobile}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        placeholder="Days of travel"
                                                        readonly="true"
                                                    />
                                                </div>
                                            </div>



                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="name">Reporting Manager
                                                    </label>
                                                    <input

                                                        type="text"
                                                        name="report_mngr"
                                                        id="report_mngr"
                                                        value={formData.report_mngr}
                                                        onChange={handleChange}
                                                        className="form-control input-font"

                                                        readonly="true"
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-1'>
                                                <div className='form-group'>
                                                    <label style={{ fontSize: '12px' }} htmlFor="name">Age</label>
                                                    <input

                                                        type="text"
                                                        name="age"
                                                        id="dob"
                                                        value={age}
                                                        onChange={handleChange}
                                                        className="form-control input-font"

                                                        readonly="true"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group">
                                                    <label htmlFor="name">Single or Shared<span style={{ color: 'red' }}>*</span></label>
                                                    <br />
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            type="radio"
                                                            name="groupType"
                                                            id="Self"
                                                            value="Self"
                                                            checked={formData.groupType === 'Self'}
                                                            onChange={handleChange}
                                                            className="form-check-input"
                                                            required
                                                        />
                                                        <label htmlFor="Self" className="form-check-label">Single</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            type="radio"
                                                            name="groupType"
                                                            id="Shared"
                                                            value="Shared"
                                                            checked={formData.groupType === 'Shared'}
                                                            onChange={handleChange}
                                                            className="form-check-input"
                                                        />
                                                        <label htmlFor="Shared" className="form-check-label">Shared</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="form-group">
                                                    <label htmlFor="name">One way or Two way<span style={{ color: 'red' }}>*</span></label>
                                                    <br />
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            type="radio"
                                                            name="way"
                                                            id="one_way"
                                                            value="one_way"
                                                            checked={formData.way === 'one_way'}
                                                            onChange={handleChange}
                                                            className="form-check-input"
                                                            required
                                                        />
                                                        <label htmlFor="one_way" className="form-check-label">One-way</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            type="radio"
                                                            name="way"
                                                            id="two_way"
                                                            value="two_way"
                                                            checked={formData.way === 'two_way'}
                                                            onChange={handleChange}
                                                            className="form-check-input"
                                                        />
                                                        <label htmlFor="two_way" className="form-check-label">Two-way</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="name">Pickup Point
                                                    </label>
                                                    <textarea

                                                        type="text"
                                                        name="boarding_point"
                                                        id="boarding_point"
                                                        value={formData.boarding_point}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        required
                                                    />
                                                </div>
                                            </div>


                                        </div>
                                        {formData.groupType === 'Shared' && (
                                            <div className="expense-table-container" style={{ textAlign: 'right' }}>
                                                <button type="button" onClick={handleAddRow} className="btn btn-primary">+</button>
                                                <table className="expense-table">
                                                    <thead>
                                                        <p style={{ color: 'Red' }}>This is for Shared Details</p>
                                                        <tr className="header-row">
                                                            <th>Shared By BMID<span style={{ color: 'red' }}>*</span></th>
                                                            <th>Name</th>
                                                            <th>Dept</th>
                                                            <th>Designation</th>
                                                            <th>Grade</th>
                                                            <th>Mobile</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {rows.map(row => (
                                                            <tr key={row.id}>
                                                                <td data-label="Emp Code">
                                                                    <input
                                                                        type="text"
                                                                        name="emp_code"
                                                                        value={row.emp_code}
                                                                        onChange={(e) => {
                                                                            handleRowChange(row.id, e);
                                                                            setEmpCode(e.target.value); // Update EmpCode state to trigger API fetch
                                                                        }}
                                                                        className="form-control input-font"

                                                                        required
                                                                    />
                                                                    {filteredCodes.length > 0 && (
                                                                        <ul className="suggestions">
                                                                            {filteredCodes.map((code) => (
                                                                                <li key={code} onClick={() => handleSelectCode(code)}>
                                                                                    {code}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    )}
                                                                </td>
                                                                <td data-label="Name">
                                                                    <input
                                                                        type="text"
                                                                        name="comp_name"
                                                                        value={row.comp_name}
                                                                        onChange={(e) => handleRowChange(row.id, e)}
                                                                        className="form-control input-font"
                                                                        placeholder="Name"
                                                                        readOnly
                                                                    />
                                                                </td>
                                                                <td data-label="Dept">
                                                                    <input
                                                                        type="text"
                                                                        name="dept_name"
                                                                        value={row.dept_name}
                                                                        onChange={(e) => handleRowChange(row.id, e)}
                                                                        className="form-control input-font"
                                                                        placeholder="Dept"
                                                                        readOnly
                                                                    />
                                                                </td>
                                                                <td data-label="Designation">
                                                                    <input
                                                                        type="text"
                                                                        name="designation_name"
                                                                        value={row.designation_name}
                                                                        onChange={(e) => handleRowChange(row.id, e)}
                                                                        className="form-control input-font"
                                                                        placeholder="Designation"
                                                                        readOnly
                                                                    />
                                                                </td>
                                                                <td data-label="Grade">
                                                                    <input
                                                                        type="text"
                                                                        name="grade"
                                                                        value={row.grade}
                                                                        onChange={(e) => handleRowChange(row.id, e)}
                                                                        className="form-control input-font"
                                                                        placeholder="Grade"
                                                                        readOnly
                                                                    />
                                                                </td>
                                                                <td data-label="Mobile">
                                                                    <input
                                                                        type="text"
                                                                        name="mobile"
                                                                        value={row.mobile}
                                                                        onChange={(e) => handleRowChange(row.id, e)}
                                                                        className="form-control input-font"
                                                                        placeholder="Mobile"
                                                                        readOnly
                                                                    />
                                                                </td>
                                                                <td data-label="Actions">
                                                                    <button type="button" onClick={() => handleRemoveRow(row.id)} className="btn btn-danger">×</button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>)}

                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="from_city">
                                                        From City <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <Select
                                                        name="from_city"
                                                        value={cityOptions.find(option => option.value === formData.from_city)} // Set selected city
                                                        onChange={handleCityChange} // Handle selection
                                                        options={cityOptions} // Provide options
                                                        className="form-control input-font custom-select"
                                                        required
                                                        isClearable={true} // Allow clearing the selection
                                                        placeholder="Select a city" // Placeholder text
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label htmlFor="to_city">To City <span style={{ color: 'red' }}>*</span></label>
                                                            <select
                                                                name="to_city"
                                                                value={formData.to_city}
                                                                onChange={handleChange}
                                                                className="form-control input-font custom-select"
                                                                required
                                                            >
                                                                <option value="">Select a city</option>
                                                                {items.map(city => (
                                                                    <option key={city.id} value={city.id}>
                                                                        {city.city_name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div> */}
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="from_city">
                                                        To City <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <Select
                                                        name="to_city"
                                                        value={cityOptions.find(option => option.value === formData.to_city)} // Set selected city
                                                        onChange={handleCityChange1} // Handle selection
                                                        options={cityOptions} // Provide options
                                                        className="form-control input-font custom-select"
                                                        required
                                                        isClearable={true} // Allow clearing the selection
                                                        placeholder="Select a city" // Placeholder text
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="travel_date">Travel Date <span style={{ color: 'red' }}>*</span></label>
                                                    <input
                                                        type="date"
                                                        name="travel_date"
                                                        value={formData.travel_date}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        required
                                                        min={new Date().toISOString().split("T")[0]} // Sets the minimum date to today
                                                    />
                                                </div>
                                            </div>
                                            {formData.way === 'two_way' && (
                                                <div className='col-md-3'><label htmlFor="name">Return-Date<span style={{ color: 'red' }}>*</span></label>
                                                    <input
                                                        type="date"
                                                        name="return_date"
                                                        value={formData.return_date}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        required
                                                    /></div>)}
                                        </div>
                                        <br />
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <div className="form-group">

                                                    <label htmlFor="idType">Select ID Type:</label>
                                                    <select
                                                        id="idType"
                                                        value={selectedID}
                                                        name="proof_type"
                                                        onChange={handleSelectChange}
                                                        className="form-control input-font"
                                                    >
                                                        <option value="">Select ID Type</option>
                                                        <option value="aadhaar">Aadhaar</option>
                                                        <option value="voterId">Voter ID</option>
                                                        <option value="drivingLicense">Driving License</option>
                                                    </select>
                                                </div></div>

                                            {/* Conditionally render input field based on selected ID type */}
                                            {selectedID && (
                                                <div className='col-md-4'>
                                                    <div className="form-group">
                                                        <label htmlFor="idNumber">{selectedID.replace(/([A-Z])/g, ' $1').trim()} Number:</label>
                                                        <input
                                                            type="text"
                                                            id="idNumber"
                                                            value={idNumber}
                                                            name="proof_number"
                                                            onChange={handleInputChange}
                                                            className="form-control input-font"
                                                            placeholder={`Enter ${selectedID.replace(/([A-Z])/g, ' $1').trim()} Number`}
                                                            required
                                                        />
                                                    </div></div>
                                            )}
                                        </div>

                                    </div>
                                    <br />
                                    <div className="box-footer" style={{ textAlign: 'center' }}>
                                        <button type="submit" className="btn btn-success pull-right" disabled={isSubmitting} >Submit</button>
                                        <button
                                                    className="btn btn-secondary  ml-3"
                                                    onClick={handleBackClick} // Handle redirect
                                                >
                                                    Cancel
                                                </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer />
                <ToastContainer />
            </div>
        </div>
    );
};

export default Travel_Request;
