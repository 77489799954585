import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import './Travel_Desk.css';
import useAuth from './useAuth';
//import { BASE_URL } from "./Constants.js";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const Loader = () => (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
        <p>Loading...</p>
    </div>
);

const Edit_Travel_Manager_Form = () => {
    const { id } = useParams();
    const [EmpCode, setEmpCode] = useState('100076');
    const [isApproved, setIsApproved] = useState(false);
    const [ReportManagerName, setReportManagerName] = useState('');
    const [items, setItems] = useState([]); // Cities
    const [rows, setRows] = useState([]); // Rows for employee details
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const [formData, setFormData] = useState({
        from_city: '',
        to_city: '',
        travel_date: '',
        return_date: '',
        receipt: null,
        amount: '',
        receiptPreview: null, // Preview state
    });

    useAuth();
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];  // Grab the first file
        if (file) {
            // Directly set the file into the formData without resizing or processing
            setFormData({
                ...formData,
                travel_receipt: file, // Store the file directly in formData
            });
        }
    };

    const handleFileChange2 = async (event) => {
     

        const file = event.target.files[0];  // Grab the first file
        if (file) {
            // Directly set the file into the formData without resizing or processing
            setFormData({
                ...formData,
                travel_receipt2: file, // Store the file directly in formData
            });
        }
    };

    const handleFileChange3 = (event) => {
        const file = event.target.files[0];  // Grab the first file

        if (file) {
            // Directly set the file into the formData without resizing or processing
            setFormData({
                ...formData,
                travel_receipt3: file, // Store the file directly in formData
            });
        }
    };


    const handleRowChange = (id, e) => {
        const { name, value } = e.target;
        setRows(prevRows =>
            prevRows.map(row => (row.id === id ? { ...row, [name]: value } : row))
        );
    };
    const fetchEmployeeDetails = async (empCode) => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_BASE_URL}users?emp_code=${empCode}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            if (result.status) {
                setRows(prevRows => {
                    const updatedRows = [...prevRows];
                    const rowIndex = updatedRows.findIndex(row => row.emp_code === empCode);
                    if (rowIndex !== -1) {
                        updatedRows[rowIndex].comp_name = result.user.comp_name;
                        updatedRows[rowIndex].dept_name = result.user.dept_name;
                        updatedRows[rowIndex].designation_name = result.user.designation_name;
                        updatedRows[rowIndex].grade = result.user.grade;
                        updatedRows[rowIndex].mobile = result.user.mobile;
                    }
                    return updatedRows;
                });
            } else {
                setError(result.message);
            }
        } catch (error) {
            console.error('Fetch error:', error);
            // setError('Failed to fetch employee details');
        }
    };

    useEffect(() => {
        if (EmpCode) {
            fetchEmployeeDetails(EmpCode);
        }
    }, [EmpCode]);

    const fetchItems = async () => {
        try {
            const token = localStorage.getItem("authToken") || null;
            if (!token) return;

            const response = await fetch(`${API_BASE_URL}getTravelRequest?id=${id}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            if (result.status) {
                const travelDesk = result.travel_request;
                const travel_mngr_status = result.travel_request.travel_mngr_status || '';
                setReportManagerName(result.reportManagerName.comp_name || '');
                setFormData({
                    comp_name: travelDesk.comp_name,
                    travel_mngr_receipt: travelDesk.travel_mngr_receipt,
                    travel_mngr_receipt2: travelDesk.travel_mngr_receipt2,
                    travel_mngr_receipt3: travelDesk.travel_mngr_receipt3,
                    emp_code: travelDesk.emp_code,
                    dept_name: travelDesk.dept_name,
                    designation_name: travelDesk.designation_name,
                    rpt_mgr_status: travelDesk.rpt_mgr_status,
                    trans_code: travelDesk.trans_code,
                    travel_mngr_status: travelDesk.travel_mngr_status,
                    created_on: travelDesk.created_on || '',
                    manager_remarks: travelDesk.manager_remarks,
                    travel_remarks: travelDesk.travel_remarks,
                    manager_approved_date: travelDesk.manager_approved_date,
                    grade: travelDesk.grade,
                    mobile: travelDesk.mobile,
                    report_mngr: travelDesk.report_mngr,
                    from_city: travelDesk.from_city,
                    to_city: travelDesk.to_city,
                    return_date: travelDesk.return_date,
                    boarding_point: travelDesk.boarding_point,
                    proof_type: travelDesk.proof_type,
                    proof_number: travelDesk.proof_number,
                    travel_mngr_amount: travelDesk.travel_mngr_amount,
                    no_of_persons: travelDesk.no_of_persons,
                    age: travelDesk.age,
                    bank_card: travelDesk.bank_card,
                    source_of_travel: travelDesk.source_of_travel,
                    //  return_date: travelDesk.return_date ? new Date(travelDesk.return_date).toISOString().split('T')[0] : '',
                    travel_date: travelDesk.travel_date ? new Date(travelDesk.travel_date).toISOString().split('T')[0] : '', // Format date
                });
                setIsApproved(travel_mngr_status === 'Approved');
                setRows(travelDesk.related_details.map(detail => ({
                    id: detail.id,
                    emp_code: detail.emp_code,
                    comp_name: detail.comp_name,
                    dept_name: detail.dept_name,
                    designation_name: detail.designation_name,
                    grade: detail.grade,
                    mobile: detail.mobile,
                })));
            } else {
                setError(result.message);
            }
        } catch (error) {
            console.error("Fetch error:", error);
            //  setError("Failed to fetch items");
        } finally {
            setLoading(false);
        }
    };

    const fetchCitiesData = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_BASE_URL}cities`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            if (result.status) {
                setItems(result.data);
            } else {
                setError(result.message);
            }
        } catch (error) {
            console.error('Fetch error:', error);
            setError('Failed to fetch cities data');
        }
    };

    useEffect(() => {
        fetchItems();
        fetchCitiesData(); // Ensure this runs on component mount
    }, [id]);

    useEffect(() => {
        const fetchProfileData = async () => {
            setLoading(true); // Set loading to true at the start
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    const { profile } = result;

                    setEmpCode(profile.emp_code || '');


                }
            } catch (error) {
                console.error('Fetch error:', error);
            } finally {
                setLoading(false); // Set loading to false when done
            }
        };

        fetchProfileData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("authToken") || null;
        if (!token) return;

        // Validate travel_date
        if (!formData.travel_date) {
            toast.error("Travel date is required.");
            return;
        }

        if (formData.travel_receipt3) {
        
          
            const files = [
                formData.travel_receipt,
                formData.travel_receipt2,
                formData.travel_receipt3
            ];
            
            // Maximum allowed file size (2MB in bytes)
            const MAX_SIZE = 2 * 1024 * 1024;
            
            for (let i = 0; i < files.length; i++) {
                // Check if the file exists and is defined
                if (files[i]) {
                    const fileSize = files[i].size; // Size in bytes
            
                    // If the file exceeds the 2MB limit, show an alert and stop further checks
                    if (fileSize > MAX_SIZE) {
                        alert(`The file size for 'travel_receipt${i + 1}' exceeds the 2MB limit. Please upload a smaller file.`);
                        return; // Stop further checks and exit
                    }
                }
            }
            
        }

        const formDataToSend = new FormData();
        formDataToSend.append('travel_manager', EmpCode);
        formDataToSend.append('travel_mngr_status', formData.travel_mngr_status);
        formDataToSend.append('travel_remarks', formData.travel_remarks);
        formDataToSend.append('rpt_mgr_status', formData.rpt_mgr_status);
        formDataToSend.append('manager_remarks', formData.manager_remarks);
        formDataToSend.append('travel_mngr_amount', formData.travel_mngr_amount);
        formDataToSend.append('no_of_persons', formData.no_of_persons);
        formDataToSend.append('bank_card', formData.bank_card);
        formDataToSend.append('source_of_travel', formData.source_of_travel);


        // Append the travel receipt file to the FormData
        if (formData.travel_receipt) {
            formDataToSend.append('travel_mngr_receipt', formData.travel_receipt); // Adding the file
        }
        if (formData.travel_receipt2) {
            formDataToSend.append('travel_mngr_receipt2', formData.travel_receipt2); // Adding the file
        }
        if (formData.travel_receipt3) {
            formDataToSend.append('travel_mngr_receipt3', formData.travel_receipt3); // Adding the file
        }


        // Append related details
        rows.forEach((row, index) => {
            Object.keys(row).forEach(key => {
                formDataToSend.append(`related_details[${index}][${key}]`, row[key]);
            });
        });

        try {
            const response = await fetch(`${API_BASE_URL}updateTravelRequestForm/${id}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formDataToSend,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            if (result.status) {
                toast.success("Travel desk updated successfully!");
                navigate(`/travel_approval`); // Redirect after success
            } else {
                toast.error(result.message || "Update failed.");
            }
        } catch (error) {
            console.error("Submission error:", error);
            toast.error("Failed to update travel desk.");
        }
    };

    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0" style={{ fontSize: '20px' }}>Travel Request Ticket Booking Info ({formData.trans_code})</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                        <li className="breadcrumb-item"><a href="/manage_travel_desk">Travel Details</a></li>
                                        <li className="breadcrumb-item active">Travel Desk Booking Info</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <Loader /> // Show loader if loading is true
                    ) : (
                        <>
                            <section className="col-lg-12">
                                <div className="card" style={{ borderTop: '3px solid #00c0ef' }}>
                                    <div className="card-body">
                                        {error && <div className="alert alert-danger">{error}</div>}
                                        <form encType="multipart/form-data" onSubmit={handleSubmit}>
                                            <div className="box-body">
                                                <div className="row">

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="name">Name </label>
                                                            <input
                                                                type="text"
                                                                name="comp_name"
                                                                id="name"
                                                                value={formData.comp_name}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                placeholder="Name"
                                                                readonly="true"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="name">Employee No/Consultant
                                                                </label>
                                                            <input
                                                                type="text"
                                                                name="emp_code"
                                                                id="name"
                                                                value={formData.emp_code}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                placeholder="Employee No/Consultant"
                                                                readonly="true"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="name">Department</label>
                                                            <input
                                                                type="text"
                                                                name="dept_name"
                                                                id="name"
                                                                value={formData.dept_name}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                placeholder="Department&Designation"
                                                                readonly="true"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="name">Designation </label>
                                                            <input
                                                                type="text"
                                                                name="designation_name"
                                                                id="name"
                                                                value={formData.designation_name}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                placeholder="Department&Designation"
                                                                readonly="true"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="name">Employee Band as per Category </label>
                                                            <input
                                                                type="text"
                                                                name="grade"
                                                                id="name"
                                                                value={formData.grade}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                placeholder="Employee Band as per Category"
                                                                readonly="true"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="name">Mobile No
                                                                </label>
                                                            <input

                                                                type="text"
                                                                name="mobile"
                                                                id="name"
                                                                value={formData.mobile}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                placeholder="Days of travel"
                                                                readonly="true"
                                                            />
                                                        </div>
                                                    </div>



                                                </div>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label htmlFor="from_city">From City </label>
                                                            <select
                                                                name="from_city"
                                                                value={formData.from_city}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                required
                                                                disabled
                                                            >
                                                                <option value="">Select From City</option>
                                                                {items.map(city => (
                                                                    <option key={city.id} value={city.id}>
                                                                        {city.city_name} {/* Display city_name */}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label htmlFor="to_city">To City </label>
                                                            <select
                                                                name="to_city"
                                                                value={formData.to_city}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                required
                                                                disabled
                                                            >
                                                                <option value="">Select To City</option>
                                                                {items.map(city => (
                                                                    <option key={city.id} value={city.id}>
                                                                        {city.city_name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-1">
                                                        <div className="form-group">
                                                            <label htmlFor="travel_date">Age </label>
                                                            <input
                                                                type="text"
                                                                name="age"
                                                                value={formData.age || ''}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                required
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label htmlFor="travel_date">Travel Date </label>
                                                            <input
                                                                type="date"
                                                                name="travel_date"
                                                                value={formData.travel_date || ''}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                required
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                   
                                                    {formData.return_date && (
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="return_date">Return Date </label>
                                                                <input
                                                                    type="date"
                                                                    name="return_date"
                                                                    value={formData.return_date || ''}
                                                                    onChange={handleChange}
                                                                    className="form-control input-font"
                                                                    required
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                {rows.length > 0 ? (
                                                    <div className="expense-table-container" style={{ textAlign: 'right' }}>
                                                        <table className="expense-table">
                                                            <thead>
                                                                <tr className="header-row">
                                                                    <th>Emp Code<span style={{ color: 'red' }}>*</span></th>
                                                                    <th>Name</th>
                                                                    <th>Dept</th>
                                                                    <th>Designation</th>
                                                                    <th>Grade</th>
                                                                    <th>Mobile</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {rows.map(row => (  // Correctly using curly braces here
                                                                    <tr key={row.id}>
                                                                        <td data-label="Emp Code">
                                                                            <input
                                                                                type="text"
                                                                                name="emp_code"
                                                                                value={row.emp_code}
                                                                                onChange={(e) => {
                                                                                    handleRowChange(row.id, e);
                                                                                    setEmpCode(e.target.value); // Update EmpCode state to trigger API fetch
                                                                                }}
                                                                                className="form-control input-font"
                                                                                placeholder="Emp Code"
                                                                                required
                                                                                disabled
                                                                            />
                                                                        </td>
                                                                        <td data-label="Name">
                                                                            <input
                                                                                type="text"
                                                                                name="comp_name"
                                                                                value={row.comp_name}
                                                                                className="form-control input-font"
                                                                                placeholder="Name"
                                                                                readOnly
                                                                            />
                                                                        </td>
                                                                        <td data-label="Dept">
                                                                            <input
                                                                                type="text"
                                                                                name="dept_name"
                                                                                value={row.dept_name}
                                                                                className="form-control input-font"
                                                                                placeholder="Dept"
                                                                                readOnly
                                                                            />
                                                                        </td>
                                                                        <td data-label="Designation">
                                                                            <input
                                                                                type="text"
                                                                                name="designation_name"
                                                                                value={row.designation_name}
                                                                                className="form-control input-font"
                                                                                placeholder="Designation"
                                                                                readOnly
                                                                            />
                                                                        </td>
                                                                        <td data-label="Grade">
                                                                            <input
                                                                                type="text"
                                                                                name="grade"
                                                                                value={row.grade}
                                                                                className="form-control input-font"
                                                                                placeholder="Grade"
                                                                                readOnly
                                                                            />
                                                                        </td>
                                                                        <td data-label="Mobile">
                                                                            <input
                                                                                type="text"
                                                                                name="mobile"
                                                                                value={row.mobile}
                                                                                className="form-control input-font"
                                                                                placeholder="Mobile"
                                                                                readOnly
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>

                                                    </div>

                                                ) : (
                                                    <div className="expense-table-container" style={{ textAlign: 'right', display: 'none' }}>
                                                        <table className="expense-table">
                                                            <thead>
                                                                <tr className="header-row">
                                                                    <th>Emp Code<span style={{ color: 'red' }}>*</span></th>
                                                                    <th>Name</th>
                                                                    <th>Dept</th>
                                                                    <th>Designation</th>
                                                                    <th>Grade</th>
                                                                    <th>Mobile</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan="6" style={{ textAlign: 'center' }}>
                                                                        No records found
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                                <div className='row'>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="rpt_mgr_status">Reporting Manager Status
                                                            
                                                            </label>
                                                            <select
                                                                name="rpt_mgr_status"
                                                                id="rpt_mgr_status"
                                                                value={formData.rpt_mgr_status || ''}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                required
                                                                disabled
                                                            >

                                                                <option value="Pending">Pending</option>
                                                                <option value="Approved">Approved</option>
                                                                <option value="Rejected">Rejected</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="remarks">Reporting Manager Remarks
                                                                
                                                            </label>

                                                            <textarea name="manager_remarks"
                                                                disabled
                                                                required
                                                                id="remarks"
                                                                value={formData.manager_remarks || ''}
                                                                onChange={handleChange}
                                                                className="form-control "
                                                                rows="1" 
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label htmlFor="return_date">Pickup Point</label>
                                                            <textarea
                                                                type="text"
                                                                name="boarding_point"
                                                                value={formData.boarding_point || ''}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                               
                                                                required
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label htmlFor="return_date">Proof</label>
                                                            <textarea
                                                                type="text"
                                                                name="boarding_point"
                                                                value={`${formData.proof_number} (${formData.proof_type})`} 
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                rows="1" 
                                                                required
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="travel_mngr_status">Travel Desk Status
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <select
                                                                name="travel_mngr_status"
                                                                id="travel_mngr_status"
                                                                value={formData.travel_mngr_status || ''}
                                                                onChange={handleChange}
                                                                className="form-control input-font custom-select"
                                                                required

                                                            >

                                                                <option value="Pending">Pending</option>
                                                                <option value="Approved">Approved</option>
                                                                <option value="Rejected">Rejected</option>

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="travel_remarks">Travel Desk Remarks

                                                            </label>

                                                            <textarea name="travel_remarks"


                                                                id="remarks"
                                                                value={formData.travel_remarks || ''}
                                                                onChange={handleChange}
                                                                className="form-control "
                                                            ></textarea>
                                                        </div>
                                                    </div>

                                                    {/* Add a new column for Travel Receipt */}
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="travel_receipt">
                                                                Travel Receipt1

                                                            </label>
                                                            <input
                                                                type="file"
                                                                accept=".jpg,.jpeg,.png,.pdf"
                                                                name="travel_receipt"
                                                                id="travel_receipt"
                                                                onChange={handleFileChange}  // Create this function to handle file input
                                                                className="form-control input-font"

                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="travel_mngr_amount">
                                                                Ticket Price

                                                            </label>
                                                            <input
                                                                type="number"
                                                                name="travel_mngr_amount"
                                                                id="travel_mngr_amount"
                                                                value={Math.trunc(formData.travel_mngr_amount) || ''}
                                                                onChange={handleChange}  // Create this function to handle file input
                                                                className="form-control input-font"

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="no_of_persons">
                                                                No of Persons

                                                            </label>
                                                            <input
                                                                type="number"
                                                                name="no_of_persons"
                                                                id="no_of_persons"
                                                                value={formData.no_of_persons || ''}
                                                                onChange={handleChange}  // Create this function to handle file input
                                                                className="form-control input-font"

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="bank_card">
                                                                Bank Card

                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="bank_card"
                                                                id="bank_card"
                                                                value={formData.bank_card || ''}
                                                                onChange={handleChange}  // Create this function to handle file input
                                                                className="form-control input-font"
                                                                maxLength="4"
                                                            />
                                                        </div>
                                                    </div></div>
                                                <div className='row'><div className="col-md-4">
                                                    <div className="form-group">
                                                        <label style={{ fontSize: '12px' }} htmlFor="source_of_travel">
                                                            Source Of Travel

                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="source_of_travel"
                                                            id="source_of_travel"
                                                            value={formData.source_of_travel || ''}
                                                            onChange={handleChange}  // Create this function to handle file input
                                                            className="form-control input-font"

                                                        />
                                                    </div>
                                                </div><div className="col-md-4">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="travel_receipt2">
                                                                Travel Receipt2

                                                            </label>
                                                            <input
                                                                type="file"
                                                                accept=".jpg,.jpeg,.png,.pdf"
                                                                name="travel_receipt2"
                                                                id="travel_receipt2"
                                                                onChange={handleFileChange2}  // Create this function to handle file input
                                                                className="form-control input-font"

                                                            />
                                                        </div>
                                                    </div><div className="col-md-4">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="travel_receipt3">
                                                                Travel Receipt3

                                                            </label>
                                                            <input
                                                                type="file"
                                                                accept=".jpg,.jpeg,.png,.pdf"
                                                                name="travel_receipt3"
                                                                id="travel_receipt3"
                                                                onChange={handleFileChange3}  // Create this function to handle file input
                                                                className="form-control input-font"

                                                            />
                                                        </div>
                                                    </div></div>
                                                <div className='row'>
                                                    {formData.travel_mngr_receipt && (
                                                        <div className="col-md-4">
                                                            <div className="form-group">
    <label>Receipt:</label>
    {formData.travel_mngr_receipt && (
        formData.travel_mngr_receipt.match(/\.(pdf)$/i) ? (
            // Display PDF file
            <a
                href={formData.travel_mngr_receipt}
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: 'block' }}
            >
                <embed
                    src={formData.travel_mngr_receipt}
                    type="application/pdf"
                    width="100%"
                    height="200px"
                    style={{ objectFit: 'contain' }}
                />
            </a>
        ) : formData.travel_mngr_receipt.match(/\.(jpg|jpeg|png)$/i) ? (
            // Display image file
            <a
                href={formData.travel_mngr_receipt}
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: 'block' }}
            >
                <img
                    src={formData.travel_mngr_receipt}
                    alt="Travel Manager Receipt"
                    style={{ width: '100%', maxHeight: '200px', objectFit: 'contain' }}
                />
            </a>
        ) : (
            <p>Invalid file type</p> // Handle cases where the file is neither a PDF nor an image
        )
    )}
</div>

                                                            <a
                                                                href={formData.travel_mngr_receipt}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                style={{ display: 'block' }}
                                                            >click to open ticket in new tab to download</a>
                                                        </div>
                                                    )}
                                                    {formData.travel_mngr_receipt2 && (
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label>Receipt2:</label>
                                                                {formData.travel_mngr_receipt2.endsWith('.pdf') ? (
                                                                    // Display PDF file
                                                                    <a
                                                                        href={formData.travel_mngr_receipt2}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        style={{ display: 'block' }}
                                                                    >
                                                                        <embed
                                                                            src={formData.travel_mngr_receipt2}
                                                                            type="application/pdf"
                                                                            width="100%"
                                                                            height="200px"
                                                                            style={{ objectFit: 'contain' }}
                                                                        />
                                                                    </a>
                                                                ) : (
                                                                    // Display image file
                                                                    <a
                                                                        href={formData.travel_mngr_receipt2}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        style={{ display: 'block' }}
                                                                    >
                                                                        <img
                                                                            src={formData.travel_mngr_receipt2}
                                                                            alt="Travel Manager Receipt"
                                                                            style={{ width: '100%', maxHeight: '200px', objectFit: 'contain' }}
                                                                        />
                                                                    </a>
                                                                )}
                                                            </div>
                                                            <a
                                                                href={formData.travel_mngr_receipt2}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                style={{ display: 'block' }}
                                                            >click to open ticket in new tab to download</a>
                                                        </div>
                                                    )}
                                                    {formData.travel_mngr_receipt3 && (
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label>Receipt3:</label>
                                                                {formData.travel_mngr_receipt3.endsWith('.pdf') ? (
                                                                    // Display PDF file
                                                                    <a
                                                                        href={formData.travel_mngr_receipt}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        style={{ display: 'block' }}
                                                                    >
                                                                        <embed
                                                                            src={formData.travel_mngr_receipt3}
                                                                            type="application/pdf"
                                                                            width="100%"
                                                                            height="200px"
                                                                            style={{ objectFit: 'contain' }}
                                                                        />
                                                                    </a>
                                                                ) : (
                                                                    // Display image file
                                                                    <a
                                                                        href={formData.travel_mngr_receipt3}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        style={{ display: 'block' }}
                                                                    >
                                                                        <img
                                                                            src={formData.travel_mngr_receipt3}
                                                                            alt="Travel Manager Receipt"
                                                                            style={{ width: '100%', maxHeight: '200px', objectFit: 'contain' }}
                                                                        />
                                                                    </a>
                                                                )}
                                                            </div>
                                                            <a
                                                                href={formData.travel_mngr_receipt3}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                style={{ display: 'block' }}
                                                            >click to open ticket in new tab to download</a>
                                                        </div>
                                                    )}</div>
                                            </div>
                                            <br />
                                            <div className="box-footer center-button">
                                                <center><button type="submit" className="btn btn-primary" >Submit</button></center>
                                            </div>
                                        </form>
                                        <div className="col-sm-12 row" style={{ width: '100%' }}>
                                            <div style={{ border: '#cccccc 1px solid' }} className="col-sm-4">
                                                <b>Prepared by </b>
                                                <p> {formData.emp_code} ({formData.comp_name})<br /> Date
                                                    : {formData.created_on}
                                                </p>
                                            </div>
                                            <div style={{ border: '#cccccc 1px solid' }} className="col-sm-4">
                                                {formData.rpt_mgr_status === 'Approved' && (
                                                    <>
                                                        <b>Approved by</b>
                                                        <p>
                                                            <span>
                                                                {formData.report_mngr} ({ReportManagerName})<br />
                                                                Date: {formData.manager_approved_date}
                                                            </span>
                                                        </p>
                                                    </>
                                                )}
                                            </div>


                                        </div>
                                        <ToastContainer />
                                    </div>
                                </div>
                            </section>
                        </>
                    )}
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Edit_Travel_Manager_Form;
