import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Header from "./Header.js";
import Sidebar from "./Sidebar.js";
import Footer from "./Footer.js";
//import { BASE_URL } from "./Constants.js";
import useAuth from './useAuth.js';
import * as XLSX from "xlsx";
import Pagination from './Pagination.js';
import "./Manage_User.css";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const Loader = () => (
	<div style={{ textAlign: 'center', marginTop: '20px' }}>
		<div className="spinner-border" role="status">
			<span className="sr-only">Loading...</span>
		</div>
		<p>Loading...</p>
	</div>
);

const Hotel_Manager = () => {
	const [EmpCode, setEmpCode] = useState('');
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
    const [RptMgr, setRptMgr] = useState('');
	const [items, setItems] = useState([]);
	const [filteredItems, setFilteredItems] = useState([]);
	const [responseMessage, setResponseMessage] = useState('');
	const [is_traveldesk, setis_traveldesk] = useState(false);
	const [is_hotelmanager, setis_hotelmanager] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [cities, setCities] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [sortColumn, setSortColumn] = useState("sysno");
	const [sortOrder, setSortOrder] = useState("desc");
	const navigate = useNavigate();
	useAuth();
	const token = localStorage.getItem("authToken") || null;
	


    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                if (result.status) {
                    setEmpCode(result.profile.emp_code || '');

                    setRptMgr(result.profile.report_mngr || '');

					setis_hotelmanager(result.profile.is_hotelmanager_approved === 'Y');
                    // fetchItems(result.profile.emp_code, result.profile.report_mngr);
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchProfileData();
    }, []);
    useEffect(() => {
        const fetchItems = async (EmpCode, RptMgr) => {
            try {
                const token = localStorage.getItem('authToken');
                console.log("RptMgr", RptMgr);
                if (!token) return;

                const response = await fetch(`${API_BASE_URL}getHotelRequest?travel_mngr=${encodeURIComponent(EmpCode)}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                console.log("checkresponse", result);
                if (result.status) {
                    setItems(result.hotel_request);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setError('Failed to fetch items');
            } finally {
                setLoading(false);
            }
        };




        if (EmpCode) {
            fetchItems(EmpCode, RptMgr);
        }
    }, [EmpCode, navigate]);
	

	useEffect(() => {
		const fetchCitiesData = async () => {
			try {
				const token = localStorage.getItem('authToken');
				const response = await fetch(`${API_BASE_URL}cities`, {
					method: 'GET',
					headers: {
						'Authorization': `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				});
				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
				const result = await response.json();
				if (result.status) {
					setCities(result.data);
				} else {
					setError(result.message);
				}
			} catch (error) {
				console.error('Fetch error:', error);
				setError('Failed to fetch cities data');
			}
		};

		fetchCitiesData();
	}, []);

	useEffect(() => {
		if (!Array.isArray(items)) {
			setFilteredItems([]);
			return;
		}

		const lowerCaseQuery = searchQuery.toLowerCase();

		const filtered = items.filter(item => {
			const trans_code = item.trans_code ? item.trans_code.toLowerCase() : '';
			const name = item.comp_name ? item.comp_name.toLowerCase() : '';
			const ToCity = cities.find(city => city.id === item.city)?.city_name.toLowerCase() || '';
			
			
			const hotel_name = item.hotel_name ? item.hotel_name.toLowerCase() : '';
			const check_in = item.check_in ? item.check_in.toLowerCase() : '';
			const check_out = item.check_out ? item.check_out.toLowerCase() : '';
			const report_mngr = item.report_mngr ? item.report_mngr.toLowerCase() : '';
			const rpt_mgr_status = item.rpt_mgr_status ? item.rpt_mgr_status.toLowerCase() : '';
			const hotel_manager = item.hotel_manager ? item.hotel_manager.toLowerCase() : '';
			const htl_mgr_status = item.htl_mgr_status ? item.htl_mgr_status.toLowerCase() : '';
			const travel_date = item.travel_date ? item.travel_date.toLowerCase() : '';
			const empCodes = item.related_details.map(detail => detail.emp_code).join(', ').toLowerCase();

			return (
				hotel_name.includes(lowerCaseQuery) ||
				check_in.includes(lowerCaseQuery) ||
				trans_code.includes(lowerCaseQuery) ||
				name.includes(lowerCaseQuery) ||
				check_out.includes(lowerCaseQuery) ||
				report_mngr.includes(lowerCaseQuery) ||
				rpt_mgr_status.includes(lowerCaseQuery) ||
				hotel_manager.includes(lowerCaseQuery) ||
				htl_mgr_status.includes(lowerCaseQuery) ||
				ToCity.includes(lowerCaseQuery) ||
				travel_date.includes(lowerCaseQuery) ||
				empCodes.includes(lowerCaseQuery)
			);
		});

		const sorted = filtered.sort((a, b) => {
			if (a[sortColumn] < b[sortColumn]) return sortOrder === "asc" ? -1 : 1;
			if (a[sortColumn] > b[sortColumn]) return sortOrder === "asc" ? 1 : -1;
			return 0;
		});

		setFilteredItems(sorted);
		setCurrentPage(1);
	}, [searchQuery, items, sortColumn, sortOrder, cities]);

	const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

	const startItem = indexOfFirstItem + 1;
	const endItem = Math.min(indexOfLastItem, filteredItems.length);

	const handleSearchChange = e => {
		setSearchQuery(e.target.value);
	};

	const handlePageChange = pageNumber => {
		setCurrentPage(pageNumber);
	};

	const handleItemsPerPageChange = e => {
		setItemsPerPage(Number(e.target.value));
		setCurrentPage(1);
	};

	const handleSort = (column) => {
		if (column === "id") {
			if (sortColumn === column) {
				setSortOrder(sortOrder === "asc" ? "desc" : "asc");
			} else {
				setSortColumn(column);
				setSortOrder("asc");
			}
		}
	};

	const exportToExcel = () => {
		const filteredStateNames = filteredItems.map(item => ({
			trans_code: item.trans_code,
			name: item.comp_name,
			hotel_name: item.hotel_name,
			check_in: item.check_in,
			check_out: item.check_out,
			rpt_mgr_status: item.rpt_mgr_status,
			hotel_manager: item.hotel_manager,
			htl_mgr_status: item.htl_mgr_status,
			ToCity: cities.find(city => city.id === item.to_city)?.city_name || 'Unknown City',
			travel_date: item.travel_date,
			emp_code: item.related_details && item.related_details.length > 0
				? [item.emp_code, ...item.related_details.map(detail => detail.emp_code)].join(', ')
				: item.emp_code || 'N/A',
		}));
		const worksheet = XLSX.utils.json_to_sheet(filteredStateNames);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "TravelRequest");
		XLSX.writeFile(workbook, "TravelRequest.xlsx");
	};

	return (
		<>
			<div className='hold-transition sidebar-mini layout-fixed' style={{ fontSize: '12px' }}>
				<div className='wrapper'>
					<Header />
					<Sidebar />
					<div className='content-wrapper'>
						<div className='content-header'>
							<div className='container-fluid'>
								<div className='row mb-2'>
									<div className='col-sm-6'>
										<h1 className='m-0' style={{ fontSize: '20px' }}>Hotel Request Details</h1>
									</div>
									<div className='col-sm-6'>
										<ol className='breadcrumb float-sm-right'>
											<li className='breadcrumb-item' style={{ fontSize: '12px' }}>
												<Link to='/dashboard'>Home</Link>
											</li>
											<li className='breadcrumb-item active' style={{ fontSize: '12px' }}>Hotel Request Details</li>
										</ol>
									</div>
								</div>
							</div>
						</div>
						<section className='content'>
							<div className='container-fluid'>
								<div className='row'>
									<section className='col-lg-12 connectedSortable'>
										<div className='card'>
											<div className='card-header'>
												<span style={{ float: "right" }}>
													{/* <Link to='/travel_request'>
														<button type='button' className='btn btn-success'>Add New</button>
													</Link> */}
													<button type='button' className='btn btn-secondary ml-2' onClick={exportToExcel}>Export to Excel</button>
												</span>
											</div>
											<div className='card-body'>
												{loading ? (
													<Loader />
												) : (
													<>
														<div id='example1_filter' style={{ float: "right" }} className='dataTables_filter'>
															<label>
																<input
																	type='search'
																	value={searchQuery}
																	onChange={handleSearchChange}
																	className='form-control form-control-sm'
																	placeholder='Search'
																	aria-controls='example1'
																/>
															</label>
														</div>

														<div className='form-group'>
															<select
																className='form-control custom-select-sm'
																value={itemsPerPage}
																onChange={handleItemsPerPageChange}>
																<option value={10}>10</option>
																<option value={50}>50</option>
																<option value={100}>100</option>
															</select>
														</div>
														<div className="table-responsive">
															<table className='table table-bordered table-striped' style={{ fontSize: '13px' }}>
																<thead>
																	<tr>
																			<th onClick={() => handleSort("id")}>
																				ID {sortColumn === "id" && (sortOrder === "asc" ? "▲" : "▼")}
																			</th>
																			<th>Trans Code</th>
																			<th>Name</th>
																			<th>Hotel Name</th>
																			<th>City</th>
																			<th>Check - In</th>
																			<th>Check - Out</th>
																			<th>Emp Code</th>
                                                                            <th>Reporting Manager Status</th>
																			<th>Hotel Manager</th>
                                                                            <th>Hotel Manager Status</th>
																			<th>Hotel Manager Amount</th>
																			<th>Bank Card</th>
																			
																	</tr>
																</thead>
																<tbody>
																	{currentItems.length > 0 ? (
																		currentItems.map((item, index) => {
																			const City = cities.find(city => city.id === item.city)?.city_name || 'Unknown City';
																			//const toCity = cities.find(city => city.id === item.to_city)?.city_name || 'Unknown City';

																			return (
																				<tr key={index}>
																					<td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
																					<td>{item.trans_code}</td>
																					<td>{item.comp_name}</td>
																					<td>{item.hotel_name.toUpperCase()}</td>
																					<td>{City}</td>
																					
																					<td>{item.check_in}</td>
																					<td>{item.check_out}</td>
																					<td>
																						{item.related_details && item.related_details.length > 0
																							? [
																								item.emp_code,
																								...item.related_details.map(detail => detail.emp_code)
																							]
																								.filter((value, index, self) => self.indexOf(value) === index) // Remove duplicates
																								.join(', ')
																							: item.emp_code || 'N/A'}
																					</td>
                                                                                    <td style={{
                                                                                    color: item.rpt_mgr_status === 'Pending' ? 'orange' :
                                                                                        item.rpt_mgr_status === 'Rejected' ? 'red' :
                                                                                            item.rpt_mgr_status === 'Approved' ? 'green' :
                                                                                                'inherit'
                                                                                }}>
                                                                                    {item.rpt_mgr_status}
                                                                                </td>
																				<td>{item.hotel_manager ? item.hotel_manager : "Need to Approval by Travel Manager To Display"}</td>

                                                                                <td style={{
                                                                                    color: item.htl_mgr_status === 'Pending' ? 'orange' :
                                                                                        item.htl_mgr_status === 'Rejected' ? 'red' :
                                                                                            item.htl_mgr_status === 'Approved' ? 'green' :
                                                                                                'inherit'
                                                                                }}>
                                                                                    {item.htl_mgr_status}
                                                                                </td>
																				<td>{Math.trunc(item.hotel_mngr_amount)}</td>
																				<td>{item.bank_card}</td>
																					
																				</tr>
																			);
																		})
																	) : (
																		<tr>
																			<td colSpan='11'><center>No data available</center></td>
																		</tr>
																	)}
																</tbody>
															</table>
														</div>
													</>
												)}
											</div>
										</div>
										<Pagination
                                                currentPage={currentPage}
                                                totalPages={totalPages}
                                                handlePageChange={handlePageChange}
                                            />
										<div className='mb-3'>
											Showing {startItem} to {endItem} of {filteredItems.length} entries
										</div>
									</section>
								</div>
							</div>
						</section>
					</div>
					<Footer />
					<aside className='control-sidebar control-sidebar-dark'></aside>
				</div>
			</div>
		</>
	);
};

export default Hotel_Manager;
