import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CustomToastStyles.css';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import './Travel_Desk.css';
import useAuth from './useAuth';
import './Expence_Request.css';
import './Loader.css';
//import { BASE_URL } from "./Constants.js";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const Loader = () => (
//     <div style={{ textAlign: 'center', marginTop: '20px' }}>
//         <div className="spinner-border" role="status">
//             <span className="sr-only">Loading...</span>
//         </div>
//         <p>Loading...</p>
//     </div>
// );

const Loader = () => (
    <div className="loader-container1">
        <div className="spinner1"></div>
        <p>Loading...</p>
    </div>
);


const Edit_Expence_Request = () => {
    const { id } = useParams();  // Get the expense id from the URL parameter
    const [AdvanceStatus, setAdvanceStatus] = useState([]);
    const [hasSubmittedStaticData, setHasSubmittedStaticData] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [relatedDetails, setRelatedDetails] = useState([]);
    const [responseMessage, setResponseMessage] = useState('');
    const [ReportManagerName, setReportManagerName] = useState('');
    const [AuditManagerName, setAuditManagerName] = useState('');
    const [FinanceManagerName, setFinanceManagerName] = useState('');
    const [EmpCode, setEmpCode] = useState('100076'); // Default Emp Code
    const [monthlyExpenses, setMonthlyExpenses] = useState([]); // Store monthly expenses data
    const [formData, setFormData] = useState({
        comp_name: '',
        areas_visited: '',
        from_city: '',
        to_city: '',
        travel_date: '',
        return_date: '',
        receipt: null,
        amount: '',
        email: '',
        branch: '',
        rpt_mgr_status: '',
        employee_remarks: '',
        employee_remarks_audit: '',
        employee_remarks_finance: '',
    });
    const [loading, setLoading] = useState(true);
    useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch profile data
        const fetchProfileData = async () => {

            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    const { profile, travel_advance } = result;
                    setEmpCode(profile.emp_code || '');
                    setAdvanceStatus(travel_advance?.advance_status || '');
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchProfileData();
    }, []);

    useEffect(() => {
        // Fetch expense data based on id
        const fetchExpence = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}manage_expences?id=${id}`, {  // Fetch expense data for the specific id
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    const Expence_Request = result.expence_request;
                    setRelatedDetails(result.expence_request.related_details);
                    setReportManagerName(result.reportManagerName.comp_name || '');
                    setAuditManagerName(result.auditManagerName.comp_name || '');
                    setFinanceManagerName(result.accountManagerName.comp_name || '');
                    // Update state with expense details including employee_remarks
                    setFormData({
                        ...formData, // Keep other existing data intact
                        rpt_mgr_status: Expence_Request.rpt_mgr_status || '',
                        employee_remarks: Expence_Request.employee_remarks || '', // Make sure to get this from the response
                        audit_status: Expence_Request.audit_status || '',
                        account_status: Expence_Request.account_status || '',
                        emp_code: Expence_Request.emp_code || '',
                        comp_name: Expence_Request.comp_name || '',
                        designation_name: Expence_Request.designation_name || '',
                        report_mngr: Expence_Request.report_mngr || '',
                        created_on: Expence_Request.created_on || '',
                        audit_manager: Expence_Request.audit_manager || '',
                        account_manager: Expence_Request.account_manager || '',
                        manager_approved_date: Expence_Request.manager_approved_date || '',
                        audit_approved_date: Expence_Request.audit_approved_date || '',
                        account_approved_date: Expence_Request.account_approved_date || '',
                        manager_remarks: Expence_Request.manager_remarks || '',
                        audit_remarks: Expence_Request.audit_remarks || '',
                        account_remarks: Expence_Request.account_remarks || '',
                        email: Expence_Request.email || '',
                        mobile: Expence_Request.mobile || '',
                        branch: Expence_Request.branch || '',
                        trans_code: Expence_Request.trans_code || '',
                        // If necessary, populate the status
                    });
                    // Set the state for each expense
                    setMonthlyExpenses(result.expence_request.related_details.map(detail => ({
                        ...detail, // Copy the existing properties
                        areas_visited: detail.areas_visited || '', // Default to empty string if undefined
                        no_of_calls: detail.no_of_calls || '',
                        amount: detail.amount || '',
                        remarks: detail.remarks || '',



                    })));

                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchExpence();
    }, [id]); // Run effect when `id` changes

    const handleInputChange = (e, date) => {
        const { name, value } = e.target;

        // Update the respective state based on the input's name
        setMonthlyExpenses(prevExpenses =>
            prevExpenses.map(expense =>
                expense.date === date
                    ? { ...expense, [name]: value }  // Dynamically update the field based on name (e.g., areas_visited, no_of_calls, amount, remarks)
                    : expense
            )
        );
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        // If the name is 'status_select', update it and set corresponding status to 'Pending'
        if (name === 'status_select') {
            setFormData(prevData => {
                let newData = { ...prevData, [name]: value };

                // Based on the selected request type, set the corresponding status to 'Pending'
                if (value === 'request_for_manager') {
                    newData.rpt_mgr_status = 'Pending';
                } else if (value === 'request_for_audit') {
                    newData.audit_status = 'Pending';
                } else if (value === 'request_for_finance') {
                    newData.account_status = 'Pending';
                }

                return newData;
            });
        }

        // Handle other field changes (rpt_mgr_status, audit_status, account_status, etc.)
        else if (name === 'rpt_mgr_status') {
            setFormData(prevData => ({ ...prevData, [name]: value }));
        } else if (name === 'audit_status') {
            setFormData(prevData => ({ ...prevData, [name]: value }));
        } else if (name === 'account_status') {
            setFormData(prevData => ({ ...prevData, [name]: value }));
        }
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        // Calculate the total number of calls and total amount
        const totalCalls = monthlyExpenses.reduce((total, expense) => {
            return total + (Number(expense.no_of_calls) || 0);
        }, 0);

        const totalAmount = monthlyExpenses.reduce((total, expense) => {
            return total + (Number(expense.amount) || 0);
        }, 0).toFixed(2);

        // Create a new FormData object to send the data
        const formDataToSend = new FormData();
        // console.log("check",formData.employee_remarks);
        // return;
        formDataToSend.append('total_no_of_calls', totalCalls);
        formDataToSend.append('total_amount', totalAmount); // Round the amount to 2 decimal places
        formDataToSend.append('employee_remarks', formData.employee_remarks); // Ensure employee remarks are correctly appended from formData
        formDataToSend.append('rpt_mgr_status', formData.rpt_mgr_status); // Ensure employee remarks are correctly appended from formData
        formDataToSend.append('audit_status', formData.audit_status);
        formDataToSend.append('account_status', formData.account_status);
        formDataToSend.append('employee_remarks_audit', formData.employee_remarks_audit); // Ensure employee remarks are correctly appended from formData
        formDataToSend.append('employee_remarks_finance', formData.employee_remarks_finance); // Ensure employee remarks are correctly appended from formData

        // Append each expense field from your monthlyExpenses array to the FormData object
        monthlyExpenses.forEach((expense, index) => {
            formDataToSend.append(`expenses[${index}][id]`, expense.id);
            formDataToSend.append(`expenses[${index}][monthly_id]`, expense.monthly_id);
            formDataToSend.append(`expenses[${index}][emp_code]`, expense.emp_code);
            formDataToSend.append(`expenses[${index}][month]`, expense.month);
            formDataToSend.append(`expenses[${index}][date]`, expense.date);
            formDataToSend.append(`expenses[${index}][areas_visited]`, expense.areas_visited);
            formDataToSend.append(`expenses[${index}][no_of_calls]`, expense.no_of_calls);
            formDataToSend.append(`expenses[${index}][amount]`, expense.amount);
            formDataToSend.append(`expenses[${index}][remarks]`, expense.remarks);
            formDataToSend.append(`expenses[${index}][created_by]`, expense.created_by);
            formDataToSend.append(`expenses[${index}][created_on]`, expense.created_on);
        });

        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_BASE_URL}update_expences1/${id}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    // FormData handles the 'Content-Type' automatically
                },
                body: formDataToSend,
            });

            const result = await response.json();
            if (result.status) {
                toast.success('Expenses updated successfully!');
                navigate('/manage_monthly_expences');
            } else {
                toast.error('Failed to update expenses');
            }
        } catch (error) {
            console.error('Submit error:', error);
            toast.error('Error updating expenses');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                {loading ? (
                    <Loader />
                ) : (
                    <div className="content-wrapper">
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0" style={{ fontSize: '20px' }}>Monthly Expence Form {formData.trans_code && `(${formData.trans_code})`}</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>

                                            <li className="breadcrumb-item active">Monthly Expence Form</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section className="col-lg-12">
                            <div className="card" style={{ borderTop: '3px solid #00c0ef' }}>
                                <div className="card-body">
                                    <form onSubmit={handleSubmit} encType="multipart/form-data" method="POST">
                                        <div className="box-body">
                                            <div className="row">

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label style={{ fontSize: '12px' }} htmlFor="name">Name <span style={{ color: 'red', fontSize: '11px' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="comp_name"
                                                            id="name"
                                                            value={formData.comp_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Name"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label style={{ fontSize: '12px' }} htmlFor="name">Employee No/Consultant
                                                            <span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="emp_code"
                                                            id="name"
                                                            value={formData.emp_code}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Employee No/Consultant"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label style={{ fontSize: '12px' }} htmlFor="name">Designation <span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="designation_name"
                                                            id="name"
                                                            value={formData.designation_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Department&Designation"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label style={{ fontSize: '12px' }}>Reporting Manager<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="report_mngr"
                                                            value={formData.report_mngr}
                                                            className="form-control input-font"
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label style={{ fontSize: '12px' }} htmlFor="name">Employee Email  <span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="grade"
                                                            id="name"
                                                            value={formData.email}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Employee Email"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label style={{ fontSize: '12px' }} htmlFor="name">Mobile No
                                                            <span style={{ color: 'red' }}>*</span></label>
                                                        <input

                                                            type="text"
                                                            name="mobile"
                                                            id="name"
                                                            value={formData.mobile}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Days of travel"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>



                                            </div>
                                            <div className="row">
                                                {/* Existing fields */}
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label style={{ fontSize: '12px' }}>Branch<span style={{ color: 'red' }}>*</span></label>
                                                        <input
                                                            type="text"
                                                            name="branch"
                                                            value={formData.branch}
                                                            className="form-control input-font"
                                                            readonly="true"

                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-12">
                                                    <div className="table-container custom-table-container" style={{ marginTop: '20px', marginBottom: '20px' }}>
                                                        <div className="table-responsive" style={{ overflowX: 'auto' }}>
                                                            <table className="table table-hover table-mc-light-blue table-bordered table-striped table-hover table-sticky">
                                                                <thead className="thead-light">
                                                                    <tr>
                                                                        <th className="col-md-2" style={{ padding: '12px 10px', backgroundColor: '#09b8aa', color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>
                                                                            Date
                                                                        </th>
                                                                        <th className="col-md-2" style={{ padding: '12px 10px', backgroundColor: '#09b8aa', color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>
                                                                            Areas Visited
                                                                        </th>
                                                                        <th className="col-md-2" style={{ padding: '12px 10px', backgroundColor: '#09b8aa', color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>
                                                                            No of Calls
                                                                        </th>
                                                                        <th className="col-md-2" style={{ padding: '12px 10px', backgroundColor: '#09b8aa', color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>
                                                                            Amount
                                                                        </th>
                                                                        <th className="col-md-2" style={{ padding: '12px 10px', backgroundColor: '#09b8aa', color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>
                                                                            Remarks
                                                                        </th>
                                                                        {/* Render 'Manager Remarks' Column Conditionally */}
                                                                        {monthlyExpenses.length > 0 && monthlyExpenses[0].manager_remarks1 && (
                                                                            <th className="col-md-2" style={{ padding: '12px 10px', backgroundColor: '#09b8aa', color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>
                                                                                Manager Remarks
                                                                            </th>
                                                                        )}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {/* Check if there is any expense data */}
                                                                    {monthlyExpenses.length > 0 ? (
                                                                        monthlyExpenses.map((detail) => (
                                                                            <tr key={detail.id}>
                                                                                <td style={{ width: '20%', textAlign: 'center' }}>{detail.date}</td>

                                                                                <td>
                                                                                    <input
                                                                                        type="text"
                                                                                        name="areas_visited"
                                                                                        value={detail.areas_visited || ''}
                                                                                        onChange={(e) => handleInputChange(e, detail.date)}
                                                                                        className="form-control input-font"
                                                                                    />
                                                                                </td>

                                                                                <td>
                                                                                    <input
                                                                                        type="number"
                                                                                        name="no_of_calls"
                                                                                        value={detail.no_of_calls || ''}
                                                                                        onChange={(e) => handleInputChange(e, detail.date)}
                                                                                        className="form-control input-font"
                                                                                    />
                                                                                </td>

                                                                                <td>
                                                                                    <input
                                                                                        type="number"
                                                                                        name="amount"
                                                                                        value={detail.amount || ''}
                                                                                        onChange={(e) => handleInputChange(e, detail.date)}
                                                                                        className="form-control input-font"
                                                                                    />
                                                                                </td>

                                                                                <td>
                                                                                    <input
                                                                                        type="text"
                                                                                        name="remarks"
                                                                                        value={detail.remarks || ''}
                                                                                        onChange={(e) => handleInputChange(e, detail.date)}
                                                                                        className="form-control input-font"
                                                                                    />
                                                                                </td>

                                                                                {/* Render 'Manager Remarks' Conditionally */}
                                                                                {monthlyExpenses.length > 0 && monthlyExpenses[0].manager_remarks1 && (
                                                                                    <td>
                                                                                        <input
                                                                                            type="text"
                                                                                            name="manager_remarks1"
                                                                                            value={detail.manager_remarks1 || ''}
                                                                                            className="form-control input-font"
                                                                                            disabled
                                                                                        />
                                                                                    </td>
                                                                                )}
                                                                            </tr>
                                                                        ))
                                                                    ) : (
                                                                        <tr>
                                                                            <td colSpan={monthlyExpenses.length > 0 && monthlyExpenses[0].manager_remarks1 ? 6 : 5} style={{ textAlign: 'center' }}>
                                                                                No expenses data available
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>

                                                                <tfoot className="tfoot-light">
                                                                    <tr>
                                                                        <td colSpan="2">
                                                                            <strong>Total</strong>
                                                                        </td>
                                                                        <td>
                                                                            <strong>
                                                                                {/* Calculate total number of calls */}
                                                                                {monthlyExpenses.reduce((total, expense) => total + (Number(expense.no_of_calls) || 0), 0)}
                                                                            </strong>
                                                                        </td>
                                                                        <td>
                                                                            <strong>
                                                                                {/* Calculate total amount */}
                                                                                {monthlyExpenses.reduce((total, expense) => total + (Number(expense.amount) || 0), 0)} {/* Format to 2 decimal places */}
                                                                            </strong>
                                                                        </td>
                                                                        <td></td>
                                                                        {/* If 'Manager Remarks' column is present */}
                                                                        {monthlyExpenses.length > 0 && monthlyExpenses[0].manager_remarks1 && <td></td>}
                                                                    </tr>
                                                                </tfoot>
                                                            </table>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            {/* <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="rpt_mgr_status">Request For Pending
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <select
                                                        name="rpt_mgr_status"
                                                        id="rpt_mgr_status"
                                                        value={formData.rpt_mgr_status || ''}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        required
                                                    >

                                                        <option value="Pending">Pending</option>
                                                        <option value="Approved">Approved</option>
                                                        <option value="Rejected">Rejected</option>
                                                    </select>
                                                </div>
                                            </div> */}
                                            {formData.manager_remarks && (
                                                <div className="col-md-4">

                                                    <div className="form-group">
                                                        <label style={{ fontSize: '12px' }} htmlFor="remarks">
                                                            Reporting Manager Remarks
                                                            <span style={{ color: 'red' }}>*</span>
                                                        </label>

                                                        <textarea
                                                            name="manage_remarks"
                                                            required
                                                            id="remarks"
                                                            value={formData.manager_remarks || ''}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            disabled

                                                        ></textarea>
                                                    </div>



                                                </div>)}
                                            {formData.audit_remarks && (
                                                <div className="col-md-4">

                                                    <div className="form-group">
                                                        <label style={{ fontSize: '12px' }} htmlFor="remarks">
                                                            Audit Manager Remarks
                                                            <span style={{ color: 'red' }}>*</span>
                                                        </label>

                                                        <textarea
                                                            name="audit_remarks"
                                                            required
                                                            id="remarks"
                                                            value={formData.audit_remarks || ''}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            disabled

                                                        ></textarea>
                                                    </div>



                                                </div>)}
                                            {formData.account_remarks && (
                                                <div className="col-md-4">

                                                    <div className="form-group">
                                                        <label style={{ fontSize: '12px' }} htmlFor="remarks">
                                                            Finance Manager Remarks
                                                            <span style={{ color: 'red' }}>*</span>
                                                        </label>

                                                        <textarea
                                                            name="account_remarks"
                                                            required
                                                            id="remarks"
                                                            value={formData.account_remarks || ''}
                                                            onChange={handleChange}
                                                            className="form-control"
                                                            disabled

                                                        ></textarea>
                                                    </div>



                                                </div>)}
                                            {(formData.rpt_mgr_status === 'Rejected' || formData.audit_status === 'Rejected' || formData.account_status === 'Rejected') && (
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label style={{ fontSize: '12px' }} htmlFor="status_select">
                                                            Request Type

                                                            <span style={{ color: 'red' }}>*</span>
                                                            (<small style={{ color: '#888', fontStyle: 'italic' }}>
                                                                For Requesting Again to the Manager or Audit or Finance make this "Pending".
                                                            </small>)
                                                        </label>

                                                        <select
                                                            name="status_select"
                                                            id="status_select"
                                                            value={formData.status_select || ''}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            required
                                                        >
                                                            <option value="">Select Request</option>

                                                            {/* Conditionally enable only the appropriate option */}
                                                            {formData.rpt_mgr_status === 'Rejected' && (
                                                                <option value="request_for_manager">Request For Manager</option>
                                                            )}
                                                            {formData.audit_status === 'Rejected' && (
                                                                <option value="request_for_audit">Request For Audit</option>
                                                            )}
                                                            {formData.account_status === 'Rejected' && (
                                                                <option value="request_for_finance">Request For Finance</option>
                                                            )}
                                                        </select>
                                                    </div>

                                                    {/* Conditional note based on selected option */}
                                                    {formData.status_select === 'request_for_manager' && (
                                                        <small style={{ color: '#888', fontStyle: 'italic' }}>
                                                            For Requesting Again to the Manager, make this "Pending".
                                                        </small>
                                                    )}

                                                    {formData.status_select === 'request_for_audit' && (
                                                        <small style={{ color: '#888', fontStyle: 'italic' }}>
                                                            For Requesting Again to the Audit, make this "Pending".
                                                        </small>
                                                    )}

                                                    {formData.status_select === 'request_for_finance' && (
                                                        <small style={{ color: '#888', fontStyle: 'italic' }}>
                                                            For Requesting Again to Finance, make this "Pending".
                                                        </small>
                                                    )}

                                                </div>

                                            )}


                                            {/* Based on selected Request Type, show the corresponding dropdowns */}
                                            {formData.status_select && (
                                                <div className="col-md-4">
                                                    {formData.status_select === 'request_for_manager' && (
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="rpt_mgr_status">
                                                                Request For Manager
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <select
                                                                name="rpt_mgr_status"
                                                                id="rpt_mgr_status"
                                                                value={formData.rpt_mgr_status || ''}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                required
                                                            >
                                                                <option value="Pending">Pending</option>
                                                            </select>
                                                        </div>
                                                    )}

                                                    {formData.status_select === 'request_for_audit' && (
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="audit_status">
                                                                Request For Audit
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <select
                                                                name="audit_status"
                                                                id="audit_status"
                                                                value={formData.audit_status || ''}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                required
                                                            >
                                                                <option value="Pending">Pending</option>
                                                            </select>
                                                        </div>
                                                    )}

                                                    {formData.status_select === 'request_for_finance' && (
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="account_status">
                                                                Request For Finance
                                                                <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <select
                                                                name="account_status"
                                                                id="account_status"
                                                                value={formData.account_status || ''}
                                                                onChange={handleChange}
                                                                className="form-control input-font"
                                                                required
                                                            >
                                                                <option value="Pending">Pending</option>
                                                            </select>
                                                        </div>
                                                    )}
                                                </div>
                                            )}


                                        </div>
                                        <div className='row'><div className="col-md-4">
                                            {(formData.rpt_mgr_status === 'Approved' || formData.rpt_mgr_status === 'Rejected') && (
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="travelAdvance">Reporting Manager Status<span style={{ color: 'red' }}>*</span></label>
                                                    <select
                                                        name="rpt_mgr_status"
                                                        id="travelAdvance"
                                                        value={formData.rpt_mgr_status}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        disabled
                                                    >

                                                        <option value="Pending">Pending</option>
                                                        <option value="Approved">Approved</option>
                                                        <option value="Rejected">Rejected</option>
                                                    </select>
                                                </div>
                                            )}
                                        </div>
                                            <div className="col-md-4">
                                                {(formData.audit_status === 'Approved' || formData.audit_status === 'Rejected') && (
                                                    <div className="form-group">
                                                        <label style={{ fontSize: '12px' }} htmlFor="travelAdvance">Audit Manager Status<span style={{ color: 'red' }}>*</span></label>
                                                        <select
                                                            name="travel_advance"
                                                            id="travelAdvance"
                                                            value={formData.audit_status}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            disabled
                                                        >

                                                            <option value="Pending">Pending</option>
                                                            <option value="Approved">Approved</option>
                                                            <option value="Rejected">Rejected</option>
                                                        </select>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-md-4">
                                                {(formData.account_status === 'Approved' || formData.account_status === 'Rejected') && (
                                                    <div className="form-group">
                                                        <label style={{ fontSize: '12px' }} htmlFor="travelAdvance">Account Status<span style={{ color: 'red' }}>*</span></label>
                                                        <select
                                                            name="travel_advance"
                                                            id="travelAdvance"
                                                            value={formData.account_status}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            disabled
                                                        >

                                                            <option value="Pending">Pending</option>
                                                            <option value="Approved">Approved</option>
                                                            <option value="Rejected">Rejected</option>
                                                        </select>
                                                    </div>
                                                )}
                                            </div></div>

                                        <div className="form-group" style={{ marginTop: '20px', textAlign: 'center' }}>
                                            {/* {(formData.rpt_mgr_status !== 'Approved' && formData.audit_status !== 'Rejected') && ( */}
                                            {(formData.account_status === 'Rejected' || formData.audit_status === 'Rejected' || formData.rpt_mgr_status === 'Rejected' || formData.rpt_mgr_status === 'Pending' || formData.audit_status === 'Pending' || formData.account_status === 'Pending') &&(
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    disabled={isSubmitting}
                                                    style={{ marginTop: '10px' }}
                                                >
                                                    {isSubmitting ? 'Updating...' : 'Update Expenses'}
                                                </button>)}
                                            {/* )} */}
                                        </div>

                                    </form>
                                    <div className="col-sm-12 row" style={{ width: '100%' }}>
                                        <div style={{ border: '#cccccc 1px solid' }} className="col-sm-3">
                                            <b>Prepared by </b>
                                            <p> {formData.emp_code} ({formData.comp_name})<br /> Date
                                                : {formData.created_on}
                                            </p>
                                        </div>
                                        <div style={{ border: '#cccccc 1px solid' }} className="col-sm-3">
                                            {formData.rpt_mgr_status === 'Approved' && (
                                                <>
                                                    <b> Approved by</b>
                                                    {/* <p> {formData.report_mngr} ({ReportManageName})<br /> Date
                                                                    : {formData.manager_approved_date}
                                                                </p> */}
                                                    <p> {formData.report_mngr} ({ReportManagerName})<br /> Date
                                                        : {formData.manager_approved_date}
                                                    </p>
                                                </>
                                            )}
                                        </div>
                                        {formData.audit_status === 'Approved' && (
                                            <>
                                                <div style={{ border: '#cccccc 1px solid' }} className="col-sm-3">

                                                    <b> Verified by Audit Team</b>
                                                    <p>                         <span>
                                                        {formData.audit_manager} ({AuditManagerName})   <br /> Date
                                                        : {formData.audit_approved_date}
                                                    </span>
                                                    </p>

                                                </div>
                                            </>
                                        )}
                                        {formData.account_status === 'Approved' && (
                                            <>
                                                <div style={{ border: '#cccccc 1px solid' }} className="col-sm-3">

                                                    <b> Verified by Account Team</b>
                                                    <p>                         <span>
                                                        {formData.account_manager}   ({FinanceManagerName}) <br /> Date
                                                        : {formData.audit_approved_date}
                                                    </span>
                                                    </p>

                                                </div>
                                            </>
                                        )}



                                    </div>
                                </div>

                            </div>
                        </section>
                    </div>)}
                <Footer />
            </div>
        </div>
    );
};

export default Edit_Expence_Request;
