import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CustomToastStyles.css';
import useAuth from './useAuth';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
import './AddSettlement.css';
import Select from 'react-select';
//import { BASE_URL } from "./Constants.js";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


const AddSettlementForm = () => {
    const { id } = useParams();
    const [items, setItems] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [EmpCode, setEmpCode] = useState('');
    useAuth();
    const [fileInput, setFileInput] = useState(null);
    const [Amount, setAmount] = useState('');
    const [Purpose, setPurpose] = useState('');

    const [CustomPurpose, setCustomPurpose] = useState('');
    const [purposes, setPurposes] = useState([]);
    const [Grade, setGrade] = useState('');
    const [loading, setLoading] = useState(true);
    const [showCustomInput, setShowCustomInput] = useState(false);
    const [error, setError] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const navigate = useNavigate();
    const [Hotel, setHotel] = useState('');
    const [Diem, setDiem] = useState('');
    const [localConveyanceTotals, setLocalConveyanceTotals] = useState({});
    const [formData, setFormData] = useState({ days: '', travel_amount: 0, Group: 'Self', guesthouse: '', settlement_purpose: '', customPurpose: '', bank_acnum: '', bank_name: '', ifsc_code: '' });
    const [rows, setRows] = useState([{ id: Date.now(), fromPlace: '', toPlace: '', travelType: '', hotel_booked_by: '', hotel_receipt: '', ticket_receipt: '', ticket_travel_booked_by: '', hotel_amount: 0, ticket_travel_amount: 0, misc: 0, perDiem: 0, perDiem1: 0, local: 0, totalAmount: 0 }]);
    const [documentsRows, setDocumentsRows] = useState([]);
    useEffect(() => {
        const fetchCitiesData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}cities`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

                const result = await response.json();
                if (result.status) {
                    setItems(result.data);
                } else {
                    toast.error(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                toast.error('Error: ' + error.message);
            }
        };

        fetchCitiesData();
    }, []);

    const handleBackClick = () => {
        navigate("/own_settlement");
    };

    const handleChange1 = (id, event) => {
        const { name, value } = event.target;
        //    const updatedRows = travelRows.map(row => row.id === id ? { ...row, [name]: value } : row);



        if (name === 'groupSelf') {
            if (value === 'Self') {
                navigate('/add_settlement'); // Adjust the path as necessary
            } else if (value === 'Shared') {
                navigate('/add_settlement_group'); // Adjust the path as necessary
            }
        }

        // Update the state
        // setRows(updatedRows); // Update rows state
    };


    const handleChange = (id, eventOrSelectedOption, fieldName) => {
        // Check if the event is from a regular input change (not react-select)
        if (eventOrSelectedOption.target) {
            const { name, value, files } = eventOrSelectedOption.target;

            // If the input is a file input, handle the file separately
            if (files && files.length > 0) {
                const file = files[0];  // Get the first file selected

                // Update the rows state with the file object
                const updatedRows = rows.map(row =>
                    row.id === id ? { ...row, [name]: file } : row // Store the file object
                );
                setRows(updatedRows); // Update rows with the new file
                setFormData(prevData => ({ ...prevData, [name]: file })); // Store the file object in formData
            } else {
                // For other input types, handle normally
                const updatedRows = rows.map(row => row.id === id ? { ...row, [name]: value } : row);
                setRows(updatedRows); // Update rows with the new value
                setFormData(prevData => ({ ...prevData, [name]: value })); // Update formData with the new value
            }
        } else if (eventOrSelectedOption && eventOrSelectedOption.value) {
            // Handle react-select (selectedOption will be an object with {value, label})
            const selectedValue = eventOrSelectedOption.value; // Get the selected value

            // Update the rows state with the selected value for the correct field (fromCity or toCity)
            const updatedRows = rows.map(row =>
                row.id === id ? { ...row, [fieldName]: selectedValue } : row  // Use fieldName ('fromCity' or 'toCity')
            );
            setRows(updatedRows); // Update rows with the selected city value

            // Update formData with the selected value
            setFormData(prevData => ({
                ...prevData,
                [fieldName]: selectedValue  // Update formData for the correct field (fromCity or toCity)
            }));
        }


    };





    const handleGuesthouseChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
        // You can add any additional logic related to the guesthouse here.
    };

    const [transportRows, setTransportRows] = useState([{ id: 1, travelDate: '', origin: '', destination: '', travelAmount: '', transportMethod: '', receipt: '' }]);
    const handleAddTransportRow = () => {
        setTransportRows([...transportRows, { id: transportRows.length + 1, travelDate: '', origin: '', destination: '', travelAmount: '', transportMethod: '', receipt: '' }]);
        updateLocalConveyanceTotals();
    };
    const totalLocalConveyance = transportRows.reduce((acc, row) => acc + (parseFloat(row.travelAmount) || 0), 0);
    const handleTransportChange = (index, e) => {
        const { name, value, type, files } = e.target;
        const newTransportRows = [...transportRows];

        // Update the transport row with either file or other data
        if (type === 'file' && files.length > 0) {
            newTransportRows[index] = { ...newTransportRows[index], [name]: files[0] }; // Store the file object
        } else {
            newTransportRows[index] = { ...newTransportRows[index], [name]: value }; // Update other values
        }

        setTransportRows(newTransportRows);


    };

    const handleRemoveTransportRow = (index) => {
        if (transportRows.length === 1) return; // Prevent removal if it's the only row
        const updatedRows = transportRows.filter((_, i) => i !== index).map((row, i) => ({ ...row, id: i + 1 }));
        setTransportRows(updatedRows);
        updateLocalConveyanceTotals();
    };
    const updateLocalConveyanceTotals = () => {
        const totals = {};
        transportRows.forEach((row) => {
            if (row.travelDate) {
                totals[row.travelDate] = (totals[row.travelDate] || 0) + (parseFloat(row.travelAmount) || 0);
            }
        });
        setLocalConveyanceTotals(totals);
    };
    const handleCityChange = async (id, e) => {
        const selectedCity = e.target.value;
        const updatedRows = rows.map(row => row.id === id ? { ...row, toPlace: selectedCity } : row);
        setRows(updatedRows);

        if (id === rows[0].id && selectedCity) {
            try {
                const token = localStorage.getItem('authToken');
                const cityClassResponse = await fetch(`${API_BASE_URL}getCityClass?id=${selectedCity}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!cityClassResponse.ok) throw new Error('Network response was not ok');
                const cityClassData = await cityClassResponse.json();
                const cityClass = cityClassData.data.city_class || '';
                const grade = 'C'; // Modify as needed

                const modifiedGrade = Grade.charAt(0);
                const diemResponse = await fetch(`${API_BASE_URL}getDiem?grade=${modifiedGrade}&city_class=${cityClass}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!diemResponse.ok) throw new Error('Network response for second API was not ok');
                const diemData = await diemResponse.json();
                const hotelPerHotel = diemData.data.hotel || '';
                const hotelPerDiem = diemData.data.diem || '';

                setHotel(hotelPerHotel);
                setDiem(hotelPerDiem);
                setRows(rows => rows.map((row, index) =>
                    index === 0 ? { ...row, perDiem: (hotelPerHotel * (parseFloat(formData.days) || 0)).toFixed(2) } : row
                ));
                setRows(rows => rows.map((row, index) =>
                    index === 0 ? { ...row, perDiem1: (hotelPerDiem * (parseFloat(formData.days) || 0)).toFixed(2) } : row
                ));
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error('Error fetching data');
            }
        }
    };

    const addRow = () => {
        const newRow = { id: Date.now(), fromPlace: '', toPlace: '', hotel_amount: 0, ticket_travel_amount: 0, misc: 0, perDiem: 0 };
        setRows([...rows, newRow]);
    };

    const removeRow = (id) => {
        setRows(rows.filter(row => row.id !== id));
    };

    const grandTotal = () => {
        const totalHotelAmount = rows.reduce((total, row) => total + (parseFloat(row.hotel_amount) || 0), 0);
        const totalTravelAmount = rows.reduce((total, row) => total + (parseFloat(row.ticket_travel_amount) || 0), 0);
        const totalMisc = rows.reduce((total, row) => total + (parseFloat(row.misc) || 0), 0);
        const totalPerDiem = rows.reduce((total, row) => total + (parseFloat(row.perDiem) || 0), 0);
        // Calculate total per diem based on booking method
        // const totalPerDiem = rows.reduce((total, row) => {
        //     const perDiemValue = row.hotel_booked_by === "TravelDesk" ? parseFloat(row.perDiem1) : parseFloat(row.perDiem);
        //     return total + (isNaN(perDiemValue) ? 0 : perDiemValue);
        // }, 0);

        return totalHotelAmount + totalTravelAmount + totalMisc + totalPerDiem + totalLocalConveyance;
    };

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                console.log("Profile data:", result); // Log to check fetched data

                if (result.status) {
                    const { profile, travel_advance } = result;

                    // Set default values from profile
                    setEmpCode(profile.emp_code || '');
                    setGrade(profile.grade || '');
                    setFormData(prevData => ({
                        ...prevData,
                        comp_name: profile.comp_name || '',
                        emp_code: profile.emp_code || '',
                        dept_name: profile.dept_name || '',
                        designation_name: profile.designation_name || '',
                        grade: profile.grade || '',
                        report_mngr: profile.report_mngr || '',
                        mobile: profile.mobile || '', // Do not display the password
                        bank_acnum: profile.bank_acnum || '',
                        bank_name: profile.bank_name || '',
                        ifsc_code: profile.ifsc_code || '',
                        location_name: profile.location_name || '',
                        // other fields...
                    }));

                    // Set travel advance values conditionally
                    setPurpose(travel_advance.purpose || '');
                    setCustomPurpose(travel_advance.customPurpose || '');
                    setAmount(travel_advance.accounts_approved_amount || '');
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProfileData();
    }, []);

    const handleAddRow = () => {
        setRows([...rows, { id: Date.now(), date: '', time: '', tourPlace: '', fromPlace: '', toPlace: '', travelType: '', hotel_receipt: '', ticket_receipt: '', hotel_booked_by: '', hotel_amount: '', ticket_travel_booked_by: '', ticket_travel_amount: '', perDiem: '', local: '', misc: '', totalAmount: '' }]);
    };

    const handleRemoveRow = (id) => {
        setRows(rows.filter(row => row.id !== id));
    };

    const validateDays = () => {
        const { days } = formData;
        console.log("Days input:", days); // Log the input value

        const regex = /^(1|1\.5|2|2\.5|3|3\.5|4|4\.5|5|5\.5|6|6\.5|7|7\.5|8|8\.5|9|9\.5|10)$/; // Updated regex
        if (days && !regex.test(days)) {
            toast.error('Please enter a valid number (1, 1.5, 2, 2.5, etc.)');
            return false;
        } else {
            setError('');
            return true;
        }
    };

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const token = localStorage.getItem('authToken');

                if (!token) return;

                const response = await fetch(`${API_BASE_URL}purposes`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    setPurposes(result.data); // Set the fetched purposes
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                //setError('Failed to fetch items');
            } finally {
                setLoading(false);
            }
        };

        fetchItems();
    }, [navigate]);

    const handlePurposeChange = (e) => {
        const { name, value } = e.target;

        if (name === 'settlement_purpose') {
            setShowCustomInput(value === 'Others'); // Show custom input if "Others" is selected
        }
        setFormData((prevData) => ({
            ...prevData,
            [name]: value, // Update the purpose in formData

        }));
    };

    const handleAddDocument = async (e) => {
        const files = Array.from(e.target.files);


        // Add the valid files directly to documentsRows
        setDocumentsRows(prevRows => [
            ...prevRows,
            ...files.map(file => ({ file }))
        ]);
    };

    const handleRemoveDocument = (index) => {
        // Remove the document at the given index
        setDocumentsRows(prevRows => {
            const updatedRows = prevRows.filter((_, i) => i !== index); // Filter out the document by index
            return updatedRows;
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isSubmitting) {
            return; // Prevent multiple submissions
        }
        setIsSubmitting(true);

        // Simulate form submission (e.g., API call or any async operation)
        setTimeout(() => {
            // Reset isSubmitting after the submission is complete
            setIsSubmitting(false);

        }, 2000); // Simulating a 2-second delay for the submission process

        // File validation flag
        let isValid = true; // Flag to track form validity

        // Check file sizes in transportRows
        for (const [index, row] of transportRows.entries()) {
            if (row.travelAmount > 300 && !row.receipt) {
                toast.error(`Receipt is mandatory for travel amount greater than 300 for the entry from ${row.origin} to ${row.destination}.`);
                isValid = false; // Invalid if receipt is missing
                break; // Stop further checks
            }

            // Check the file size if receipt exists
            if (row.receipt) {
                const file = row.receipt;
                const MAX_SIZE = 2 * 1024 * 1024; // 2MB in bytes

                if (file.size > MAX_SIZE) {
                    toast.error(`File for Local Conveyance row ${index + 1} exceeds the 2MB limit. Please upload a smaller file.`);
                    isValid = false; // Invalid if file size exceeds 2MB
                    break; // Stop further checks
                }
            }
        }

        // Check file sizes in documentsRows
        if (isValid) { // Only proceed with documentsRows validation if transportRows are valid
            for (const [index, doc] of documentsRows.entries()) {
                if (doc.file) {
                    const file = doc.file;
                    const MAX_SIZE = 2 * 1024 * 1024; // 2MB in bytes

                    if (file.size > MAX_SIZE) {
                        toast.error(`File for Additional Document ${index + 1} exceeds the 2MB limit. Please upload a smaller file.`);
                        isValid = false; // Invalid if file size exceeds 2MB
                        break; // Stop further checks
                    }
                }
            }
        }

        // Check file sizes for hotel_receipt and ticket_receipt in rows
        if (isValid) { // Only proceed with rows validation if previous checks are valid
            for (const [index, row] of rows.entries()) {
                // Validate hotel_receipt file size
                if (row.hotel_receipt) {
                    const file = row.hotel_receipt;
                    const MAX_SIZE = 2 * 1024 * 1024; // 2MB in bytes

                    if (file.size > MAX_SIZE) {
                        toast.error(`Hotel receipt file for row ${index + 1} exceeds the 2MB limit. Please upload a smaller file.`);
                        isValid = false; // Invalid if file size exceeds 2MB
                        break; // Stop further checks
                    }
                }

                // Validate ticket_receipt file size
                if (row.ticket_receipt) {
                    const file = row.ticket_receipt;
                    const MAX_SIZE = 2 * 1024 * 1024; // 2MB in bytes

                    if (file.size > MAX_SIZE) {
                        toast.error(`Ticket receipt file for row ${index + 1} exceeds the 2MB limit. Please upload a smaller file.`);
                        isValid = false; // Invalid if file size exceeds 2MB
                        break; // Stop further checks
                    }
                }
            }
        }

        // If any validation failed, stop submission
        if (!isValid) {
            return; // Prevent form submission if validation fails
        }

        const formDataToSubmit = new FormData();
        Object.keys(formData).forEach(key => {
            if (formData[key] !== null && formData[key] !== '') {
                formDataToSubmit.append(key, formData[key]);
            }
        });

        rows.forEach((row, index) => {
            Object.keys(row).forEach(key => {
                formDataToSubmit.append(`rows[${index}][${key}]`, row[key]);
            });
        });



        transportRows.forEach((row, index) => {
            Object.keys(row).forEach(key => {
                formDataToSubmit.append(`transportRows[${index}][${key}]`, row[key]);
            });
        });

        // Append documents as documentsRow[n]additional_doc
        documentsRows.forEach((doc, index) => {
            formDataToSubmit.append(`documentsRows[${index}]`, doc.file);
        });

        // Calculate grand totals
        const grandTotalHotel = rows.reduce((total, row) => total + (parseFloat(row.hotel_amount) || 0), 0);
        const grandTotalTicket = rows.reduce((total, row) => total + (parseFloat(row.ticket_travel_amount) || 0), 0);
        // const grandTotalPerDiem = rows.reduce((total, row) => total + (parseFloat(row.perDiem) || 0), 0);
        const grandTotalPerDiem = rows.reduce((total, row) => total + (parseFloat(row.perDiem)));
        const grandTotalLocal = totalLocalConveyance;
        const grandTotalMisc = rows.reduce((total, row) => total + (parseFloat(row.misc) || 0), 0);

        // Calculate grandTotalAmount for each row
        const grandTotalAmount = rows.reduce((total, row, index) => {


            // Calculate total for the row by summing all the amounts with default values
            return total + (
                parseFloat(row.perDiem || 0) +
                parseFloat(row.hotel_amount || 0) +
                parseFloat(row.ticket_travel_amount || 0) +
                parseFloat(row.misc || 0) +
                (index === 0 ? parseFloat(totalLocalConveyance || 0) : 0) // Include totalLocalConveyance only for the first row
            );
        }, 0);

        // Debugging the result

        // Travel advance amount
        const travelAdvanceAmount = parseFloat(formData.travel_amount) || 0;

        // Adjust the total amount after subtracting travel advance
        const adjustedTotalAmount = grandTotalAmount - travelAdvanceAmount;

        // Debugging alert to check the calculated grandTotalAmount



        // Append the calculated values to formDataToSubmit
        formDataToSubmit.append('grandTotalHotel', grandTotalHotel); // Assuming grandTotalHotel is already calculated
        formDataToSubmit.append('grandTotalTicket', grandTotalTicket); // Assuming grandTotalTicket is already calculated
        formDataToSubmit.append('grandTotalPerDiem', grandTotalPerDiem); // Assuming grandTotalPerDiem is already calculated
        formDataToSubmit.append('grandTotalLocal', grandTotalLocal); // Assuming totalLocalConveyance was grandTotalLocal
        formDataToSubmit.append('grandTotalMisc', grandTotalMisc);
        //  formDataToSubmit.append('already_taken_advance', formData.already_taken_advance);
        formDataToSubmit.append('settlement_purpose', formData.settlement_purpose || '');
        formDataToSubmit.append('customPurpose', formData.customPurpose || '');
        // formDataToSubmit.append('city', rows[0].fromCity);
        formDataToSubmit.append('city', rows[0].toCity);
        formDataToSubmit.append('from_date', formData.from_date);
        formDataToSubmit.append('to_date', formData.to_date);



        { formDataToSubmit.append('purpose', Purpose) }

        { formDataToSubmit.append('travel_amount', formData.advance_amount || 0)}

        //travel_amount

        // Adjust the grandTotalAmount to include local conveyance
        formDataToSubmit.append('grandTotalAmount', grandTotalAmount);

        // Final total amount after all deductions and adjustments
        formDataToSubmit.append('TotalAmount', grandTotalAmount)



        // Debugging logs
        const formDataObject = {};
        formDataToSubmit.forEach((value, key) => {
            console.log(`${key}: ${value}`);
            if (formDataObject[key]) {
                // If the key exists, convert it to an array if it's not already
                if (!Array.isArray(formDataObject[key])) {
                    formDataObject[key] = [formDataObject[key]];
                }
                // Push the new value to the array
                formDataObject[key].push(value);
            } else {
                formDataObject[key] = value;
            }
        });
        console.log('Form Data to Submit:', JSON.stringify(formDataObject, null, 2));

        try {
            const token = localStorage.getItem('authToken');
            console.log("checktoken", token);

            const response = await fetch(`${API_BASE_URL}postSettlement`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formDataToSubmit
            });

            const result = await response.json();
            console.log("Response Data:", result);

            if (result.status) {
                toast.success('Travel Advance Form successfully added');
                setFormData({ emp_code: '' }); // Reset other fields as necessary
                navigate('/own_settlement');
            } else {
                toast.error(result.message || 'An error occurred while adding the travel advance form.');
            }
        } catch (error) {
            console.error('Fetch error:', error);
            toast.error('Failed to submit form');
        }
    };

    const handleDateChange = (event) => {
        const { name, value } = event.target; // Destructure the target and value from the event
        setFormData({
            ...formData,
            [name]: value // Update the formData state with the new value
        });
    };



    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0" style={{ fontSize: '20px' }}>Tour/Travel Settlement Form</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                        <li className="breadcrumb-item"><a href="/own_settlement">Settlement Details</a></li>
                                        <li className="breadcrumb-item active">Tour/Travel Settlement Form</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="col-lg-12">
                        <div className="card" style={{ borderTop: '3px solid #00c0ef' }}>
                            <div className="card-body">
                                <form onSubmit={handleSubmit} method="post" acceptCharset="utf-8" encType="multipart/form-data">
                                    <fieldset style={{ border: '1px #ccc solid', padding: '10px' }}>
                                        <div className="box-body">
                                            <div className="row">

                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Employee No
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="emp_code"
                                                            id="name"
                                                            value={formData.emp_code}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Employee No/Consultant"
                                                            readonly="true"

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Name </label>
                                                        <input
                                                            type="text"
                                                            name="comp_name"
                                                            id="name"
                                                            value={formData.comp_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"
                                                            placeholder="Name"
                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Employee Band</label>
                                                        <input
                                                            type="text"
                                                            name="grade"
                                                            id="name"
                                                            value={formData.grade}
                                                            onChange={handleChange}
                                                            className="form-control input-font"

                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Department</label>
                                                        <input
                                                            type="text"
                                                            name="dept_name"
                                                            id="name"
                                                            value={formData.dept_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"

                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Designation </label>
                                                        <input
                                                            type="text"
                                                            name="designation_name"
                                                            id="name"
                                                            value={formData.designation_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"

                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Mobile No
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="mobile"
                                                            id="name"
                                                            value={formData.mobile}
                                                            onChange={handleChange}
                                                            className="form-control input-font"

                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>



                                            </div>
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="bank_name">Bank Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="bank_name"
                                                            id="bank_name"
                                                            value={formData.bank_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"

                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>


                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="ifsc_code">IFSC Code
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="ifsc_code"
                                                            id="ifsc_code"
                                                            value={formData.ifsc_code}
                                                            onChange={handleChange}
                                                            className="form-control input-font"

                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-2">
                                                            <div className="form-group">
                                                                <label htmlFor="already_taken_advance">Have you taken advance? <span style={{ color: 'red' }}>*</span></label>
                                                                <select
                                                                    name="already_taken_advance"
                                                                    id="already_taken_advance"
                                                                    value={formData.already_taken_advance}
                                                                    onChange={(e) => handleChange('already_taken_advance', e)}
                                                                    className="form-control input-font"
                                                                    required
                                                                >

                                                                    <option value="">Select</option>
                                                                    <option value="Y">Yes</option>
                                                                    <option value="N">No</option>
                                                                </select>
                                                            </div>
                                                        </div> */}
                                                {/* {Amount > 0 && (
                                                            <div className="col-md-2">
                                                                <div className="form-group">
                                                                    <label htmlFor="travelAmount">Travel Advance Amount <span style={{ color: 'red' }}>*</span></label>
                                                                    <input
                                                                        type="number"
                                                                        name="travel_amount"
                                                                        id="travelAmount"
                                                                        value={formData.travel_amount || Amount} // Assuming Amount is the initial value
                                                                        onChange={(e) => handleChange('travel_amount', e)} // Handle change for travel amount
                                                                        className="form-control input-font "
                                                                        required
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>)} */}


                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Reporting Manager
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="report_mngr"
                                                            id="name"
                                                            value={formData.report_mngr}
                                                            onChange={handleChange}
                                                            className="form-control input-font"

                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Location
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="report_mngr"
                                                            id="name"
                                                            value={formData.location_name}
                                                            onChange={handleChange}
                                                            className="form-control input-font"

                                                            readonly="true"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="name">Shared or Single
                                                            <span style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <br />
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                type="radio"
                                                                name="groupSelf" // Use a common name for grouping
                                                                id="Shared" // Unique ID for each radio button
                                                                value="Shared"
                                                                checked={formData.Group === 'Shared'} // Check if Group is selected
                                                                onChange={(e) => handleChange1('groupSelf', e)}// Use handleChange to update state
                                                                className="form-check-input"
                                                            />
                                                            <label htmlFor="Shared" className="form-check-label">
                                                                Shared
                                                            </label>
                                                        </div>

                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                type="radio"
                                                                name="groupSelf" // Use the same common name for the other option
                                                                id="Self" // Unique ID for each radio button
                                                                value="Self"
                                                                checked={formData.Group === 'Self'} // Check if Self is selected
                                                                onChange={(e) => handleChange1('groupSelf', e)} // Use handleChange to update state
                                                                className="form-check-input"
                                                            />
                                                            <label htmlFor="Self" className="form-check-label">
                                                                Single
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>


                                                {/* {Amount > 0 && (
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="purpose">Purpose <span style={{ color: 'red' }}>*</span></label>
                                                            {loading ? (
                                                                <p>Loading purposes...</p> // Loading state
                                                            ) : error ? (
                                                                <p>{error}</p> // Error message
                                                            ) : (
                                                                <select
                                                                    name="purpose"
                                                                    id="purpose"
                                                                    value={formData.purpose}
                                                                    onChange={handlePurposeChange}
                                                                    className="form-control input-font"
                                                                    required
                                                                    disabled
                                                                >
                                                                    <option value={Purpose}>{Purpose} </option>
                                                                    <option value="">Select a purpose</option>
                                                                    {purposes.map(item => (
                                                                        <option key={item.id} value={item.purpose}>{item.purpose}</option>
                                                                    ))}
                                                                </select>
                                                            )}
                                                        </div>

                                                    </div>)} */}
                                                {/* Show Settlement Purpose only if travel_amount is not present */}
                                                {/* {!Amount && ( */}
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="settlement_purpose">Settlement Purpose <span style={{ color: 'red' }}>*</span></label>
                                                        {/* <input
                                                                    type="text"
                                                                    name="settlement_purpose"
                                                                    id="settlement_purpose"
                                                                    value={formData.settlement_purpose}
                                                                    onChange={(e) => handleChange('settlement_purpose', e)} // Handle change for settlement purpose
                                                                    className="form-control input-font"
                                                                    required
                                                                /> */}
                                                        <select
                                                            name="settlement_purpose"
                                                            id="purpose"
                                                            value={formData.settlement_purpose}
                                                            onChange={handlePurposeChange}
                                                            className="form-control input-font custom-select"
                                                            required

                                                        >

                                                            <option value="">Select a purpose</option>
                                                            {purposes.map(item => (
                                                                <option key={item.id} value={item.purpose}>{item.purpose}</option>
                                                            ))}
                                                            <option value="Others">Others</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                {showCustomInput && ( // Conditionally render custom input field
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label style={{ fontSize: '12px' }} htmlFor="customPurpose">
                                                                Specify Purpose <span style={{ color: 'red' }}>*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="customPurpose"
                                                                id="customPurpose"
                                                                placeholder="Specify your purpose"
                                                                value={formData.customPurpose || ''}
                                                                onChange={handlePurposeChange}
                                                                className="form-control input-font"
                                                                required={formData.settlement_purpose === 'Others'}
                                                            />
                                                        </div>
                                                    </div>
                                                )}


                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="days">No of Days
                                                            <span style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <input
                                                            type="number"
                                                            name="days"
                                                            id="days"
                                                            value={formData.days}
                                                            onChange={(e) => handleChange('days', e)}
                                                            onBlur={validateDays}
                                                            className="form-control input-font"
                                                            placeholder="1,1.5,2,2.5, etc"
                                                            required



                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="days">From Date
                                                            <span style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <input
                                                            type="date"
                                                            name="from_date"
                                                            id="days"
                                                            value={formData.from_date}
                                                            onChange={handleDateChange}

                                                            className="form-control input-font"

                                                            required



                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="days">To Date
                                                            <span style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <input
                                                            type="date"
                                                            name="to_date"
                                                            id="days"
                                                            value={formData.to_date}
                                                            onChange={handleDateChange}

                                                            className="form-control input-font"

                                                            required



                                                        />
                                                    </div>
                                                </div>

                                                 <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="travelAdvance">Has advance settled <span style={{ color: 'red' }}>*</span></label>
                                                        <select
                                                            name="travel_advance"
                                                            id="travelAdvance"
                                                            value={formData.travel_advance}
                                                            onChange={(e) => handleChange('travel_advance', e)}
                                                            className="form-control input-font custom-select"
                                                            required
                                                        >

                                                            <option value="">Select</option>
                                                            <option value="yes">Yes</option>
                                                            <option value="no">No</option>
                                                        </select>
                                                    </div>
                                                </div> 



                                                <div className='col-md-2'>
                                                    <div className="form-group">
                                                        <label style={{ fontSize: '12px' }} htmlFor="guesthouse">Select City Guest-House
                                                            <span style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <select
                                                            required
                                                            name="guesthouse"
                                                            id="guesthouse"
                                                            value={formData.guesthouse}
                                                            onChange={handleGuesthouseChange}
                                                            className="form-control input-font custom-select"
                                                        >
                                                            <option value="">Select a Guest-House</option>
                                                            <option value="Hyderabad">Hyderabad</option>
                                                            <option value="Vishakapatnam">Vishakapatnam</option>
                                                            <option value="Rajuhmundry">Rajuhmundry</option>
                                                            <option value="Gurgaon">Gurgaon</option>
                                                            <option value="no">No</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                
                                                {/* {formData.dept_name === 'Information Technology' && (
                                                <div className="col-md-2">
                                                    
                                                        <div className="form-group">
                                                            <label htmlFor="advance_amount">Advance Amount (If not taken then please enter '0')</label>
                                                            <input
                                                                type="text"
                                                                name="advance_amount"
                                                                id="advance_amount"
                                                                value={formData.advance_amount}
                                                                onChange={handleGuesthouseChange}
                                                                className="form-control input-font"
                                                                
                                                            />
                                                        </div>
                                                   
                                                </div> )} */}






                                            </div>
                                        </div>
                                        <th style={{ color: 'red', fontSize: '17px' }}>
                                            Make sure to upload the image size less than 2 mb for submission.
                                        </th>
                                        <div className="expense-table-container" style={{ textAlign: 'right' }}>
                                            <button type="button" onClick={handleAddRow} className="btn btn-primary">+</button>
                                            <table className="expense-table">
                                                <thead>
                                                    <tr className="header-row">
                                                        <th>Date<span style={{ color: 'red' }}>*</span></th>
                                                        <th>Time<span style={{ color: 'red' }}>*</span></th>
                                                        <th>Travel Type<span style={{ color: 'red' }}>*</span></th>
                                                        <th>From City<span style={{ color: 'red' }}>*</span></th>
                                                        <th>To City<span style={{ color: 'red' }}>*</span></th>
                                                        <th>Hotel Booked By<span style={{ color: 'red' }}>*</span></th>
                                                        <th>Hotel Receipt</th>

                                                        <th>Hotel Amount</th>
                                                        <th>Travel Ticket Booked By<span style={{ color: 'red' }}>*</span></th>
                                                        <th>Ticket Receipt</th>
                                                        <th>Travel Amount</th>
                                                        <th>Per Diem Allow</th>

                                                        <th>Local Conveyance</th>
                                                        <th>Misc</th>
                                                        <th>Total Rs</th>

                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {rows.map((row, index) => (
                                                        <tr key={row.id}>
                                                            <td data-label="Date">
                                                                <input
                                                                    type="date"
                                                                    name="date"
                                                                    value={row.date}
                                                                    onChange={(e) => handleChange(row.id, e)}
                                                                    className="form-control input-font"
                                                                    max={new Date().toISOString().split("T")[0]}
                                                                    required={index === 0}
                                                                />
                                                            </td>
                                                            <td data-label="Time">
                                                                <input
                                                                    type="time"
                                                                    name="time"
                                                                    value={row.time}
                                                                    onChange={(e) => handleChange(row.id, e)}
                                                                    className="form-control input-font"
                                                                    required={index === 0}
                                                                />
                                                            </td>
                                                            <td data-label="Travel Type">
                                                                <select name="travelType" value={row.travelType} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" required={index === 0} >
                                                                    <option value="">Select Travel Type</option>

                                                                    {(formData.grade && ['E', 'F'].includes(formData.grade[0])) ? (
                                                                        <>
                                                                            <option value='Air'>Air</option>
                                                                            <option value='Train'>Train</option>
                                                                            <option value='Bus'>Bus</option>
                                                                            <option value='Local_Taxi'>Local Taxi</option>
                                                                            <option value='Local_Auto'>Local Auto</option>
                                                                            <option value='Own_Bike'>Own Bike</option>
                                                                            <option value='Own_Car'>Own Car</option>
                                                                            <option value='Company_Car'>Company Car</option>
                                                                        </>

                                                                    ) : (
                                                                        <>
                                                                            <option value='Air'>Air</option>
                                                                            <option value='Train'>Train</option>
                                                                            <option value='Bus'>Bus</option>
                                                                            <option value='Local_Taxi'>Local Taxi</option>
                                                                            <option value='Local_Auto'>Local Auto</option>
                                                                            <option value='Own_Bike'>Own Bike</option>
                                                                            {/* <option value='Own_Car'>Own Car</option> */}
                                                                            <option value='Company_Car'>Company Car</option>
                                                                        </>
                                                                    )}
                                                                </select>
                                                            </td>
                                                            <td data-label="From City">
                                                                <Select
                                                                    name="fromPlace"  // Use name 'fromCity' to differentiate between from and to
                                                                    value={items.find(city => city.id === row.fromCity) ? {
                                                                        value: row.fromCity,
                                                                        label: items.find(city => city.id === row.fromCity).city_name
                                                                    } : null}  // Ensure that the selected city is correctly mapped to the value and label
                                                                    onChange={(selectedOption) => handleChange(row.id, selectedOption, 'fromCity')}  // Pass selectedOption and name 'fromCity'
                                                                    options={items.map(city => ({
                                                                        value: city.id,  // City ID as value
                                                                        label: city.city_name  // City name as label
                                                                    }))}
                                                                    className="react-select-container"
                                                                    classNamePrefix="react-select"
                                                                    placeholder="Select a city"
                                                                    required
                                                                    styles={{
                                                                        control: (base) => ({
                                                                            ...base,
                                                                            width: '100px',  // Set a fixed width
                                                                        }),
                                                                    }}
                                                                />
                                                            </td>
                                                            <td data-label="To City">
                                                                <Select
                                                                    name="toPlace"  // Use name 'toCity' to differentiate between from and to
                                                                    value={items.find(city => city.id === row.toCity) ? {
                                                                        value: row.toCity,
                                                                        label: items.find(city => city.id === row.toCity).city_name
                                                                    } : null}  // Ensure that the selected city is correctly mapped to the value and label
                                                                    onChange={(selectedOption) => handleChange(row.id, selectedOption, 'toCity')}  // Pass selectedOption and name 'toCity'
                                                                    options={items.map(city => ({
                                                                        value: city.id,  // City ID as value
                                                                        label: city.city_name  // City name as label
                                                                    }))}
                                                                    className="react-select-container"
                                                                    classNamePrefix="react-select"
                                                                    placeholder="Select a city"
                                                                    required
                                                                    styles={{
                                                                        control: (base) => ({
                                                                            ...base,
                                                                            width: '100px',  // Set a fixed width
                                                                        }),
                                                                    }}
                                                                />
                                                            </td>

                                                            {/* <td data-label="To City">
                                                                        <select
                                                                            name="toPlace"
                                                                            value={row.toPlace}
                                                                            onChange={(e) => handleChange(row.id, e)}
                                                                            className="form-control input-font custom-select"
                                                                            required
                                                                        >
                                                                            <option value="">Select a city</option>
                                                                            {items.map((city) => (
                                                                                <option key={city.id} value={city.id}>
                                                                                    {city.city_name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </td> */}
                                                            <td data-label="Booked by">
                                                                <select name="hotel_booked_by" value={row.hotel_booked_by} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" required={index === 0} >
                                                                    <option value="">Select Booked By</option>
                                                                    <option value="Self">Self</option>
                                                                    <option value="TravelDesk">Travel Desk</option>
                                                                    <option value="Not Eligible">Not Eligible</option>
                                                                </select>
                                                            </td>
                                                            <td data-label="Receipt/Bill">
                                                                <input
                                                                    type="file"
                                                                    name="hotel_receipt"
                                                                    accept=".jpg,.jpeg,.png,.pdf"
                                                                    onChange={(e) => handleChange(row.id, e)}
                                                                    className="form-control input-font"
                                                                    disabled={row.hotel_booked_by === "TravelDesk"}
                                                                />

                                                            </td>
                                                            <td data-label="Hotel Amount">
                                                                <input
                                                                    type="number"
                                                                    name="hotel_amount"
                                                                    value={row.hotel_amount}
                                                                    onChange={(e) => handleChange(row.id, e)}
                                                                    className="form-control input-font"
                                                                    disabled={row.hotel_booked_by === "TravelDesk"}
                                                                />
                                                            </td>
                                                            <td data-label="Booked by">
                                                                <select name="ticket_travel_booked_by" value={row.ticket_travel_booked_by} onChange={(e) => handleChange(row.id, e)} className="form-control input-font" required={index === 0} >
                                                                    <option value="">Select Booked By</option>
                                                                    <option value="Self">Self</option>
                                                                    <option value="TravelDesk">Travel Desk</option>
                                                                </select>
                                                            </td>
                                                            <td data-label="Receipt/Bill">
                                                                <input
                                                                    type="file"
                                                                    name="ticket_receipt"
                                                                    accept=".jpg,.jpeg,.png,.pdf"
                                                                    onChange={(e) => handleChange(row.id, e)}
                                                                    className="form-control input-font"
                                                                    disabled={row.ticket_travel_booked_by === "TravelDesk"}
                                                                />

                                                            </td>
                                                            <td data-label="Travel Amount">
                                                                <input
                                                                    type="number"
                                                                    name="ticket_travel_amount"
                                                                    value={row.ticket_travel_amount}
                                                                    onChange={(e) => handleChange(row.id, e)}
                                                                    className="form-control input-font"
                                                                    disabled={row.ticket_travel_booked_by === "TravelDesk"}
                                                                />
                                                            </td>
                                                            <td data-label="Per Diem Allow">
                                                                <input
                                                                    type="number"
                                                                    name="perDiem"
                                                                    value={row.perDiem}
                                                                    onChange={(e) => handleChange(row.id, e)}
                                                                    className="form-control input-font"
                                                                    required={index === 0}

                                                                />
                                                            </td>
                                                            <td data-label="Local Conveyance">
                                                                <input
                                                                    type="number"
                                                                    name="local"
                                                                    value={index === 0 ? totalLocalConveyance : ''} // Set only in the first row
                                                                    onChange={(e) => handleChange(row.id, e)}
                                                                    className="form-control input-font"
                                                                    disabled={index !== 0} // Disable for other rows
                                                                />
                                                            </td>


                                                            <td data-label="Misc">
                                                                <input
                                                                    type="number"
                                                                    name="misc"
                                                                    value={row.misc}
                                                                    onChange={(e) => handleChange(row.id, e)}
                                                                    className="form-control input-font"
                                                                />
                                                            </td>
                                                            <td data-label="Total Rs.">
                                                                <input
                                                                    type="number"

                                                                    value={
                                                                        (parseFloat(row.perDiem) || 0) +
                                                                        (parseFloat(row.misc) || 0) +
                                                                        (parseFloat(row.hotel_amount) || 0) +
                                                                        (parseFloat(row.ticket_travel_amount) || 0) +
                                                                        (index === 0 ? totalLocalConveyance : 0)  // Uncomment and adjust if needed
                                                                    }
                                                                    className="form-control input-font"
                                                                    readOnly
                                                                />
                                                            </td>


                                                            <td>
                                                                <button type="button" onClick={() => removeRow(row.id)} className="btn btn-danger">
                                                                    <i className="fas fa-times"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>

                                                <tfoot>
                                                    <tr>
                                                        <th colSpan="14" style={{ textAlign: 'right' }}>Grand Total</th>
                                                        <th style={{ textAlign: 'center' }}>{grandTotal()}</th>
                                                        <th></th>
                                                    </tr>

                                                    {/* {Amount ? ( // Check if Amount is available
                                                                <tr>
                                                                    <th colSpan="14" style={{ textAlign: 'right' }}>Less Advance</th>
                                                                    <th style={{ textAlign: 'center' }}>{Amount}</th>
                                                                    <th></th>
                                                                </tr>
                                                            ) : null} */}
                                                    <tr>
                                                        <th colSpan="14" style={{ textAlign: 'right' }}>
                                                            {Amount ? 'Total' : 'Total'} {/* Display Total or Grand Total based on Amount */}
                                                        </th>
                                                        <th style={{ textAlign: 'center' }}>
                                                            {Math.trunc(grandTotal().toFixed(2))}
                                                        </th>
                                                        <th></th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </fieldset>
                                    <br />
                                    <fieldset style={{ border: '1px #ccc solid', padding: '10px' }}>
                                        <center><span className='btn btn-primary' style={{ fontSize: '12px' }}>Details of Local Conveyance</span></center>


                                        <div className="transport-table-container" style={{ marginBottom: '20px', textAlign: 'right' }}>
                                            <button type="button" onClick={handleAddTransportRow} className="btn btn-primary">+</button>
                                            <table className="expense-table">
                                                <thead>
                                                    <tr>
                                                        <td colSpan="8"></td>
                                                    </tr>
                                                    <tr className="header-row">

                                                        <th style={{ textAlign: 'center' }}>Travel Date<span style={{ color: 'red' }}>*</span></th>
                                                        <th style={{ textAlign: 'center' }}>Origin<span style={{ color: 'red' }}>*</span></th>
                                                        <th style={{ textAlign: 'center' }}>Destination<span style={{ color: 'red' }}>*</span></th>
                                                        <th style={{ textAlign: 'center' }}>Amount<span style={{ color: 'red' }}>*</span></th>
                                                        <th style={{ textAlign: 'center' }}>Transport Mode<span style={{ color: 'red' }}>*</span></th>
                                                        <th style={{ textAlign: 'center' }}>Receipt/Bill</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {transportRows.map((row, index) => (
                                                        <tr key={index}>

                                                            <td data-label="Travel Date">
                                                                <input
                                                                    type="date"
                                                                    name="travelDate"
                                                                    value={row.travelDate}
                                                                    onChange={(e) => handleTransportChange(index, e)}
                                                                    className="form-control input-font"
                                                                    max={new Date().toISOString().split("T")[0]}
                                                                    required={index === 0}
                                                                />
                                                            </td>
                                                            <td data-label="Origin">
                                                                <input
                                                                    type="text"
                                                                    name="origin"
                                                                    value={row.origin}
                                                                    onChange={(e) => handleTransportChange(index, e)}
                                                                    className="form-control input-font"
                                                                    placeholder="From"
                                                                    required={index === 0}
                                                                />
                                                            </td>
                                                            <td data-label="Destination">
                                                                <input
                                                                    type="text"
                                                                    name="destination"
                                                                    value={row.destination}
                                                                    onChange={(e) => handleTransportChange(index, e)}
                                                                    className="form-control input-font"
                                                                    placeholder="To"
                                                                    required={index === 0}
                                                                />
                                                            </td>
                                                            <td data-label="Amount">
                                                                <input
                                                                    type="number"
                                                                    name="travelAmount"
                                                                    value={row.travelAmount}
                                                                    onChange={(e) => handleTransportChange(index, e)}
                                                                    className="form-control input-font"

                                                                    required={index === 0}
                                                                />
                                                            </td>
                                                            <td data-label="Transport Mode">
                                                                <select
                                                                    name="transportMethod"
                                                                    value={row.transportMethod}
                                                                    onChange={(e) => handleTransportChange(index, e)}
                                                                    className="form-control input-font custom-select"
                                                                    required={index === 0}
                                                                >
                                                                    <option value="">Select Mode</option>
                                                                    <option value="bus">Bus</option>
                                                                    <option value="auto">Auto</option>
                                                                    <option value="taxi">Taxi</option>
                                                                    <option value="Car">Own Vehicle-Car</option>
                                                                    <option value="Bike">Own Vehicle-Bike</option>
                                                                    <option value="Company_Car">Company Car</option>

                                                                </select>
                                                            </td>
                                                            <td data-label="Receipt/Bill">
                                                                <input
                                                                    type="file"
                                                                    name="receipt"
                                                                    accept=".jpg,.jpeg,.png,.pdf"
                                                                    onChange={(e) => handleTransportChange(index, e)}
                                                                    className="form-control input-font"
                                                                />
                                                                {row.travelAmount > 300 && !row.receipt && (
                                                                    <span style={{ color: 'red' }}>Receipt is mandatory for amounts greater than 300.</span>
                                                                )}
                                                            </td>
                                                            <td data-label="Actions">
                                                                <button type="button" onClick={() => handleRemoveTransportRow(index)} className="btn btn-danger">×</button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan="3" style={{ textAlign: 'right' }}>Total Amount:</td>
                                                        <td>{totalLocalConveyance}</td>
                                                        <td colSpan="3"></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </fieldset>
                                    <fieldset style={{ border: '1px #ccc solid', padding: '10px' }}>
                                        <center>
                                            <span className="btn btn-primary" style={{ fontSize: '12px' }}>
                                                Additional Documents
                                            </span>
                                        </center>
                                        <div className="document-table-container" style={{ marginBottom: '20px', textAlign: 'right' }}>
                                            <input
                                                type="file"
                                                accept=".jpg,.jpeg,.png,.pdf" // Accepting image formats and PDF files
                                                onChange={handleAddDocument}
                                                multiple
                                                className="form-control input-font"
                                                required
                                                value={fileInput}
                                            />
                                            <table className="document-table" style={{ marginTop: '10px' }}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ textAlign: 'center' }}>
                                                            You Can Add Multiple Document
                                                        </th>
                                                        <th style={{ color: 'red', fontSize: '17px' }}>
                                                            Make sure to provide accurate information and include all
                                                            relevant attachments (tickets, invoices, bills, etc.) to ensure a smooth
                                                            processing without rejection.
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {documentsRows.map((doc, index) => (
                                                        <tr key={index}>
                                                            <td data-label="Document Name">{doc.file.name}</td>
                                                            <td data-label="Preview">
                                                                {/* Check if the file is an image */}
                                                                {doc.file.type.startsWith('image') ? (
                                                                    <img
                                                                        src={URL.createObjectURL(doc.file)}
                                                                        alt={doc.file.name}
                                                                        style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                                                                    />
                                                                ) : doc.file.type === 'application/pdf' ? (
                                                                    // For PDFs, display a preview using an iframe
                                                                    <iframe
                                                                        src={URL.createObjectURL(doc.file)}
                                                                        title={doc.file.name}
                                                                        style={{ width: '50px', height: '50px', border: '1px solid #ccc' }}
                                                                    />
                                                                ) : (
                                                                    // For other file types, show the file name and a download link
                                                                    ''
                                                                )}
                                                            </td>

                                                            <td data-label="Action">
                                                                <button
                                                                    type="button"
                                                                    onClick={() => handleRemoveDocument(index)}
                                                                    className="btn btn-danger"
                                                                >
                                                                    ×
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </fieldset>



                                    <div className="box-footer">
                                        <br />
                                        <center><button type="submit" disabled={isSubmitting} className="btn btn-success pull-right"> {isSubmitting ? 'Submitting...' : 'Submit'}</button>
                                            <button
                                                className="btn btn-secondary  ml-3"
                                                onClick={handleBackClick} // Handle redirect
                                            >
                                                Cancel
                                            </button></center>

                                    </div>
                                </form>

                            </div>
                        </div>
                    </section>

                </div>
                <Footer />
            </div>
            <ToastContainer />
        </div>
    );
};

export default AddSettlementForm;
