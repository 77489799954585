import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import useAuth from './useAuth';
import './Manage_User.css';
import * as XLSX from 'xlsx';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
import './Loader.css';
import Pagination from './Pagination.js';
//import { BASE_URL } from "./Constants.js";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const Loader = () => (
//     <div style={{ textAlign: 'center', marginTop: '20px' }}>
//         <div className="spinner-border" role="status">
//             <span className="sr-only">Loading...</span>
//         </div>
//         <p>Loading...</p>
//     </div>
// );

const Loader = () => (
    <div className="loader-container1">
      <div className="spinner1"></div>
      <p>Loading...</p>
    </div>
  );

const ReportingManagerExpence = () => {
    const [EmpCode, setEmpCode] = useState('');
    const [RptMgr, setRptMgr] = useState('');
    const [items, setItems] = useState([]);
    const [auditStatusFilter, setAuditStatusFilter] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [is_audit_approval, setis_audit_approval] = useState(false);
    const [filteredItems, setFilteredItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState('date');
    const [sortOrder, setSortOrder] = useState('asc');
    const navigate = useNavigate();
    useAuth();

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                if (result.status) {
                    setEmpCode(result.profile.emp_code || '');

                    setRptMgr(result.profile.report_mngr || '');
                    setis_audit_approval(result.profile.is_audit_approval === 'Y');

                    // fetchItems(result.profile.emp_code, result.profile.report_mngr);
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchProfileData();
    }, []);

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const token = localStorage.getItem('authToken');
                if (!token) return;

                const response = await fetch(`${API_BASE_URL}manage_expences?audit_mngr=${EmpCode}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    setItems(result.expence_request);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setError('Failed to fetch items');
            } finally {
                setLoading(false);
            }
        };

        if (EmpCode) {
            fetchItems();
        }
    }, [EmpCode]);

    useEffect(() => {
            // Retrieve the saved auditStatusFilter from localStorage if available
            const savedFilter = localStorage.getItem('auditStatusFilter');
            if (savedFilter) {
                setAuditStatusFilter(savedFilter);
            }
        }, []);

    useEffect(() => {
        const filtered = items.filter(item => {
            const auditStatusMatch = !auditStatusFilter || item.audit_status === auditStatusFilter;
            return (
                item.emp_code.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.comp_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.designation_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.month.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.total_no_of_calls.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.total_amount.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.report_mngr.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.rpt_mgr_status.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.audit_status.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.account_status.toLowerCase().includes(searchQuery.toLowerCase())
            ) && auditStatusMatch;
        });

        const sorted = filtered.sort((a, b) => {
            if (a[sortColumn] < b[sortColumn]) return sortOrder === 'asc' ? -1 : 1;
            if (a[sortColumn] > b[sortColumn]) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });

        setFilteredItems(sorted);
        setCurrentPage(1); // Reset to first page when items or search query changes
    }, [searchQuery, items, sortColumn, sortOrder, auditStatusFilter]);

    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const startItem = indexOfFirstItem + 1;
    const endItem = Math.min(indexOfLastItem, filteredItems.length);

    const handleAuditStatusChange = (e) => {
        const newFilter = e.target.value;
        setAuditStatusFilter(newFilter); // Set the selected audit status filter
        localStorage.setItem('auditStatusFilter', newFilter); // Save the filter in localStorage
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const handleSort = (column) => {
        let sortedItems;
        if (column === 'sno') {
            sortedItems = [...items].sort((a, b) => {
                const indexA = items.indexOf(a);
                const indexB = items.indexOf(b);
                return sortOrder === 'asc' ? indexA - indexB : indexB - indexA;
            });
        } else {
            sortedItems = [...items].sort((a, b) => {
                if (a[column] < b[column]) return sortOrder === 'asc' ? -1 : 1;
                if (a[column] > b[column]) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            });
        }

        setItems(sortedItems);
        setSortColumn(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const exportToExcel = () => {
            const filteredBranchNames = filteredItems.map(item => ({
                EmpCode: item.emp_code,
                Name: item.comp_name,
                Designation: item.designation_name,
                Month: item.month,
                Calls: item.total_no_of_calls,
                Amount: Math.trunc(item.total_amount),
                AuditApprovedAmount: Math.trunc(item.audit_approved_amount),
                // Amount: item.amount,
                Remarks: item.audit_remarks,
                ManagerStatus: item.rpt_mgr_status,
                AuditStatus: item.audit_status,
                // AccountStatus: item.account_status,
            }));
    
            const worksheet = XLSX.utils.json_to_sheet(filteredBranchNames);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'MonthlyExpenses');
            XLSX.writeFile(workbook, 'Monthly_Expenses.xlsx');
        };

    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h4 className="m-0" style={{ fontSize: '20px' }}>Audit Monthly Expense</h4>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item" style={{ fontSize: '12px' }}><a href="/dashboard">Home</a></li>
                                        <li className="breadcrumb-item active" style={{ fontSize: '12px' }}>Manage Monthly Expense</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <Loader />
                    ) : (

                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <section className="col-lg-12 connectedSortable">
                                        <div className="card">
                                        <div className="card-header">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label>Audit Status Filter</label>
                                                                <select
                                                                    className="form-control custom-select-sm"
                                                                    value={auditStatusFilter}
                                                                    onChange={handleAuditStatusChange}
                                                                >
                                                                    <option value="">All</option>
                                                                    <option value="Pending">Pending</option>
                                                                    <option value="Approved">Approved</option>
                                                                    <option value="Rejected">Rejected</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-8 text-right">
                                                            <button type="button" className="btn btn-secondary" onClick={exportToExcel}>
                                                                Export to Excel
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            <div className="card-body">
                                                {loading ? (
                                                    <Loader /> // Show loader if loading is true
                                                ) : (
                                                    <>
                                                        <div id="example1_filter" style={{ float: 'right' }} className="dataTables_filter">
                                                            <label>
                                                                <input
                                                                    type="search"
                                                                    value={searchQuery}
                                                                    onChange={handleSearchChange}
                                                                    className="form-control form-control-sm"
                                                                    placeholder="Search"
                                                                    aria-controls="example1"
                                                                />
                                                            </label>
                                                        </div>

                                                        <div className="form-group">
                                                            <select
                                                                className="form-control custom-select-sm"
                                                                value={itemsPerPage}
                                                                onChange={handleItemsPerPageChange}
                                                            >
                                                                <option value={10}>10</option>
                                                                <option value={50}>50</option>
                                                                <option value={100}>100</option>
                                                            </select>
                                                        </div>
                                                        
                                                        <div className="table-responsive">
                                                            <table className="table table-bordered table-striped" style={{ fontSize: '13px' }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th
                                                                            onClick={() => handleSort("sno")}
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                backgroundColor: sortColumn === "sno" ? '#f8f9fa' : 'transparent',
                                                                                position: 'relative'
                                                                            }}
                                                                        >
                                                                            S.No {sortColumn === "sno" && (sortOrder === "asc" ? '▲' : '▼')}
                                                                            {/* Adding arrows for sorting indication */}
                                                                            <span
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    right: '10px',
                                                                                    color: '#007bff',
                                                                                    fontSize: '12px',
                                                                                    textDecoration: 'underline',
                                                                                }}
                                                                            >

                                                                            </span>
                                                                        </th>

                                                                        <th>
                                                                            Emp Code
                                                                        </th>

                                                                        <th>
                                                                            Trans Code
                                                                        </th>

                                                                        <th>
                                                                            Emp Name
                                                                        </th>

                                                                        <th>
                                                                            Designation
                                                                        </th>
                                                                        <th>
                                                                            Month
                                                                        </th>
                                                                        <th>
                                                                            Total Calls
                                                                        </th>
                                                                        <th>
                                                                            Total Amount
                                                                        </th>
                                                                        <th>
                                                                            Audit Approval Amount
                                                                        </th>
                                                                        <th>
                                                                            Reporting Manager
                                                                        </th>

                                                                        <th>
                                                                            Reporting Manager Status
                                                                        </th>
                                                                        <th>
                                                                            Audit Status
                                                                        </th>

                                                                        <th>
                                                                            Finance Status
                                                                        </th>

                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {is_audit_approval && currentItems.length > 0 ? (
                                                                        currentItems.some(item => item.rpt_mgr_status !== 'Rejected') ? (
                                                                            currentItems.map((item, index) => (

                                                                                <tr key={index}>
                                                                                    <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                                                                                    <td>{item.emp_code}</td>
                                                                                    <td>{item.trans_code}</td>
                                                                                    <td>{item.comp_name}</td>
                                                                                    <td>{item.designation_name}</td>

                                                                                    <td>{item.month}</td>
                                                                                    <td>{item.total_no_of_calls}</td>
                                                                                    <td>{Math.trunc(item.total_amount)}</td>
                                                                                    <td>{Math.trunc(item.audit_approved_amount)}</td>
                                                                                    <td>{item.report_mngr}</td>


                                                                                    <td style={{
                                                                                        color: item.rpt_mgr_status === 'Pending' ? 'orange' :
                                                                                            item.rpt_mgr_status === 'Rejected' ? 'red' :
                                                                                                item.rpt_mgr_status === 'Approved' ? 'green' :
                                                                                                    'inherit'
                                                                                    }}>
                                                                                        {item.rpt_mgr_status}
                                                                                    </td>

                                                                                    <td style={{
                                                                                        color: item.audit_status === 'Pending' ? 'orange' :
                                                                                            item.audit_status === 'Rejected' ? 'red' :
                                                                                                item.audit_status === 'Approved' ? 'green' :
                                                                                                    'inherit'
                                                                                    }}>
                                                                                        {item.audit_status}
                                                                                    </td>

                                                                                    <td style={{ color: item.account_status === 'Pending' ? 'orange' : item.account_status === 'Approved' ? 'green' : item.account_status === 'Rejected' ? 'red' : 'inherit' }}>
                                                                                        {item.account_status}
                                                                                    </td>

                                                                                    <td>
                                                                                    {item.rpt_mgr_status === 'Approved' && (
                                                                                        <Link to={`/edit_audit_expence_request/${item.id}`}>

                                                                                            <i className="fa fa-edit" style={{ fontSize: '12px', color: 'green' }}></i> {/* Small edit icon */}

                                                                                        </Link>
                                                                                    )}
                                                                                    </td>
                                                                                </tr>

                                                                            ))
                                                                        ) : (
                                                                            <tr>
                                                                                <td colSpan="15" style={{ textAlign: 'center' }}>No data available</td>
                                                                            </tr>
                                                                        )
                                                                    ) : (
                                                                        <tr>
                                                                            <td colSpan="15" style={{ textAlign: 'center' }}>No data available</td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <Pagination
                                                currentPage={currentPage}
                                                totalPages={totalPages}
                                                handlePageChange={handlePageChange}
                                            />
                                        <div className="mb-3">
                                            Showing {startItem} to {endItem} of {filteredItems.length} entries
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </section>
                    )}
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default ReportingManagerExpence;
