import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import samplePDF from './travel_policy2.pdf';
import samplePDF1 from './Travel_guildlines.pdf';
import samplePDF2 from './user_manual.pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import handicon from './handicon.png';
import notifyicon from './notification.png';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import './Header.css'; // Import your CSS file for styles
import logo from './vdc_logo.png';

//import { BASE_URL } from "./Constants.js";
import MD5 from "crypto-js/md5";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const Header = () => {
    const [formData, setFormData] = useState({ emp_code: '' });
    const [notificationCount, setNotificationCount] = useState(0);

    const [ExpenceManagerNotificationCount, setExpenceManagerNotificationCount] = useState(0);

    const [ExpenceAuditNotificationCount, setExpenceAuditNotificationCount] = useState(0);
    const [ExpenceAccountNotificationCount, setExpenceAccountNotificationCount] = useState(0);

    const [NotificationReportingMoanagerCount, setNotificationReportingMoanagerCount] = useState(0);
    const [NotificationHotelReportingManagerCount, setNotificationHotelReportingManagerCount] = useState(0);
    const [NotificationHotelManagerCount, setNotificationHotelManagerCount] = useState(0);
    const [is_disabled, setis_disabled] = useState(false);
    const [notificationTravelCount, setNotificationTravelCount] = useState(0);
    const [notificationFinanceCount, setNotificationFinanceCount] = useState(0);
    const [notificationFinanceCount1, setNotificationFinanceCount1] = useState(0);
    const [snotificationCount, setsNotificationCount] = useState(0);
    const [NotificationTravelManagerCount, setNotificationTravelManagerCount] = useState(0);
    const [snotificationAuditCount, setsNotificationAuditCount] = useState(0);
    const [snotificationAuditCount1, setsNotificationAuditCount1] = useState(0);
    const [snotificationFinanceCount, setsNotificationFinanceCount] = useState(0);
    const [snotificationFinanceCount1, setsNotificationFinanceCount1] = useState(0);
    const [NotificationConveyanceReportingMoanagerCount, setNotificationConveyanceReportingMoanagerCount] = useState(0);
    const [NotificationAuditManagerCount, setNotificationAuditManagerCount] = useState(0);
    const [NotificationAccountManagerCount, setNotificationAccountManagerCount] = useState(0);
    
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_BASE_URL}logout`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const result = await response.json();
            if (result.status) {
                localStorage.removeItem('authToken');
                localStorage.removeItem('exp');
                navigate('/');
            }
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    setFormData({
                        emp_code: result.profile.emp_code || '',
                        comp_name: result.profile.comp_name || '',

                        mail_id: result.profile.mail_id || '',
                        password: ''

                    });
                    const hashedPassword = MD5('adnet2008').toString();
                    const getPassword = result.profile.password;
                    if (hashedPassword === getPassword) {

                        setis_disabled('Y');
                    }
                }
            } catch (error) {
                console.error('Fetch error:', error);
            }
        };

        const checkTokenExpiration = () => {
            const exp = localStorage.getItem('exp');
            if (exp && Date.now() > exp) {
                handleLogout();
            } else {
                const timeoutDuration = exp - Date.now();
                if (timeoutDuration > 0) {
                    setTimeout(() => {
                        handleLogout();
                    }, timeoutDuration);
                }
            }
        };

        fetchProfileData();
        checkTokenExpiration();
    }, [navigate]);

    const fetchTravelNotificationCount = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_BASE_URL}travel_notifications`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            const result = await response.json();
            if (result.status) {
                setNotificationReportingMoanagerCount(result.report_mngr_count);
                setNotificationTravelManagerCount(result.travelManagerCount);


            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    };

    useEffect(() => {
        fetchTravelNotificationCount();
    }, []);
    const fetchConveyanceNotificationCount = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_BASE_URL}notifications_conveyance`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            const result = await response.json();
            if (result.status) {
                setNotificationConveyanceReportingMoanagerCount(result.report_mngr_count);
                setNotificationAuditManagerCount(result.audit_manager_count);
                setNotificationAccountManagerCount(result.accounts_manager_count);


            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    };
    

    

    useEffect(() => {
        fetchConveyanceNotificationCount();
    }, []);

    const fetchHotelNotificationCount = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_BASE_URL}hotel_notifications`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            const result = await response.json();
            if (result.status) {
                setNotificationHotelReportingManagerCount(result.report_mngr_count);
                setNotificationHotelManagerCount(result.hotelManagerCount);


            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    };

    useEffect(() => {
        fetchHotelNotificationCount();
    }, []);



    const fetchNotificationCount = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_BASE_URL}notifications`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            const result = await response.json();
            if (result.status) {
                setNotificationCount(result.report_mngr_count);
                setNotificationFinanceCount(result.accounts_manager_count);
                setNotificationFinanceCount1(result.accounts_manager_count1);

            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    };

    useEffect(() => {
        fetchNotificationCount();
    }, []);

    const fetchsNotificationCount = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_BASE_URL}snotifications`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            const result = await response.json();
            if (result.status) {
                setsNotificationCount(result.reportMngrCount);
                setsNotificationAuditCount(result.auditManagerCount);
                setsNotificationAuditCount1(result.auditManagerCount1);
                setsNotificationFinanceCount(result.accountsManagerCount);
                setsNotificationFinanceCount1(result.accountsManagerCount1);
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    };

    useEffect(() => {
        fetchsNotificationCount();
    }, []);


    const fetchExpenceNotificationCount = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_BASE_URL}expence_notifications`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            const result = await response.json();
            if (result.status) {
                setExpenceManagerNotificationCount(result.report_mngr_count);
                setExpenceAuditNotificationCount(result.accounts_manager_count);
                setExpenceAccountNotificationCount(result.accounts_manager_count1);
                // setsNotificationAuditCount1(result.auditManagerCount1);
                // setsNotificationFinanceCount(result.accountsManagerCount);
                // setsNotificationFinanceCount1(result.accountsManagerCount1);
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    };

    useEffect(() => {
        fetchExpenceNotificationCount();
    }, []);

    const handleBellClick = () => {
        fetchNotificationCount();
    };

    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light" style={{ backgroundColor: 'rgb(64, 68, 72)' }}>
            <ul className="navbar-nav">
                <li className="hand-nav-item">
                    <Link to="#" className="nav-link" data-widget="pushmenu" role="button">
                        <i className="fas fa-bars" style={{ color: 'white' }}></i>
                    </Link>
                </li>
            </ul>

            <ul className="navbar-nav ml-auto">
                <li className="hand-nav-item" style={{ marginRight: '15px' }}>

                </li>
                {!is_disabled && (<>
                    {NotificationConveyanceReportingMoanagerCount > 0 && (<li className="hand-nav-item" style={{ marginRight: '15px' }}>
                        <a href="/reporting_manager_conveyance" style={{ display: 'flex', alignItems: 'center' }}>
                            <FontAwesomeIcon
                                icon={faBell}
                                style={{ color: 'green', fontSize: '20px', cursor: 'pointer' }}
                                onClick={handleBellClick} title="Reporting Manager Conveyance Approval"
                            />
                        </a>

                        <span className="badge badge-danger" style={{
                            position: 'absolute',
                            top: '-5px',
                            right: '-10px',
                            fontSize: '12px',
                            borderRadius: '50%',
                            padding: '2px 5px',
                            background: 'red',
                            color: 'white'
                        }}>
                            {NotificationConveyanceReportingMoanagerCount}
                        </span>

                    </li>)}
                    {NotificationAuditManagerCount > 0 && (<li className="hand-nav-item" style={{ marginRight: '15px' }}>
                        <a href="/audit_conveyance" style={{ display: 'flex', alignItems: 'center' }}>
                            <FontAwesomeIcon
                                icon={faBell}
                                style={{ color: 'green', fontSize: '20px', cursor: 'pointer' }}
                                onClick={handleBellClick} title="Audit Conveyance Approval"
                            />
                        </a>

                        <span className="badge badge-danger" style={{
                            position: 'absolute',
                            top: '-5px',
                            right: '-10px',
                            fontSize: '12px',
                            borderRadius: '50%',
                            padding: '2px 5px',
                            background: 'red',
                            color: 'white'
                        }}>
                            {NotificationAuditManagerCount}
                        </span>

                    </li>)}
                    {NotificationAccountManagerCount > 0 && (<li className="hand-nav-item" style={{ marginRight: '15px' }}>
                        <a href="/finance_conveyance" style={{ display: 'flex', alignItems: 'center' }}>
                            <FontAwesomeIcon
                                icon={faBell}
                                style={{ color: 'green', fontSize: '20px', cursor: 'pointer' }}
                                onClick={handleBellClick} title="Finance Conveyance Approval"
                            />
                        </a>

                        <span className="badge badge-danger" style={{
                            position: 'absolute',
                            top: '-5px',
                            right: '-10px',
                            fontSize: '12px',
                            borderRadius: '50%',
                            padding: '2px 5px',
                            background: 'red',
                            color: 'white'
                        }}>
                            {NotificationAccountManagerCount}
                        </span>

                    </li>)}
                    {ExpenceAccountNotificationCount > 0 && (
                        <li className="hand-nav-item" style={{ marginRight: '15px' }}>
                            <a href="/finance_expence_approval" style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={handicon} alt="Hand Icon" className="hand-icon animated-hand" style={{ width: '30px', marginRight: '5px' }} />

                                <img src={notifyicon} alt="Hand Icon" style={{ width: '22px', marginRight: '5px' }} title="Finance Expence Request" />
                            </a>

                            <span className="badge badge-danger" style={{
                                position: 'absolute',
                                top: '-5px',
                                right: '-10px',
                                fontSize: '12px',
                                borderRadius: '50%',
                                padding: '2px 5px',
                                background: 'red',
                                color: 'white'
                            }}>
                                {ExpenceAccountNotificationCount}
                            </span>

                        </li>)}
                    {/* {!is_disabled &&(<>  */}
                    {ExpenceAuditNotificationCount > 0 && (
                        <li className="hand-nav-item" style={{ marginRight: '15px' }}>
                            <a href="/audit_expence_approval" style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={handicon} alt="Hand Icon" className="hand-icon animated-hand" style={{ width: '30px', marginRight: '5px' }} />

                                <img src={notifyicon} alt="Hand Icon" style={{ width: '22px', marginRight: '5px' }} title="Audit Expence Request" />
                            </a>

                            <span className="badge badge-danger" style={{
                                position: 'absolute',
                                top: '-5px',
                                right: '-10px',
                                fontSize: '12px',
                                borderRadius: '50%',
                                padding: '2px 5px',
                                background: 'red',
                                color: 'white'
                            }}>
                                {ExpenceAuditNotificationCount}
                            </span>

                        </li>)}

                    {ExpenceManagerNotificationCount > 0 && (
                        <li className="hand-nav-item" style={{ marginRight: '15px' }}>
                            <a href="/reporting_manager_expences" style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={handicon} alt="Hand Icon" className="hand-icon animated-hand" style={{ width: '30px', marginRight: '5px' }} />

                                <img src={notifyicon} alt="Hand Icon" style={{ width: '22px', marginRight: '5px' }} title="Manager Expence Request" />
                            </a>

                            <span className="badge badge-danger" style={{
                                position: 'absolute',
                                top: '-5px',
                                right: '-10px',
                                fontSize: '12px',
                                borderRadius: '50%',
                                padding: '2px 5px',
                                background: 'red',
                                color: 'white'
                            }}>
                                {ExpenceManagerNotificationCount}
                            </span>

                        </li>)}
                    {/* {!is_disabled &&(<>  */}
                    {notificationCount > 0 && (
                        <li className="hand-nav-item" style={{ marginRight: '15px' }}>
                            <a href="/manage_advance" style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={handicon} alt="Hand Icon" className="hand-icon animated-hand" style={{ width: '30px', marginRight: '5px' }} />

                                <img src={notifyicon} alt="Hand Icon" style={{ width: '22px', marginRight: '5px' }} title="Advance Request" />
                            </a>

                            <span className="badge badge-danger" style={{
                                position: 'absolute',
                                top: '-5px',
                                right: '-10px',
                                fontSize: '12px',
                                borderRadius: '50%',
                                padding: '2px 5px',
                                background: 'red',
                                color: 'white'
                            }}>
                                {notificationCount}
                            </span>

                        </li>)}
                    {NotificationReportingMoanagerCount > 0 && (
                        <li className="hand-nav-item" style={{ marginRight: '15px' }}>
                            <a href="/reporting_manager_travel_approval" style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={handicon} alt="Hand Icon" className="hand-icon animated-hand" style={{ width: '30px', marginRight: '5px' }} />

                                <img src={notifyicon} alt="Hand Icon" style={{ width: '22px', marginRight: '5px' }} title="Travel Request" />
                            </a>

                            <span className="badge badge-danger" style={{
                                position: 'absolute',
                                top: '-5px',
                                right: '-10px',
                                fontSize: '12px',
                                borderRadius: '50%',
                                padding: '2px 5px',
                                background: 'red',
                                color: 'white'
                            }}>
                                {NotificationReportingMoanagerCount}
                            </span>

                        </li>)}
                    {NotificationHotelReportingManagerCount > 0 && (
                        <li className="hand-nav-item" style={{ marginRight: '15px' }}>
                            <a href="/reporting_manager_hotel_approval" style={{ display: 'flex', alignItems: 'center' }}>


                                <FontAwesomeIcon
                                    icon={faBell}
                                    style={{ color: 'green', fontSize: '20px', cursor: 'pointer' }}
                                    onClick={handleBellClick} title="Hotel Manager Approval"
                                />
                            </a>

                            <span className="badge badge-danger" style={{
                                position: 'absolute',
                                top: '-5px',
                                right: '-10px',
                                fontSize: '12px',
                                borderRadius: '50%',
                                padding: '2px 5px',
                                background: 'red',
                                color: 'white'
                            }}>
                                {NotificationHotelReportingManagerCount}
                            </span>

                        </li>)}

                    {NotificationHotelManagerCount > 0 && (
                        <li className="hand-nav-item" style={{ marginRight: '15px' }}>
                            <a href="/hotel_approval" style={{ display: 'flex', alignItems: 'center' }}>


                                <FontAwesomeIcon
                                    icon={faBell}
                                    style={{ color: 'green', fontSize: '20px', cursor: 'pointer' }}
                                    onClick={handleBellClick} title="Hotel Approval"
                                />
                            </a>

                            <span className="badge badge-danger" style={{
                                position: 'absolute',
                                top: '-5px',
                                right: '-10px',
                                fontSize: '12px',
                                borderRadius: '50%',
                                padding: '2px 5px',
                                background: 'red',
                                color: 'white'
                            }}>
                                {NotificationHotelManagerCount}
                            </span>

                        </li>)}
                    {NotificationTravelManagerCount > 0 && (
                        <li className="hand-nav-item" style={{ marginRight: '15px' }}>
                            <a href="/travel_approval" style={{ display: 'flex', alignItems: 'center' }}>


                                <FontAwesomeIcon
                                    icon={faBell}
                                    style={{ color: 'green', fontSize: '20px', cursor: 'pointer' }}
                                    onClick={handleBellClick} title="Travel Approval"
                                />
                            </a>

                            <span className="badge badge-danger" style={{
                                position: 'absolute',
                                top: '-5px',
                                right: '-10px',
                                fontSize: '12px',
                                borderRadius: '50%',
                                padding: '2px 5px',
                                background: 'red',
                                color: 'white'
                            }}>
                                {NotificationTravelManagerCount}
                            </span>

                        </li>)}
                    {notificationFinanceCount > 0 && (
                        <li className="hand-nav-item" style={{ marginRight: '15px' }}>
                            <a href="/finance_form" style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={handicon} alt="Hand Icon" className="hand-icon animated-hand" style={{ width: '30px', marginRight: '5px' }} />

                                <img src={notifyicon} alt="Hand Icon" style={{ width: '22px', marginRight: '5px' }} title="Advance" />
                            </a>

                            <span className="badge badge-danger" style={{
                                position: 'absolute',
                                top: '-5px',
                                right: '-10px',
                                fontSize: '12px',
                                borderRadius: '50%',
                                padding: '2px 5px',
                                background: 'red',
                                color: 'white'
                            }}>
                                {notificationFinanceCount}
                            </span>

                        </li>)}

                    {notificationFinanceCount1 > 0 && (
                        <li className="hand-nav-item" style={{ marginRight: '15px' }}>
                            <a href="/finance_form" style={{ display: 'flex', alignItems: 'center' }}>


                                <FontAwesomeIcon
                                    icon={faBell}
                                    style={{ color: 'green', fontSize: '20px', cursor: 'pointer' }}
                                    onClick={handleBellClick} title="Advance Finance Form"
                                />
                            </a>

                            <span className="badge badge-danger" style={{
                                position: 'absolute',
                                top: '-5px',
                                right: '-10px',
                                fontSize: '12px',
                                borderRadius: '50%',
                                padding: '2px 5px',
                                background: 'red',
                                color: 'white'
                            }}>
                                {notificationFinanceCount1}
                            </span>

                        </li>)}
                    {snotificationCount > 0 && (<li className="hand-nav-item" style={{ marginRight: '15px' }}>
                        <a href="/manage_settlement" style={{ display: 'flex', alignItems: 'center' }}>
                            <FontAwesomeIcon
                                icon={faBell}
                                style={{ color: 'violet', fontSize: '20px', cursor: 'pointer' }}
                                onClick={handleBellClick} title="Settlement"
                            />
                        </a>

                        <span className="badge badge-danger" style={{
                            position: 'absolute',
                            top: '-5px',
                            right: '-10px',
                            fontSize: '12px',
                            borderRadius: '50%',
                            padding: '2px 5px',
                            background: 'red',
                            color: 'white'
                        }}>
                            {snotificationCount}
                        </span>

                    </li>)}
                    {snotificationAuditCount > 0 && (<li className="hand-nav-item" style={{ marginRight: '15px' }}>
                        <a href="/audit_form" style={{ display: 'flex', alignItems: 'center' }}>
                            <FontAwesomeIcon
                                icon={faBell}
                                style={{ color: 'violet', fontSize: '20px', cursor: 'pointer' }}
                                onClick={handleBellClick} title="Settlement"
                            />
                        </a>

                        <span className="badge badge-danger" style={{
                            position: 'absolute',
                            top: '-5px',
                            right: '-10px',
                            fontSize: '12px',
                            borderRadius: '50%',
                            padding: '2px 5px',
                            background: 'red',
                            color: 'white'
                        }}>
                            {snotificationAuditCount}
                        </span>

                    </li>)}
                    {snotificationAuditCount1 > 0 && (<li className="hand-nav-item" style={{ marginRight: '15px' }}>
                        <a href="/audit_form" style={{ display: 'flex', alignItems: 'center' }}>
                            <FontAwesomeIcon
                                icon={faBell}
                                style={{ color: 'white', fontSize: '20px', cursor: 'pointer' }}
                                onClick={handleBellClick} title="Settlement"
                            />
                        </a>

                        <span className="badge badge-danger" style={{
                            position: 'absolute',
                            top: '-5px',
                            right: '-10px',
                            fontSize: '12px',
                            borderRadius: '50%',
                            padding: '2px 5px',
                            background: 'red',
                            color: 'white'
                        }}>
                            {snotificationAuditCount1}
                        </span>

                    </li>)}
                    {snotificationFinanceCount > 0 && (<li className="hand-nav-item" style={{ marginRight: '15px' }}>
                        <a href="/finance_settlement_form" style={{ display: 'flex', alignItems: 'center' }}>
                            <FontAwesomeIcon
                                icon={faBell}
                                style={{ color: 'violet', fontSize: '20px', cursor: 'pointer' }}
                                onClick={handleBellClick} title="Settlement"
                            />
                        </a>

                        <span className="badge badge-danger" style={{
                            position: 'absolute',
                            top: '-5px',
                            right: '-10px',
                            fontSize: '12px',
                            borderRadius: '50%',
                            padding: '2px 5px',
                            background: 'red',
                            color: 'white'
                        }}>
                            {snotificationFinanceCount}
                        </span>

                    </li>)}

                    {snotificationFinanceCount1 > 0 && (<li className="hand-nav-item" style={{ marginRight: '15px' }}>
                        <a href="/finance_settlement_form" style={{ display: 'flex', alignItems: 'center' }}>
                            <FontAwesomeIcon
                                icon={faBell}
                                style={{ color: 'green', fontSize: '20px', cursor: 'pointer' }}
                                onClick={handleBellClick} title="Settlement Finance Form"
                            />
                        </a>

                        <span className="badge badge-danger" style={{
                            position: 'absolute',
                            top: '-5px',
                            right: '-10px',
                            fontSize: '12px',
                            borderRadius: '50%',
                            padding: '2px 5px',
                            background: 'red',
                            color: 'white'
                        }}>
                            {snotificationFinanceCount1}
                        </span>

                    </li>)}
                </>)};
                <li className="hand-nav-item">
                    <a
                        href={samplePDF2}
                        className="btn btn-link"
                        style={{ color: 'white', marginRight: '10px', display: 'flex', alignItems: 'center', textDecoration: 'none' }}
                        target="new" // Opens PDF in a new tab
                        rel="noopener noreferrer" // Security best practice when using target="_blank"
                    >
                        <i className="fa fa-paperclip" aria-hidden="true"></i> User Manual
                    </a>
                    <a
                        href={samplePDF1}
                        className="btn btn-link"
                        style={{ color: 'white', marginRight: '10px', display: 'flex', alignItems: 'center', textDecoration: 'none' }}
                        target="new" // Opens PDF in a new tab
                        rel="noopener noreferrer" // Security best practice when using target="_blank"
                    >
                        <i className="fa fa-paperclip" aria-hidden="true"></i> Travel Guidelines
                    </a>
                    <a
                        href={samplePDF}
                        className="btn btn-link"
                        style={{ color: 'white', marginRight: '10px', display: 'flex', alignItems: 'center', textDecoration: 'none' }}
                        target="new" // Opens PDF in a new tab
                        rel="noopener noreferrer" // Security best practice when using target="_blank"
                    >
                        <i className="fa fa-paperclip" aria-hidden="true"></i> Travel Policy
                    </a>

                </li>

                <li className="hand-nav-item dropdown user user-menu" style={{ marginTop: '7px' }}>
                    <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                        <img
                            src="https://cdn-icons-png.flaticon.com/512/9385/9385289.png"
                            className="user-image"
                            alt="User Image"
                        />
                        <span className="hidden-xs" style={{ color: 'white', textDecoration: 'none' }}>{formData.emp_code || ''}</span>
                    </a>
                    <ul className="dropdown-menu" style={{ margin: '0px -180px' }}>
                        <li className="user-header">
                            <img
                                src="https://cdn-icons-png.flaticon.com/512/9385/9385289.png"
                                className="img-circle"
                                alt="User Image"
                            />
                            <p>{formData.comp_name || ''}</p>
                        </li>
                        <li className="user-footer" style={{ backgroundColor: '#a8324c', textAlign: 'center' }}>
                            <div className="pull-right" style={{ display: 'inline-block' }}>
                                <button className="btn btn-default btn-flat" onClick={handleLogout}>
                                    <span style={{ color: 'black' }}>Sign out</span>
                                </button>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>

        </nav>
    );
};

export default Header;
