import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CustomToastStyles.css';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
import logo from './vdc_logo.png';
import './Loader.css';
import './Travel_Desk.css';
import jsPDF from 'jspdf';
import useAuth from './useAuth.js';
//import { BASE_URL } from "./Constants.js";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const Loader = () => (
    <div className="loader-container1">
      <div className="spinner1"></div>
      <p>Loading...</p>
    </div>
  );

const Edit_Finance_Manager_Request = () => {
    const { id } = useParams();  // Get the expense id from the URL parameter
    const [AdvanceStatus, setAdvanceStatus] = useState([]);
    const [hasSubmittedStaticData, setHasSubmittedStaticData] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [responseMessage, setResponseMessage] = useState('');
    // Default Emp Code
    const [monthlyExpenses, setMonthlyExpenses] = useState([]); // Store monthly expenses data
    const [formData, setFormData] = useState({
        comp_name: '',
        areas_visited: '',
        from_city: '',
        to_city: '',
        travel_date: '',
        return_date: '',
        receipt: null,
        amount: '',
        email: '',
        branch: '',
        rpt_mgr_status: '',
        utr: '',
        utr_date: '',
    });
    useAuth();
    const [errors, setErrors] = useState({
        account_status: '', // Initial errors can go here (empty or predefined)
    });
    const [dates, setDates] = useState([]);
    const [EmpCode, setEmpCode] = useState();
    const [relatedDetails, setRelatedDetails] = useState([]);
    const [ReportManagerName, setReportManagerName] = useState('');
    const [AuditManagerName, setAuditManagerName] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        // Fetch expense data based on id
        const fetchExpence = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}manage_expences?id=${id}`, {  // Fetch expense data for the specific id
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                if (result.status) {
                    const Expence_Request = result.expence_request;
                    setReportManagerName(result.reportManagerName.comp_name || '');
                    setAuditManagerName(result.auditManagerName.comp_name || '');
                    setEmpCode(result.expence_request.emp_code || '');
                    setFormData({
                        comp_name: Expence_Request.comp_name,
                        emp_code: Expence_Request.emp_code,
                        designation_name: Expence_Request.designation_name,
                        branch: Expence_Request.branch,
                        report_mngr: Expence_Request.report_mngr,
                        audit_manager: Expence_Request.audit_manager,
                        account_manager: Expence_Request.account_manager,
                        email: Expence_Request.email,
                        mobile: Expence_Request.mobile,
                        rpt_mgr_status: Expence_Request.rpt_mgr_status,
                        manager_remarks: Expence_Request.manager_remarks,
                        audit_status: Expence_Request.audit_status,
                        audit_remarks: Expence_Request.audit_remarks,
                        account_status: Expence_Request.account_status,
                        account_remarks: Expence_Request.account_remarks,
                        utr: Expence_Request.utr,
                        utr_date: Expence_Request.utr_date,
                        total_no_of_calls: Expence_Request.total_no_of_calls,
                        total_amount: Expence_Request.total_amount,
                        created_on: Expence_Request.created_on,
                        manager_approved_date: Expence_Request.manager_approved_date,
                        audit_approved_date: Expence_Request.audit_approved_date,
                        trans_code : Expence_Request.trans_code,


                    });

                    setRelatedDetails(result.expence_request.related_details);
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchExpence();
    }, [id]); // Run effect when `id` changes

    const handleChange = (e, date) => {
        const { name, value } = e.target;

        // Update the respective state based on the input's name
        setMonthlyExpenses(prevExpenses =>
            prevExpenses.map(expense =>
                expense.date === date
                    ? { ...expense, [name]: value }
                    : expense
            )
        );
        // If the name is 'rpt_mgr_status', update only the rpt_mgr_status in the formData
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    useEffect(() => {
    const fetchProfileData = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_BASE_URL}getemployeedetails/${EmpCode}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            console.log("Profile data:", result); // Log to check fetched data

            if (result.status) {
                // Destructuring directly from the 'data' object
                const {
                    dept_name,
                    designation_name,
                    grade,
                    mobile,
                    bank_acnum,
                    bank_name,
                    ifsc_code,
                    location_name
                    // Add more fields as needed
                } = result.data;

                setFormData(prevData => ({
                    ...prevData,
                    dept_name: dept_name || '',
                    designation_name: designation_name || '',
                    grade: grade || '',
                    mobile: mobile || '', // Do not display the password
                    bank_acnum: bank_acnum || '',
                    bank_name: bank_name || '',
                    ifsc_code: ifsc_code || '',
                    location_name: location_name || '',
                    // You can add more fields here as required
                }));

            } else {
                setResponseMessage('Error: ' + result.message);
            }
        } catch (error) {
            console.error('Fetch error:', error);
            setResponseMessage('Error: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    fetchProfileData();
}, [EmpCode]); // Ensure EmpCode is in the dependency array so it triggers on change


    const handleSubmit = async (e) => {
        e.preventDefault();
        let formIsValid = true;
        let newErrors = {};

        // If Reporting Manager Status is Pending, show error
        if (formData.account_status === 'Pending') {
            formIsValid = false;
            newErrors.account_status = 'Please approve the status before submitting.';
        }

        if (!formIsValid) {
            setErrors(newErrors); // Update errors state if form is invalid
            return;
        }
        setIsSubmitting(true);

        // Create a FormData object to send the data
        const formDataToSend = new FormData();
        formDataToSend.append('account_status', formData.account_status);
        formDataToSend.append('account_remarks', formData.account_remarks);
        formDataToSend.append('utr', formData.utr);
        formDataToSend.append('utr_date', formData.utr_date);

        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_BASE_URL}updateFinance/${id}`, {
                method: 'POST', // Or 'PUT' if you're updating
                headers: {
                    'Authorization': `Bearer ${token}`,
                    // Don't set 'Content-Type' header because FormData will handle it automatically
                },
                body: formDataToSend,  // Send the formDataToSend here
            });

            const result = await response.json();
            if (result.status) {
                toast.success('Expenses updated successfully!');
                navigate('/finance_expence_approval');  // Redirect after successful update
            } else {
                toast.error('Failed to update expenses');
            }
        } catch (error) {
            console.error('Submit error:', error);
            toast.error('Error updating expenses');
        } finally {
            setIsSubmitting(false);
        }
    };

    const generatePDF = () => {
        const doc = new jsPDF();
        // const logo = './vdc_logo.png';  // Assuming you have the logo as a file or base64 string
        // const fromCityName = items.find(city => city.id === row.from_city)?.city_name || '';

        // Outside the tbody loop: Compute fromCityName for each row
       
      
   


        // Add logo to the top left corner of the PDF (10, 10) and resize to fit
        doc.addImage(logo, 'PNG', 5, 10, 15, 15);  // X, Y, Width, Height

        // Title - Centered and Bold
        doc.setFontSize(22);
        doc.setFont('helvetica', 'bold');
        doc.text("Tour/Travel Expence Finance Approval Form", 105, 20, null, null, 'center');

        // Line separator (draw a horizontal line below the title)
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.5);
        doc.line(10, 25, 200, 25); // Horizontal line below title

        // Header Section (Company Info)
        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
        doc.text(`Emp Name: ${formData.comp_name}`, 10, 35);
        doc.text(`Employee No: ${formData.emp_code}`, 105, 35); // Second column starting at X=105

        // Row 2: Department and Grade
        doc.text(`Department: ${formData.dept_name}`, 10, 45);
        
        doc.text(`Location: ${formData.branch}`, 125, 45);
        // Row 3: Report Manager and Created On
        doc.text(`Report Manager: ${formData.report_mngr}`, 10, 55);
        doc.text(`Created On: ${formData.created_on}`, 105, 55); //



        // Table for Detailed Form Data
        doc.autoTable({
            startY: 60, // Table starting position
            head: [['Field', 'Value']],
            body: [
               
                ['Approved Amount', formData.total_amount],
           
                ['Audit Status', formData.audit_status === 'Pending' ? '' : formData.audit_status],
                ['Account Status', formData.account_status === 'Pending' ? '' : formData.account_status],
                ['Manager Remarks', formData.manager_remarks],
                ['Accounts Remarks', formData.account_remarks],

                ['Manager Approved Date', formData.manager_approved_date],
                ['Audit Approved Date', formData.audit_approved_date],
                ['Accounts Manager', formData.account_manager],
                ['Trans Code', formData.trans_code],
                ['UTR', formData.utr],
                ['UTR Date', formData.utr_date],
                ['Focus Code', formData.focus_code],

                ['Bank Account No.', formData.bank_acnum],
                ['Bank Name', formData.bank_name],
                ['IFSC Code', formData.ifsc_code],
            ],
            theme: 'grid',
            headStyles: {
                fillColor: [0, 104, 116],
                textColor: [255, 255, 255],
                fontSize: 12,
                font: 'helvetica',
            },
            bodyStyles: {
                fontSize: 10,
                font: 'helvetica',
            },
            margin: { top: 100, left: 10, right: 10 },
            styles: {
                halign: 'center',
                valign: 'middle',
            },
        });

        // Get the current position after the table
        let yPosition = doc.lastAutoTable.finalY + 10;

        // Section for "Prepared by"
        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text("Prepared by:", 10, yPosition);
        doc.setFont('helvetica', 'normal');
        doc.text(`${formData.emp_code} (${formData.comp_name})`, 10, yPosition + 10);
        doc.text(`Date: ${formData.created_on}`, 10, yPosition + 20);

        // Section for "Approved by" (Side by side)
        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text("Approved by:", 135, yPosition);  // Starting from X=105 for right alignment
        doc.setFont('helvetica', 'normal');
        
        doc.text(`Date: ${formData.manager_approved_date}`, 105, yPosition + 20);

        // Section for "Second Approved by" with empty date
        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text("Audit Approved by:", 10, yPosition + 30);  // Starting from X=105 for right alignment
        doc.setFont('helvetica', 'normal');
     
        doc.text(`Date: ${formData.audit_approved_date}`, 10, yPosition + 45); // Empty space for second approver's date

        // Section for "Approved by" (Side by side)
        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text("Approved by:", 135, yPosition + 30);  // Starting from X=105 for right alignment
        doc.setFont('helvetica', 'normal');
        doc.text(`______________________`, 105, yPosition + 37);
        doc.text(`______________________`, 105, yPosition + 45);

        // Footer with page number
        const totalPages = doc.internal.getNumberOfPages();
        doc.setFontSize(10);
        doc.setFont('helvetica', 'normal');
        doc.text(`Page ${totalPages}`, 105, 290, null, null, "center");

        // Save the PDF
        doc.save("finance_approval_form.pdf");
    };


    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0" style={{ fontSize: '20px' }}>Finance Manager Monthly Expence Approval {formData.trans_code && `(${formData.trans_code})`}</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                    <button
                                            type="button"
                                            className="btn btn-primary pull-right"
                                            onClick={generatePDF}
                                        >
                                            Generate PDF
                                        </button>
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active">Finance Manager Monthly Expence Approval</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <Loader />
                    ) : (
                    <section className="col-lg-12">
                        <div className="card" style={{ borderTop: '3px solid #00c0ef' }}>
                            <div className="card-body">
                                <form onSubmit={handleSubmit} encType="multipart/form-data" method="POST">
                                    <div className="box-body">
                                        <div className="row">

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="name">Name <span style={{ color: 'red', fontSize: '11px' }}>*</span></label>
                                                    <input
                                                        type="text"
                                                        name="comp_name"
                                                        id="name"
                                                        value={formData.comp_name}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        placeholder="Name"
                                                        readonly="true"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="name">Employee No/Consultant
                                                        <span style={{ color: 'red' }}>*</span></label>
                                                    <input
                                                        type="text"
                                                        name="emp_code"
                                                        id="name"
                                                        value={formData.emp_code}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        placeholder="Employee No/Consultant"
                                                        readonly="true"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="name">Designation <span style={{ color: 'red' }}>*</span></label>
                                                    <input
                                                        type="text"
                                                        name="designation_name"
                                                        id="name"
                                                        value={formData.designation_name}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        placeholder="Department&Designation"
                                                        readonly="true"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }}>Reporting Manager<span style={{ color: 'red' }}>*</span></label>
                                                    <input
                                                        type="text"
                                                        name="report_mngr"
                                                        value={formData.report_mngr}
                                                        className="form-control input-font"
                                                        disabled
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="name">Employee Email  <span style={{ color: 'red' }}>*</span></label>
                                                    <input
                                                        type="text"
                                                        name="grade"
                                                        id="name"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        placeholder="Employee Email"
                                                        readonly="true"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }}>Branch<span style={{ color: 'red' }}>*</span></label>
                                                    <input
                                                        type="text"
                                                        name="branch"
                                                        value={formData.branch}
                                                        className="form-control input-font"
                                                        readonly="true"
                                                        disabled

                                                    />
                                                </div>
                                            </div>



                                        </div>

                                        <div className='row'><div className="col-md-4">
                                            <div className="form-group">
                                                <label style={{ fontSize: '12px' }} htmlFor="name">Mobile No
                                                    <span style={{ color: 'red' }}>*</span></label>
                                                <input

                                                    type="text"
                                                    name="mobile"
                                                    id="name"
                                                    value={formData.mobile}
                                                    onChange={handleChange}
                                                    className="form-control input-font"
                                                    placeholder="Days of travel"
                                                    readonly="true"
                                                />
                                            </div>
                                        </div></div>

                                        {/* New Dynamic Row */}
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-md-12">
                                                <div className="table-container custom-table-container" style={{ marginTop: '20px', marginBottom: '20px' }}>
                                                    <div className="table-responsive" style={{ overflowX: 'auto' }}>
                                                        <table className="table table-bordered">
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th className="col-md-2" style={{ padding: '12px 10px', backgroundColor: '#09b8aa', color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>Date</th>
                                                                    <th className="col-md-2" style={{ padding: '12px 10px', backgroundColor: '#09b8aa', color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>Areas Visited</th>
                                                                    <th className="col-md-2" style={{ padding: '12px 10px', backgroundColor: '#09b8aa', color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>No of Calls</th>
                                                                    <th className="col-md-2" style={{ padding: '12px 10px', backgroundColor: '#09b8aa', color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>Amount</th>
                                                                    <th className="col-md-2" style={{ padding: '12px 10px', backgroundColor: '#09b8aa', color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>Remarks</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {relatedDetails.map((detail, index) => (
                                                                    <tr key={detail.id}>
                                                                        <td>
                                                                            <input
                                                                                type="date"
                                                                                name="date"
                                                                                value={detail.date}
                                                                                onChange={(e) => handleChange(e, index)}
                                                                                className="form-control input-font"
                                                                                disabled
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                type="text"
                                                                                name="areas_visited"
                                                                                value={detail.areas_visited}
                                                                                onChange={(e) => handleChange(e, index)}
                                                                                className="form-control input-font"
                                                                                disabled
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                type="number"
                                                                                name="no_of_calls"
                                                                                value={detail.no_of_calls}
                                                                                onChange={(e) => handleChange(e, index)}
                                                                                className="form-control input-font"
                                                                                disabled
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                type="number"
                                                                                name="amount"
                                                                                value={detail.amount}
                                                                                onChange={(e) => handleChange(e, index)}
                                                                                className="form-control input-font"
                                                                                disabled
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <textarea
                                                                                name="remarks"
                                                                                value={detail.remarks}
                                                                                onChange={(e) => handleChange(e, index)}
                                                                                className="form-control input-font"
                                                                                rows="1"
                                                                                disabled
                                                                            ></textarea>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                            <tfoot className="tfoot-light">
                                                                <tr>
                                                                    <td colSpan="2">
                                                                        <strong>Total</strong>
                                                                    </td>
                                                                    <td>
                                                                        <strong>
                                                                            {formData.total_no_of_calls}
                                                                        </strong>
                                                                    </td>
                                                                    <td>
                                                                        <strong>
                                                                            {Math.trunc(formData.total_amount)}
                                                                        </strong>
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="rpt_mgr_status">Reporting Manager Status
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <select
                                                        name="rpt_mgr_status"
                                                        id="rpt_mgr_status"
                                                        value={formData.rpt_mgr_status || ''}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        required
                                                        disabled
                                                    >

                                                        <option value="Pending">Pending</option>
                                                        <option value="Approved">Approved</option>
                                                        <option value="Rejected">Rejected</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="remarks">Reporting Manager Remarks
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>

                                                    <textarea name="manager_remarks"
                                                        required
                                                        id="remarks"
                                                        value={formData.manager_remarks || ''}
                                                        onChange={handleChange}
                                                        className="form-control "
                                                        disabled
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="audit_status">Audit Manager Status
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <select
                                                        name="audit_status"
                                                        id="audit_status"
                                                        value={formData.audit_status || ''}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        required
                                                        disabled

                                                    >

                                                        <option value="Pending">Pending</option>
                                                        <option value="Approved">Approved</option>
                                                        <option value="Rejected">Rejected</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="audit_remarks">Audit Manager Remarks
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>

                                                    <textarea name="audit_remarks"
                                                        required
                                                        id="remarks"
                                                        value={formData.audit_remarks || ''}
                                                        onChange={handleChange}
                                                        className="form-control "
                                                        disabled

                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="account_status">Finance Manager Status
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>
                                                    <select
                                                        name="account_status"
                                                        id="account_status"
                                                        value={formData.account_status || ''}
                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        required


                                                    >

                                                        <option value="Pending">Pending</option>
                                                        <option value="Approved">Approved</option>
                                                        <option value="Rejected">Rejected</option>
                                                    </select>
                                                    {errors.account_status && (
                                                                    <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                                                                        {errors.account_status}
                                                                    </div>
                                                                )}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label style={{ fontSize: '12px' }} htmlFor="account_remarks">Finance Manager Remarks
                                                        <span style={{ color: 'red' }}>*</span>
                                                    </label>

                                                    <textarea name="account_remarks"
                                                        required
                                                        id="remarks"
                                                        value={formData.account_remarks || ''}
                                                        onChange={handleChange}
                                                        className="form-control "


                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="name">UTR

                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="utr"
                                                        id="name"
                                                        value={formData.utr}

                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        placeholder="utr"


                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="name">date

                                                    </label>
                                                    <input
                                                        type="date"
                                                        name="utr_date"
                                                        id="utr_date"
                                                        value={formData.utr_date}

                                                        onChange={handleChange}
                                                        className="form-control input-font"
                                                        placeholder="utr date"


                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 row" style={{ width: '100%' }}>
                                            <div style={{ border: '#cccccc 1px solid' }} className="col-sm-3">
                                                <b>Prepared by </b>
                                                <p> {formData.emp_code} ({formData.comp_name})<br /> Date
                                                    : {formData.created_on}
                                                </p>
                                            </div>
                                            <div style={{ border: '#cccccc 1px solid' }} className="col-sm-3">
                                                {formData.rpt_mgr_status === 'Approved' && (
                                                    <>
                                                        <b> Approved by</b>
                                                        {/* <p> {formData.report_mngr} ({ReportManageName})<br /> Date
                                                                    : {formData.manager_approved_date}
                                                                </p> */}
                                                        <p> {formData.report_mngr} ({ReportManagerName})<br /> Date
                                                            : {formData.manager_approved_date}
                                                        </p>
                                                    </>
                                                )}
                                            </div>
                                            {formData.audit_status === 'Approved' && (
                                                <>
                                                    <div style={{ border: '#cccccc 1px solid' }} className="col-sm-3">

                                                        <b> Verified by Audit Team</b>
                                                        <p>                         <span>
                                                            {formData.audit_manager} ({AuditManagerName})   <br /> Date
                                                            : {formData.audit_approved_date}
                                                        </span>
                                                        </p>

                                                    </div>
                                                </>
                                            )}



                                        </div>

                                    </div>
                                    <div className="box-footer" style={{ textAlign: 'center' }}>
                                        <button type="submit" className="btn btn-success pull-right" disabled={isSubmitting}>
                                            {isSubmitting ? 'Submitting...' : 'Submit'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>)}
                </div>
                <Footer />
                <ToastContainer />
            </div>
        </div>
    );
};

export default Edit_Finance_Manager_Request;
