import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import useAuth from './useAuth';
import './Manage_User.css';
import * as XLSX from 'xlsx';
import Header from './Header.js';
import Modal from './Modal.js';
import Sidebar from './Sidebar.js';
import Footer from './Footer.js';
import Pagination from './Pagination.js';
//import { BASE_URL } from "./Constants.js";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const Loader = () => (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
        <p>Loading...</p>
    </div>
);

const Manage_Employee = () => {
    // State and effect hooks
    const [items, setItems] = useState([]);
    const [selectedEmpCode, setSelectedEmpCode] = useState('');
    const [formData, setFormData] = useState({
        emp_code: ''
    });
    const [filteredItems, setFilteredItems] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [isItAdmin, setIsItAdmin] = useState(false);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState('SysNo');
    const [sortOrder, setSortOrder] = useState('asc');
    const navigate = useNavigate();
    useAuth();

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const token = localStorage.getItem('authToken');
                if (!token) return;

                const response = await fetch(`${API_BASE_URL}users`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                console.log(result)
                if (result.status) {
                    console.log(result.users)
                    setItems(result.users);
                } else {
                    setError(result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setError('Failed to fetch items');
            } finally {
                setLoading(false);
            }
        };

        fetchItems();
    }, [navigate]);


    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                if (result.status) {
                    setFormData({
                        emp_code: result.profile.emp_code || '',
                        mail_id: result.profile.mail_id || '',
                        password: '' // Do not display the password
                    });
                    setIsItAdmin(result.profile.is_it_admin === 'Y'); // Check if admin

                    // Store expiration time in localStorage
                    const expirationTime = Date.now() + 3600 * 1000; // 1 hour from now
                    localStorage.setItem('expirationTime', expirationTime);
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchProfileData(); // Call the function to fetch profile data
    }, []);

    useEffect(() => {
        const filtered = items.filter(item =>
            (item.emp_code?.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (item.comp_name?.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (item.mail_id?.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (item.id?.toLowerCase().includes(searchQuery.toLowerCase()))
        );

        const sorted = filtered.sort((a, b) => {
            if (a[sortColumn] < b[sortColumn]) return sortOrder === 'asc' ? -1 : 1;
            if (a[sortColumn] > b[sortColumn]) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });

        setFilteredItems(sorted);
        setCurrentPage(1); // Reset to first page when items or search query changes
    }, [searchQuery, items, sortColumn, sortOrder]);

    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const startItem = indexOfFirstItem + 1;
    const endItem = Math.min(indexOfLastItem, filteredItems.length);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortOrder('asc');
        }
    };

    const exportToExcel = () => {
        const filteredBranchNames = filteredItems.map(item => ({
            EmployeeCode: item.emp_code,
            FullName: item.comp_name,
            Email: item.mail_id
        }));
        const worksheet = XLSX.utils.json_to_sheet(filteredBranchNames);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
        XLSX.writeFile(workbook, 'Employee_Details.xlsx');
    };

    const handleResetPassword = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_BASE_URL}reset_password/${selectedEmpCode}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            const result = await response.json();
            if (result.status) {
                alert('Password reset successfully!');
            } else {
                alert('Error: ' + result.message);
            }
        } catch (error) {
            console.error('Fetch error:', error);
            alert('Failed to reset password');
        } finally {
            setIsModalOpen(false); // Close the modal
        }
    };

    return (
        <div className="hold-transition sidebar-mini layout-fixed" style={{ fontSize: '12px' }}>
            <div className="wrapper">
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Employee Details</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                       
                                        <li className="breadcrumb-item active">Employee Details</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <Loader /> // Show loader if loading is true
                    ) : (
                        <>
                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        <section className="col-lg-12 connectedSortable">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h3 className="card-title">Employee Details</h3>
                                                    <span style={{ float: 'right' }}>

                                                        <button type="button" className="btn btn-secondary ml-2" onClick={exportToExcel}>
                                                            Export to Excel
                                                        </button>
                                                    </span>
                                                </div>
                                                <div className="card-body">
                                                    <div id="example1_filter" style={{ float: 'right' }} className="dataTables_filter">
                                                        <label>
                                                            <input
                                                                type="search"
                                                                value={searchQuery}
                                                                onChange={handleSearchChange}
                                                                className="form-control form-control-sm"
                                                                placeholder="Search"
                                                                aria-controls="example1"
                                                            />
                                                        </label>
                                                    </div>

                                                    <div className="form-group">
                                                        <select
                                                            className="form-control custom-select-sm"
                                                            value={itemsPerPage}
                                                            onChange={handleItemsPerPageChange}
                                                        >
                                                            <option value={10}>10</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </select>
                                                    </div>
                                                    <div className="table-responsive">
                                                    <table className="table table-bordered table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>S.No</th>
                                                                <th>
                                                                    
                                                                        Employee Code

                                                                </th>
                                                                <th>
                                                                    
                                                                        Complete Name 
                                                                </th>
                                                                <th>
                                                                   
                                                                        Mail id 
                                                                </th>
                                                                {isItAdmin && (<th>
                                                                   
                                                                        Action 
                                                                </th>)}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {currentItems.length > 0 ? (
                                                                currentItems.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                                                                        <td>{item.emp_code}</td>
                                                                        <td>{item.comp_name}</td>
                                                                        <td>{item.mail_id}</td>
                                                                        {isItAdmin && (<td><Link to={`/edit_employee/${item.emp_code}`}>
                                                                         
                                                                            <i className="fa fa-edit" style={{ fontSize: '12px', color: 'green' }}></i>
                                                                        </Link>
                                                                                <i className="fa fa-lock" onClick={() => {
                                                                                setSelectedEmpCode(item.emp_code);
                                                                                setIsModalOpen(true);
                                                                            }} style={{ fontSize: '12px', color: 'green' }}></i>
                                                                           </td>)}

                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="3">No data available</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <Pagination
                                                currentPage={currentPage}
                                                totalPages={totalPages}
                                                handlePageChange={handlePageChange}
                                            />
                                            <div className="mb-3">
                                                Showing {startItem} to {endItem} of {filteredItems.length} entries
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </section>
                        </>
                    )}
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        onConfirm={handleResetPassword}
                    />
                </div>

                <Footer />

                <aside className="control-sidebar control-sidebar-dark">
                </aside>
            </div>
        </div>
    );
};

export default Manage_Employee;
