import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from './vdc_logo.png';
import './Sidebar.css';
//import { BASE_URL } from "./Constants.js";

import MD5 from "crypto-js/md5";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const Sidebar = () => {
    const [isCollapsed, setIsCollapsed] = useState(false); // State for sidebar toggle

    const [userType, setUserType] = useState('');
    const [formData, setFormData] = useState({
        emp_code: ''
    });
    const [responseMessage, setResponseMessage] = useState('');
    const [openDropdowns, setOpenDropdowns] = useState({});
    const [isAdmin, setIsAdmin] = useState(false);
    const [Password, setPassword] = useState(false);
    const [is_manager_approval, setis_manager_approval] = useState(false);

    const [is_disabled, setis_disabled] = useState(false);
    const [is_audit_approval, setis_audit_approval] = useState(false);
    const [is_finance_approval, setis_finance_approval] = useState(false);

    const [Count, setCount] = useState(false);
    const [is_traveldesk, setis_traveldesk] = useState(false);
    const [is_travelmanager, setis_travelmanager] = useState(false);
    const [is_hotelmanager, setis_hotelmanager] = useState(false);

    const navigate = useNavigate();
    const [is_hotelinfo, setis_hotelinfo] = useState(false);
    // New state for admin check

    const toggleDropdown = (id) => {
        setOpenDropdowns((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const handleSidebarToggle = () => {
        setIsCollapsed(prevState => !prevState);
    };

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}profile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                if (result.status) {
                    setFormData({
                        emp_code: result.profile.emp_code || '',
                        mail_id: result.profile.mail_id || '',
                        password: '' // Do not display the password
                    });
                    setPassword(result.profile.password);
                    //const hashedPasswordforAccess = MD5('Y').toString();
                    setIsAdmin(result.profile.is_admin === 'Y'); // Check if admin
                    setis_manager_approval(result.profile.is_manager_approval === 'Y');
                    setis_audit_approval(result.profile.is_audit_approval === 'Y');
                    setis_finance_approval(result.profile.is_finance_approval === 'Y');
                    setis_traveldesk(result.profile.is_traveldesk === 'Y');
                    setis_travelmanager(result.profile.is_travelmanager_approved === 'Y');
                    setis_hotelmanager(result.profile.is_hotelmanager_approved === 'Y');
                    setis_hotelinfo(result.profile.is_hotelinfo === 'Y');
                    const hashedPassword = MD5('adnet2008').toString();
                    const getPassword = result.profile.password;
                    if (hashedPassword === getPassword) {

                        setis_disabled('Y');
                    }
                
                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchProfileData(); // Call the function to fetch profile data
    }, []); // Ensure this runs once on component mount


     

    useEffect(() => {
        const fetchEmployeeCount = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const response = await fetch(`${API_BASE_URL}employee_count`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();

                if (result.status) {

                    setCount(result.data[0].count);


                } else {
                    setResponseMessage('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Fetch error:', error);
                setResponseMessage('Error: ' + error.message);
            }
        };

        fetchEmployeeCount(); // Call the function to fetch profile data
    }, []); // Ensure this runs once on component mount

    const handleLogout = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${API_BASE_URL}logout`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const result = await response.json();
            if (result.status) {
                localStorage.removeItem('authToken');
                localStorage.removeItem('exp');
                navigate('/');
            }
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <div className="sidebar-header">
                <Link to="/dashboard" className="brand-link">
                    <img
                        src={logo}
                        style={{ width: '30px', height: '30px', opacity: 0.8 }}
                        className="brand-image img-circle elevation-3"
                        alt="Brand Logo"
                    />
                    <span className="brand-text font-weight-light">Travel App</span>
                </Link>
                <Link to="#" className="nav-link toggle-button" data-widget="pushmenu" role="button" style={{ paddingLeft: '58px' }}>
                    <i className="fas fa-bars" style={{ color: 'white' }}></i>
                </Link>
            </div>
            <div className="sidebar">


                <nav className="mt-2">
                    {!is_disabled && (
                        <ul className="nav nav-pills nav-sidebar flex-column">
                            <li className="nav-item">
                                <Link to="/dashboard" className="nav-link">
                                    <i className="fas fa-chart-line nav-icon" style={{ color: '#1aab8b' }}></i>
                                    <p style={{ color: '#1aab8b' }}>Dashboard</p>
                                </Link>
                            </li>
                            {isAdmin && (<li className="nav-item">
                                <Link to="/manage_employee" className="nav-link">
                                    <i className="fas fa-chart-line nav-icon" style={{ color: '#1aab8b' }}></i>
                                    <p style={{ color: '#1aab8b' }}>Employee</p>
                                </Link>
                            </li>)}
                            {isAdmin && (<li className="nav-item">
                                <Link to="/logged_in_users" className="nav-link">
                                    <i className="fas fa-chart-line nav-icon" style={{ color: '#1aab8b' }}></i>
                                    <p style={{ color: '#1aab8b' }}>Logged In Users</p>
                                </Link>
                            </li>)}


                            <li className="nav-item">
                                <a
                                    href="#"
                                    className="nav-link"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleDropdown('settings');
                                    }}
                                >
                                    <i className="fas fa-cogs nav-icon" style={{ color: '#1aab8b' }}></i>
                                    <p style={{ color: '#1aab8b' }}>
                                        Settings
                                        <i className={`right fas fa-angle-left ${openDropdowns['settings'] ? 'rotate' : ''}`}></i>
                                    </p>
                                </a>
                                <ul className={`nav nav-treeview ${openDropdowns['settings'] ? 'd-block' : 'd-none'}`}>
                                    <li className='nav-item'>
                                        <Link to='/change_password' className='nav-link'>
                                            <i className='left fas fa-angle-right nav-icon' ></i>
                                            <p>Change Password</p>
                                        </Link>
                                    </li>
                                    {isAdmin && (
                                        <>
                                            <li className='nav-item'>
                                                <Link to='/manage_states' className='nav-link'>
                                                    <i className='left fas fa-angle-right nav-icon'></i>
                                                    <p>Manage States</p>
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link to='/manage_cities' className='nav-link'>
                                                    <i className='left fas fa-angle-right nav-icon'></i>
                                                    <p>Manage Cities</p>
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link to='/manage_diem' className='nav-link'>
                                                    <i className='left fas fa-angle-right nav-icon'></i>
                                                    <p>DIEM</p>
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link to='/purposes' className='nav-link'>
                                                    <i className='left fas fa-angle-right nav-icon'></i>
                                                    <p>Purposes</p>
                                                </Link>
                                            </li></>)}
                                    {/* <li className='nav-item'>
                                        <Link to='/manage_bankinfo' className='nav-link'>
                                            <i className='far fa-circle nav-icon'></i>
                                            <p>Manage Bankinfo</p>
                                        </Link>
                                    </li> */}
                                </ul>
                            </li>


                            <li className="nav-item">
                                <a
                                    href="#"
                                    className="nav-link"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleDropdown('advance');
                                    }}
                                >
                                    <i className="fas fa-money-bill nav-icon" style={{ color: '#1aab8b' }}></i>
                                    <p style={{ color: '#1aab8b' }}>
                                        Advance Form
                                        <i className={`right fas fa-angle-left ${openDropdowns['advance'] ? 'rotate' : ''}`}></i>
                                    </p>
                                </a>
                                <ul className={`nav nav-treeview ${openDropdowns['advance'] ? 'd-block' : 'd-none'}`}>
                                    <li className="nav-item">
                                        <Link to="/own_advance/all" className="nav-link">
                                            <i className="left fas fa-angle-right nav-icon"></i>
                                            <p>Tour Advance Form</p>
                                        </Link>
                                    </li>
                                    {is_travelmanager && (<li className="nav-item">
                                        <Link to="/view_advance_travel" className="nav-link">
                                            <i className="left fas fa-angle-right nav-icon"></i>
                                            <p>View all advances for travel manager</p>
                                        </Link>
                                    </li>)}
                                    {Count > 0 && (
                                        <li className="nav-item">
                                            <Link to="/manage_advance" className="nav-link">
                                                <i className="left fas fa-angle-right nav-icon"></i>
                                                <p>Reporting Manager Advance Approval</p>
                                            </Link>
                                        </li>)}
                                    {is_finance_approval && (<li className="nav-item">
                                        <Link to="/finance_form" className="nav-link">
                                            <i className="left fas fa-angle-right nav-icon"></i>
                                            <p>Finance Approval</p>
                                        </Link>
                                    </li>)}




                                </ul>
                            </li>

                            <li className="nav-item">
                                <a
                                    href="#"
                                    className="nav-link"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleDropdown('settlement');
                                    }}
                                >
                                    <i className="fas fa-check-circle nav-icon" style={{ color: '#1aab8b' }}></i>
                                    <p style={{ color: '#1aab8b' }}>
                                        Settlement Form
                                        <i className={`right fas fa-angle-left ${openDropdowns['settlement'] ? 'rotate' : ''}`}></i>
                                    </p>
                                </a>
                                <ul className={`nav nav-treeview ${openDropdowns['settlement'] ? 'd-block' : 'd-none'}`}>
                                    <li className="nav-item">
                                        <Link to="/own_settlement/all" className="nav-link">
                                            <i className="left fas fa-angle-right nav-icon"></i>
                                            <p>Tour Settlement Form</p>
                                        </Link>
                                    </li>
                                    {Count > 0 && (<li className="nav-item">
                                        <Link to="/manage_settlement" className="nav-link">
                                            <i className="left fas fa-angle-right nav-icon"></i>
                                            <p>Reporting Manager Settlement Approval</p>
                                        </Link>
                                    </li>)}
                                    {is_finance_approval && (
                                        <li className="nav-item">
                                            <Link to="/finance_settlement_form" className="nav-link">
                                                <i className="left fas fa-angle-right nav-icon"></i>
                                                <p>Finance Approval</p>
                                            </Link>
                                        </li>)}







                                    {is_audit_approval && (<><li className="nav-item">
                                        <Link to="/audit_form" className="nav-link">
                                            <i className="fas fa-file-alt nav-icon"></i>
                                            <p>Audit Approval</p>
                                        </Link>
                                    </li>
                                 <li className="nav-item">
                                 <Link to="/settlement_audit_reports" className="nav-link">
                                     <i className="fas fa-file-alt nav-icon"></i>
                                     <p>Settlement Audit Reports</p>
                                 </Link>
                             </li></>)}


                                </ul>
                            </li>

                            <li className="nav-item">
                                <a
                                    href="#"
                                    className="nav-link"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleDropdown('traveldesk');
                                    }}
                                >
                                    <i className="fas fa-suitcase nav-icon" style={{ color: '#1aab8b' }}></i>
                                    <p style={{ color: '#1aab8b' }}>
                                        Travel & Hotel
                                        <i className={`right fas fa-angle-left ${openDropdowns['traveldesk'] ? 'rotate' : ''}`}></i>
                                    </p>
                                </a>
                                <ul className={`nav nav-treeview ${openDropdowns['traveldesk'] ? 'd-block' : 'd-none'}`}>

                                    <li className='nav-item'>
                                        <Link to='/manage_travel_request' className='nav-link'>
                                            <i className='left fas fa-angle-right nav-icon'></i>
                                            <p>Travel Request</p>
                                        </Link></li>

                                    {is_travelmanager && (<li className='nav-item'>
                                        <Link to='/travel_approval' className='nav-link'>
                                            <i className='left fas fa-angle-right nav-icon'></i>
                                            <p>Travel Approval</p>
                                        </Link></li>)}
                                        {is_audit_approval && (<li className='nav-item'>
                                        <Link to='/travel_approval_audit' className='nav-link'>
                                            <i className='left fas fa-angle-right nav-icon'></i>
                                            <p>Travel For Audit</p>
                                        </Link></li>)}    
                                        {is_audit_approval && (<li className='nav-item'>
                                        <Link to='/hotel_approval_audit' className='nav-link'>
                                            <i className='left fas fa-angle-right nav-icon'></i>
                                            <p>Hotel For Audit</p>
                                        </Link></li>)}   
                                    <li className='nav-item'>
                                        <Link to='/manage_hotel_request' className='nav-link'>
                                            <i className='left fas fa-angle-right nav-icon'></i>
                                            <p>Hotel Request</p>
                                        </Link></li>

                                    {Count > 0 && (<li className='nav-item'>
                                        <Link to='/reporting_manager_travel_approval' className='nav-link'>
                                            <i className='left fas fa-angle-right nav-icon'></i>
                                            <p>Reporting Manager Travel Approval</p>
                                        </Link></li>)}
                                    {Count > 0 && (<li className='nav-item'>
                                        <Link to='/reporting_manager_hotel_approval' className='nav-link'>
                                            <i className='left fas fa-angle-right nav-icon'></i>
                                            <p>Reporting Manager Hotel Approval</p>
                                        </Link></li>)}

                                    {is_hotelmanager && (<li className='nav-item'>
                                        <Link to='/hotel_approval' className='nav-link'>
                                            <i className='left fas fa-angle-right nav-icon'></i>
                                            <p>Hotel Approval</p>
                                        </Link></li>)}




                                </ul>

                            </li>

                            <li className="nav-item">
                                <a
                                    href="#"
                                    className="nav-link"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleDropdown('expence');
                                    }}
                                >
                                    <i className="fas fa-dollar-sign nav-icon" style={{ color: '#1aab8b' }}></i>
                                    <p style={{ color: '#1aab8b' }}>
                                        Expense
                                        <i className={`right fas fa-angle-left ${openDropdowns['expence'] ? 'rotate' : ''}`}></i>
                                    </p>
                                </a>
                                <ul className={`nav nav-treeview ${openDropdowns['expence'] ? 'd-block' : 'd-none'}`}>
                                    <li className="nav-item">
                                        <Link to="/manage_monthly_expences" className="nav-link">
                                            <i className="left fas fa-angle-right nav-icon"></i>
                                            <p>Manage Monthly Expenses</p>
                                        </Link>
                                    </li>
                                    {Count > 0 && (<li className="nav-item">
                                        <Link to="/reporting_manager_expences" className="nav-link">
                                            <i className="left fas fa-angle-right nav-icon"></i>
                                            <p>Reporting Manager Expenses Approval</p>
                                        </Link>
                                    </li>)}
                                    {is_audit_approval && (<li className="nav-item">
                                        <Link to="/audit_expence_approval" className="nav-link">
                                            <i className="left fas fa-angle-right nav-icon"></i>
                                            <p>Audit Expenses Approval</p>
                                        </Link>
                                    </li>)}
                                    {is_audit_approval && (<li className="nav-item">
                                        <Link to="/audit_expence_reports" className="nav-link">
                                            <i className="left fas fa-angle-right nav-icon"></i>
                                            <p>Audit Expenses Reports</p>
                                        </Link>
                                    </li>)}
                                    {is_finance_approval && (<li className="nav-item">
                                        <Link to="/finance_expence_approval" className="nav-link">
                                            <i className="left fas fa-angle-right nav-icon"></i>
                                            <p>Finance Expenses Approval</p>
                                        </Link>
                                    </li>)}
                                    {is_finance_approval && (<li className="nav-item">
                                        <Link to="/finance_expence_reports" className="nav-link">
                                            <i className="left fas fa-angle-right nav-icon"></i>
                                            <p>Finance Expenses Reports</p>
                                        </Link>
                                    </li>)}
                                </ul>
                            </li>





                            {/* {is_traveldesk && (<>
                                <li className="nav-item">
                                    <a
                                        href="#"
                                        className="nav-link"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toggleDropdown('traveldesk1');
                                        }}
                                    >
                                        <i className="fas fa-suitcase nav-icon" style={{ color: '#1aab8b' }}></i>
                                        <p style={{ color: '#1aab8b' }}>
                                            Travel Desk & Hotel Desk
                                            <i className={`right fas fa-angle-left ${openDropdowns['traveldesk1'] ? 'rotate' : ''}`}></i>
                                        </p>
                                    </a>
                                    <ul className={`nav nav-treeview ${openDropdowns['traveldesk1'] ? 'd-block' : 'd-none'}`}>


                                        {is_traveldesk && (<li className='nav-item'>
                                            <Link to='/manage_travel_desk' className='nav-link'>
                                                <i className='left fas fa-angle-right nav-icon'></i>
                                                <p>Manage Travel Desk</p>
                                            </Link>
                                        </li>)}

                                        {is_hotelinfo && (<li className="nav-item">
                                            <Link to="/manage_hotel_info" className="nav-link">
                                                <i className="left fas fa-angle-right nav-icon"></i>
                                                <p>Manage Hotel Desk</p>
                                            </Link>
                                        </li>)}



                                    </ul>

                                </li></>)} */}

                            <li className="nav-item">
                                <a
                                    href="#"
                                    className="nav-link"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleDropdown('reports');
                                    }}
                                >
                                    <i className="fa fa-file nav-icon" style={{ color: '#1aab8b' }}></i>
                                    <p style={{ color: '#1aab8b' }}>
                                        Reports
                                        <i className={`right fas fa-angle-left ${openDropdowns['reports'] ? 'rotate' : ''}`}></i>
                                    </p>
                                </a>
                                <ul className={`nav nav-treeview ${openDropdowns['reports'] ? 'd-block' : 'd-none'}`}>
                                    <li className="nav-item">
                                        <Link to="/reports" className="nav-link">
                                            <i className="left fas fa-angle-right nav-icon"></i>
                                            <p>Advance</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/settlement_reports" className="nav-link">
                                            <i className="left fas fa-angle-right nav-icon"></i>
                                            <p>Settlement</p>
                                        </Link>
                                    </li>
                                    { is_audit_approval && (
                                    <li className="nav-item">
                                        <Link to="/settlement_audit_reports" className="nav-link">
                                            <i className="left fas fa-angle-right nav-icon"></i>
                                            <p>Settlement Audit Reports</p>
                                        </Link>
                                    </li>)}
                                    { is_audit_approval && (
                                    <li className="nav-item">
                                        <Link to="/settlement_expense_reports" className="nav-link">
                                            <i className="left fas fa-angle-right nav-icon"></i>
                                            <p>Settlement Expence Reports</p>
                                        </Link>
                                    </li>)}
                                    { is_finance_approval && (
                                    <li className="nav-item">
                                        <Link to="/settlement_finance_reports" className="nav-link">
                                            <i className="left fas fa-angle-right nav-icon"></i>
                                            <p>Settlement Finance Reports</p>
                                        </Link>
                                    </li>)}
                                    <li className="nav-item">
                                        <Link to="/travel_reports" className="nav-link">
                                            <i className="left fas fa-angle-right nav-icon"></i>
                                            <p>Travel</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/hotel_reports" className="nav-link">
                                            <i className="left fas fa-angle-right nav-icon"></i>
                                            <p>Hotel</p>
                                        </Link>
                                    </li>
                                    {Count > 0 && (<>


                                        <li className="nav-item">
                                            <Link to="/manager_travel_reports" className="nav-link">
                                                <i className="left fas fa-angle-right nav-icon"></i>
                                                <p>Manager Travel Reports</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/manager_hotel_reports" className="nav-link">
                                                <i className="left fas fa-angle-right nav-icon"></i>
                                                <p>Manager Hotel Reports</p>
                                            </Link>
                                        </li></>)}
                                    {Count > 0 && (<li className="nav-item">
                                        <Link to="/manage_settlement_reports" className="nav-link">
                                            <i className="left fas fa-angle-right nav-icon"></i>
                                            <p>Reporting Manager Settlement Reports</p>
                                        </Link>
                                    </li>)}
                                    {/* {is_traveldesk && (<li className='nav-item'>
                                        <Link to='/travel_desk_reports' className='nav-link'>
                                            <i className='left fas fa-angle-right nav-icon'></i>
                                            <p>Travel Desk Reports</p>
                                        </Link>
                                    </li>)}


                                    {is_hotelinfo && (<li className='nav-item'>
                                        <Link to='/hotel_info_reports' className='nav-link'>
                                            <i className='left fas fa-angle-right nav-icon'></i>
                                            <p>Hotel Desk Reports</p>
                                        </Link>
                                    </li>)} */}

                                    {Count > 0 && (
                                        <li className="nav-item">
                                            <Link to="/manage_reports" className="nav-link">
                                                <i className="left fas fa-angle-right nav-icon"></i>
                                                <p>Reporting Manager Advance Reports</p>
                                            </Link>
                                        </li>)}
                                    {is_finance_approval && (<li className="nav-item">
                                        <Link to="/finance_advance_reports" className="nav-link">
                                            <i className="left fas fa-angle-right nav-icon"></i>
                                            <p>Finance Advance Reports</p>
                                        </Link>
                                    </li>)}
                                    {is_travelmanager && (<li className='nav-item'>
                                        <Link to='/travel_manager_approval' className='nav-link'>
                                            <i className='left fas fa-angle-right nav-icon'></i>
                                            <p>Travel Manager Approval</p>
                                        </Link></li>)}
                                    {is_hotelmanager && (<li className='nav-item'>
                                        <Link to='/hotel_manager_approval' className='nav-link'>
                                            <i className='left fas fa-angle-right nav-icon'></i>
                                            <p>Hotel Manager Approval</p>
                                        </Link></li>)}
                                    {is_finance_approval && (<li className="nav-item">
                                        <Link to="/finance_settlement_reports" className="nav-link">
                                            <i className="left fas fa-angle-right nav-icon"></i>
                                            <p>Finance Settlement Reports</p>
                                        </Link>
                                    </li>)}
                                    {is_audit_approval && (<li className="nav-item">
                                        <Link to="/audit_reports" className="nav-link">
                                            <i className="left fas fa-angle-right nav-icon"></i>
                                            <p>Audit Reports</p>
                                        </Link>
                                    </li>)}


                                </ul>


                            </li>
                            <li className="nav-item">
                                <Link to="/manage_conveyance" className="nav-link">
                                   
                                    <i className="fas fa-car nav-icon" style={{ color: '#1aab8b' }}></i>
                                    <p style={{ color: '#1aab8b' }}>Local Conveyance</p>
                                </Link>
                            </li>
                            {Count > 0 && (<li className="nav-item">
                        <Link to="/reporting_manager_conveyance" className="nav-link">
                           
                            <i className="fas fa-car nav-icon" style={{ color: '#1aab8b' }}></i>
                            <p style={{ color: '#1aab8b' }}>Reporting Manager Local Conveyance</p>
                        </Link>
                    </li>)}
                    {is_audit_approval && (<li className="nav-item">
                        <Link to="/audit_conveyance" className="nav-link">
                           
                            <i className="fas fa-car nav-icon" style={{ color: '#1aab8b' }}></i>
                            <p style={{ color: '#1aab8b' }}>Audit Conveyance Approval</p>
                        </Link>
                    </li>)}
                    {is_finance_approval && (<li className="nav-item">
                        <Link to="/finance_conveyance" className="nav-link">
                           
                            <i className="fas fa-car nav-icon" style={{ color: '#1aab8b' }}></i>
                            <p style={{ color: '#1aab8b' }}>Finance Conveyance Approval</p>
                        </Link>
                    </li>)}
                            <li className="nav-item">
                                <a
                                    href="#"
                                    className="nav-link"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleDropdown('logout');  // Handle the dropdown toggle for logout
                                        handleLogout();
                                    }}
                                >
                                    <i className="fa fa-sign-out-alt nav-icon" style={{ color: '#1aab8b' }}></i>
                                    <p style={{ color: '#1aab8b' }}>
                                        Logout
                                        {/* <i className={`right fas fa-angle-left ${openDropdowns['logout'] ? 'rotate' : ''}`}></i> */}
                                    </p>
                                </a>

                            </li>
                        </ul>
                    )};

                </nav>
            </div>
        </aside>
    );
};

export default Sidebar;
